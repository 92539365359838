import moment from 'moment';

export default function validate(values, file, financeDetails, userTypeId, tab, isLenderModifi) {
  let errors = {};


  if (!values.applicantEmail) {
    errors.applicantEmail = 'Applicant is Missing';
  }

  if (!values.buyerId) {
    errors.buyerId = 'Select a Buyer';
  }

  if (!values.invoiceRefrenceNo) {
    errors.invoiceRefrenceNo = 'Enter Invoice Number';
  }

  if (!values.poRefrenceNo) {
    errors.poRefrenceNo = 'Enter PO Number';
  }

  if (!values.invoiceIssueDate) {
    errors.invoiceIssueDate = 'Enter Invoice issue date';
  }

  if (!values.invoiceDueDate) {
    errors.invoiceDueDate = 'Enter Invoice due date';
  }
  else if (financeDetails.info.action === "applyFinance") {
    if (moment() > moment(values.invoiceDueDate)) {
      errors.invoiceDueDate = 'Invoice due date must be greater than present date';
    }
  }

  if (!values.currency) {
    errors.currency = 'Select a Currency';
  }

  if (!values.contractAmount) {
    errors.contractAmount = 'Enter Contract Amount';
  }

  if (!values.reqAmount) {
    errors.reqAmount = 'Enter Requested Amount';
  }

  if (!values.lenderId) {
    errors.lenderId = 'Select a Lending Bank';
  }

  if (!values.creditDays) {
    errors.creditDays = 'Enter Credit Days';
  }

  if (!values.bankListId) {
    errors.bankListId = 'Select a Bank';
  }

  if (!file.invoiceDocument && financeDetails.info.action === "applyFinance" && tab === 1) {
    errors.invoiceDocument = 'Upload the Invoice Copy';
  }

  if (!values.interestRate && userTypeId === 8) {
    errors.interestRate = 'Enter Interest Rate';
  }

  if (values.selectedBuyerLimit.limit == 'No values assigned to buyer' && userTypeId != 8 && financeDetails.info.action === "applyFinance") {
    errors.selectedBuyerLimit = 'No values assigned to buyer';
  }

  if (!values.nameAuthorizedSignatory) {
    errors.nameAuthorizedSignatory = 'Enter Name of Authorized Signatory';
  }

  if (!values.buyerEmail) {
    errors.buyerEmail = 'Enter Buyer Email';
  }

  if (!values.buyerContact) {
    errors.buyerContact = 'Enter Buyer Contact';
  }

  if (!values.buyerPosition) {
    errors.buyerPosition = 'Enter Buyer Position';
  }

  if (isLenderModifi) {
    if (!values.shipmentOriginCountry) {
      errors.shipmentOriginCountry = 'Select Shipment Origin Country';
    }
    if (!values.shipmentOriginAddress) {
      errors.shipmentOriginAddress = 'Enter Shipment Origin Address';
    }
    if (!values.shipmentDestinationCountry) {
      errors.shipmentDestinationCountry = 'Select Shipment Destination Country';
    }
    if (!values.shipmentDestinationAddress) {
      errors.shipmentDestinationAddress = 'Select Shipment Destination Address';
    }
    if (!values.financingTerm) {
      errors.financingTerm = 'Enter Finance Terms Days';
    }
  }

  console.log("error in finance form==>", errors)

  return errors;
}
