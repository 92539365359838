import React from 'react';
import { connect } from 'react-redux';
import Header from '../partial/header';
import { setInvoiceDetail } from '../../store/actions/action';
import InvoiceView from './invoiceViewComp';
import InvoiceGen from './invoiceGenerateComp';
import InvoiceGenShipment from './invoiceGenerateCompShipment';


const Invoice = ({ userTokenDetails, planData, setInvoiceDetail, InvoiceDetail, dispatch }) => {

  const operationType = InvoiceDetail.info.operation
  const invoiceType = InvoiceDetail.info.invoiceType
  // const [showLoader, setshowLoader] = useState(false);

  console.log("invoiceType=======>", invoiceType)

  return (
    <>
      <Header
        title={'Contract Invoice ' + operationType}
        userTokenDetails={userTokenDetails} />
      {/* {showLoader && <div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>} */}
      {operationType === "View" ?
        <InvoiceView userTokenDetails={userTokenDetails} /> :
        (invoiceType === "contract" ? <InvoiceGen userTokenDetails={userTokenDetails} planData={planData} /> : <InvoiceGenShipment userTokenDetails={userTokenDetails} />)
      }
    </>
  )
};

const mapStateToProps = state => {
  return {
    InvoiceDetail: state.InvoiceDetail
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setInvoiceDetail: (flag, data) => { dispatch(setInvoiceDetail({ modal: flag, info: data })) }
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invoice)
