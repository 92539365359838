import React, { useState } from 'react';

// editLevel (true= editable, false=viewonly) ; userLevel (1->admin , 2->User)
const TncForm = ({ dataTnc, setdataTnc, handleTNC, contMode, editCount, tncOrder, settncOrder, setEditCount, }) => {

  console.log("tncOrder in TncForm-->", tncOrder)
  // console.log("setdataTnc in TncForm-->", setdataTnc)
  // console.log("handleTNC in TncForm-->", handleTNC)


  //let [extraTnc, setextraTnc] = useState([]);
  const [newTnc, setnewTnc] = useState({ newTncName: "", newTncValue: "" });
  // const [userEmail, setuserEmail] = useState('');
  // const [userTypeId, setuserTypeId] = useState('');
  // const [count, setcount] = useState(50);
  const [accordion, setAccordion] = useState(0);


  const handleAccordion = (accordion) => setAccordion(accordion);

  //---------------------------------------------------------------------------------------------------------------------
  // Functions to add dynamic specification elements
  function addElement() {
    // const dataElement = [...extraTnc]
    // dataElement.push(moreTncElement())
    // setextraTnc(dataElement)
    let newTncObj = { "name": newTnc.newTncName, "value": newTnc.newTncValue }
    setdataTnc({ ...dataTnc, [newTnc.newTncName.toLowerCase().replace(/\s/g, '')]: newTncObj });
    setnewTnc({ newTncName: "", newTncValue: "" });
    // setcount(count + 1)
    if (contMode)
      setEditCount(editCount + 1)

    let tempOrderArray = [...tncOrder]
    tempOrderArray.push(newTnc.newTncName.toLowerCase().replace(/\s/g, ''))
    settncOrder(tempOrderArray)

  }

  function removeElement(key) {
    // const dataElement = [...extraTnc]
    let tncdataElement = { ...dataTnc }
    // console.log("dataElement[i]--->", dataElement[i].props.children.props.children)
    // let keytoDelete = dataElement[i].props.children.props.children.toLowerCase().replace(/\s/g, '')
    delete tncdataElement[key]
    // dataElement.splice(i, 1)
    setdataTnc(tncdataElement)
    // setextraTnc(dataElement)
    let tempOrderArray = [...tncOrder]
    const index = tempOrderArray.indexOf(key);
    if (index > -1) {
      tempOrderArray.splice(index, 1);
    }
    settncOrder(tempOrderArray)
  }


  const handlenewTnc = (event) => {
    event.persist();
    setnewTnc(newTnc => ({ ...newTnc, [event.target.name]: event.target.value }));
  };
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Commodity Spec Components

  function tncBodyGenerator() {
    let keyArray = (tncOrder && tncOrder.length) ? tncOrder : Object.keys(dataTnc).length ? Object.keys(dataTnc) : []
    // keyArray.sort((a, b) => (a.split(':')[0] / 1) - (b.split(':')[0] / 1))
    console.log("keyArray=>", keyArray)
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!dataTnc[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{dataTnc[key].name ? dataTnc[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={dataTnc[key].value.replace(/<br>/g, "\n")} onChange={handleTNC}></textarea>
                </div>
              </div>
              <button type="button" className="delete-icon tnc-delete" onClick={() => removeElement(key)} ><i className="fa fa-trash"></i></button>
            </div>
          )
        })}
      </div>
    )
  }


  //---------------------------------------------------------------------------------------------------------------------


  return (
    <>
      {tncBodyGenerator()}
      <hr />
      <div className="modal-padding mt-2">
        {/* <h3 className="mt-2 mb-2">Extra TNC (if any)<span className="help-icon" flow="right" tooltip="Additional TNC">i</span></h3> */}
        {/* <div className=""> */}
        {/* {extraTnc.map((extraTnc, idx) => {
            return (<>
              {extraTnc &&
                <div className="col-md-12 row d-flex pl-0">
                  <div className="accordionWrapper col-md-11">{extraTnc}</div>
                  <div className="col-md-1 ml-auto">{extraTnc !== undefined ? <button type="button" className="delete-icon" onClick={() => removeElement(idx)} ><i className="fa fa-trash"></i></button> : ""}</div>
                </div>}
            </>
            )
          })} */}
        <div className="col-md-12 pb-3">
          <h3 className="col-md-12">Add New TNC</h3>
          <div className="col-md-12">
            <label className="">TNC Name/Title</label>
            <input type="text" value={newTnc.newTncName} name="newTncName" placeholder="Enter New Spec Name" className="form-control" onChange={handlenewTnc} />
          </div>
          <div className="col-md-12 mt-2 mb-2">
            <label className="">TNC Value/Text</label>
            <textarea rows="5" value={newTnc.newTncValue} name="newTncValue" placeholder="TNC" className="form-control" onChange={handlenewTnc} ></textarea>
          </div>
          {(newTnc.newTncName !== "" && newTnc.newTncValue !== "") && <button className="btn-primary btn ml-2 btn-sm col-md-2" onClick={() => addElement()} >Add +</button>}
        </div>
        {/* </div> */}
      </div>
    </>)
}



export default TncForm;
