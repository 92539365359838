import React, { useEffect, useState } from 'react';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';

const DocAcl = ({ contractNo, setAclModal, aclModal, refreshDoc, setrefreshDoc }) => {

  const [showLoader, setshowLoader] = useState(false);

  const [contractRow, setContractRow] = useState({
    categoryId: aclModal.details.catergory_id,
    tblDocId: aclModal.details.tbl_doc_id,
    data: [{ party: null, authenticate: 0, upload: 0, remove: 0 }]
  });
  const [parties, setParties] = useState([]);

  // Handlers
  const handleChange = (event, ind) => {
    if (event.target.name === 'party') {
      if (contractRow.data.filter((i) => {
        if (i.party / 1 == event.target.value / 1) {
          return true
        }
      })?.length) {
        toastDisplay("User already selected", "info")
        contractRow.data[ind] = { ...contractRow.data[ind], [event.target.name]: null }
      }
      else {
        contractRow.data[ind] = { ...contractRow.data[ind], [event.target.name]: event.target.value }
      }
    } else {
      contractRow.data[ind] = { ...contractRow.data[ind], [event.target.name]: (event.target.checked ? 1 : 0) }
    }
    setContractRow({ ...contractRow })
  }

  // set doc acl
  function submitACL() {
    setshowLoader(true)
    contractRow["contract_number"] = contractNo
    call('POST', 'updateDocPermission', contractRow).then((result) => {
      console.log('=======updateDocPermission Result========', result);
      setAclModal({ state: false, title: '' })
      toastDisplay(result, "success")
      setshowLoader(false)
      setrefreshDoc(refreshDoc + 1)
    }).catch((e) => {
      setshowLoader(false)
      toastDisplay(e, "error")
      console.log("Error while querying updateDocPermission:", e);
    })
  }

  useEffect(() => {
    call('POST', 'getContractUsers', { categoryId: aclModal.details.catergory_id, contractNo: contractNo }).then((result) => {
      setParties(result);
    }).catch((e) => {
      console.log("Error while querying getContractUsers:", e);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addContractRow() {
    contractRow.data.push({ party: null, authenticate: 0, upload: 0, remove: 0 });
    setContractRow({ ...contractRow })
  }
  function deleteContractRow(ind) {
    contractRow.data.splice(ind, 1)
    setContractRow({ ...contractRow })
  }

  function getContractRow() {
    let rowsArr = [];
    for (let i = 0; i < contractRow.data.length; i++) {
      rowsArr.push(
        <>
          <div className="row form-group">
            <div className="col-md-1">
              <span className="field">
                <i className="fa fa-trash fa-2x" aria-hidden="true" onClick={() => deleteContractRow(i)}></i>
              </span>
            </div>
            <div className="col-md-2">
              <span className="field">
                <label className="checkbox" for="checkbox1">Select Party</label>
              </span>
            </div>

            <div className="col-md-3">
              <span className="field">
                <select value={contractRow.data[i]["party"] || ""} className="form-control" name="party" onChange={(event) => handleChange(event, i)} required>
                  <option value="" selected>--Select Party--</option>
                  {
                    parties.map((item) => {
                      return (
                        <option value={item.tbl_user_id} >{item.company_name}</option>
                      )
                    }
                    )
                  }
                </select>
              </span>
            </div>

            <div className="col-md-2">
              <span className="field">
                <input type="checkbox" name="authenticate" id={"checkbox1" + i} onBlur={(event) => handleChange(event, i)} />
                <label className="checkbox" for={"checkbox1" + i}>Auth</label>
              </span>
            </div>
            <div className="col-md-2">
              <span className="field">
                <input type="checkbox" name="upload" id={"checkbox2" + i} onBlur={(event) => handleChange(event, i)} />
                <label className="checkbox" for={"checkbox2" + i}>Upload</label>
              </span>
            </div>
            <div className="col-md-2">
              <span className="field">
                <input type="checkbox" name="remove" id={"checkbox3" + i} onBlur={(event) => handleChange(event, i)} />
                <label className="checkbox" for={"checkbox3" + i}>Delete</label>
              </span>
            </div>
          </div>
        </>
      )
    }
    return rowsArr;
  }

  // JSX return
  return (
    <div className="modal show" id="docAcl">
      <div className="modal-dialog modal-xl border-inner" id="parent_class">
        <div className="modal-content">
          <div className="modal-header primary">
            <h4 className="modal-title text-white">Define Document ACL</h4>
            <button type="button" className="close" onClick={() => setAclModal({ state: false, title: '' })}>×</button>
          </div>
          <div className="modal-body calc-inner-modal">
            {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

            <div className="col-md-12 mb-2 ">
              <div className="row form-group">
                <div className="col-md-6">
                  <h3>{aclModal.details.pretty_name}</h3><br />
                </div>

                <div className="ml-auto">
                  <input type="button" className="btn btn-primary btn-sm" value="Add +" onClick={() => addContractRow()} />
                </div>
              </div>
              {
                getContractRow()
              }
            </div>
          </div>
          <div className="modal-footer primary">
            <button type="button" className="btn btn-danger btn-sm" onClick={() => setAclModal({ state: false, title: '' })}>Close</button>
            <button type="button" className="btn btn-info btn-sm" onClick={() => submitACL()}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocAcl;
