import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setInvoiceDetail } from '../../store/actions/action';
import call from '../../service';
// import DigitalSignDoc from '../contractDocument/digitalSignDoc'


const InvoiceView = ({ userTokenDetails, setInvoiceDetail, InvoiceDetail, dispatch }) => {

  const contractNo = InvoiceDetail.info.contractNo
  const invoiceType = InvoiceDetail.info.invoiceType
  const [fileData, setfileData] = useState({});
  const [srcData, setsrcData] = useState("");
  // const [showAclAuthModal, setAclAuthModal] = useState(false)


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    //User details from cookie

    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getinvoiceforcontract', { "contract_number": contractNo, "invoiceType": invoiceType }).then(async (result) => {
      console.log("get invoice details in view invoice ->", result)
      setfileData(result.fileData ? result.fileData : { data: null })
      setsrcData(result.fileBase64)
    }).catch((error) => {
      console.log("error occur in getcontractdetails ->", error)
    })

    //------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------------------------------

  console.log("fileData in gen invoice ->", fileData)
  console.log("srcData in gen invoice ->", srcData)

  function viewTheFile() {
    let mime = fileData.file_name.split(".").pop()
    let sourceType = getSourceType(mime)
    return (
      <>
        {
          (mime === "pdf" || mime === "PDF") ?
            <iframe title="Document Preview" frameborder="0" style={{ height: "75vh", width: "100vw" }} className="col-md-12 m-0 p-0"
              src={sourceType + encodeURI(srcData ? srcData : "")}></iframe>
            :
            <div className="col-md-12 overflow-auto">
              <img src={sourceType + encodeURI(srcData ? srcData : "")} alt={fileData.file_name} />
            </div>
        }
      </>

    )
  }

  function getSourceType(mime) {
    return ((mime === "png" || mime === "PNG") ? "data:image/png;base64," :
      (mime === "jpg" || mime === "JPG") ? "data:image/jpeg;base64," :
        (mime === "pdf" || mime === "PDF") ? "data:application/pdf;base64," : "")
  }

  // function callAclAuthComponent(values) {
  //   console.log("in acl call")
  //   setfileData({ ...values })
  //   setAclAuthModal(true);
  // }

  return (
    <>
      <div className="col-md-12">
        <h2 className="text-primary">{invoiceType === "contract" ? "Contract Invoice for " : "Freight Invoice for "}- ({contractNo})</h2>
        {/* <button title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
          () => setAclAuthModal(true)}>
          <i className="fa fa-shield" aria-hidden="true"></i>
        </button> */}
        <hr className="p-1" />
      </div>
      {(srcData && fileData) ? viewTheFile() : fileData.data === null ? <h1>Invoice Not Available</h1> : <h1>Fetching Invoice Data Please Wait....</h1>}

      {/* {showAclAuthModal &&
        <DigitalSignDoc
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showAclAuthModal={showAclAuthModal}
          setAclAuthModal={setAclAuthModal} />} */}
    </>
  )
};

const mapStateToProps = state => {
  return {
    InvoiceDetail: state.InvoiceDetail
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setInvoiceDetail: (flag, data) => { dispatch(setInvoiceDetail({ modal: flag, info: data })) }
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceView)
