import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import DataTable from 'react-data-table-component';
import FilePreview from '../utilComponents/previewFile';
import customStyles from '../dataTablesColumsMap/customTableCss';
import SignPad from '../contractDocument/signPadForChannelPartner';
import dataUser from './agreementT&C.json';
import tradeTechAnnexure from './tradeTechAnnexure.json';
import finTechAnnexure from './finTechAnnexure.json';
import logiTechAnnexure from './logiTechAnnexure.json';
import insureTechAnnexure from './insureTechAnnexure.json';
import config from '../../config.json';
import validate from '../../utils/validation_files/channelPartnerAgreement';
import franchiseTC from './franchiseAgreementT&C.json'

const ChannelPartnerAgreement = ({ userTokenDetails }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables

  const [showLoader, setshowLoader] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [agreementUserInfo, setAgreementUserInfo] = useState({});
  const [reqId, setReqId] = useState(0);
  const [showAclAuthModal, setAclAuthModal] = useState(false);
  const [refreshDoc, setrefreshDoc] = useState(0);
  const [addFileData, setaddFileData] = useState('');
  const [dataTnc, setdataTnc] = useState({});
  const [tradetechAnnexure, settradetechAnnexure] = useState({});
  const [fintechAnnexure, setfintechAnnexure] = useState({});
  const [logitechAnnexure, setlogitechAnnexure] = useState({});
  const [insuretechAnnexure, setinsuretechAnnexure] = useState({});
  const [tab, setTab] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accordion, setAccordion] = useState(0);
  const [agreementTemplate, setAgreementTemplate] = useState({})
  const [viewDocFromMstTable, toggleViewDocFromMstTable] = useState(false)
  const columns = [
    {
      name: 'Sr. No.',
      selector: 'sr_no',
      sortable: true,
      style: {
        backgroundColor: 'rgba(63, 195, 128, 0.9)',
        color: 'white',
      },
    },
    {
      name: 'Type of Document',
      selector: 'type_of_document',
      sortable: true,
      grow: 1,
    },
    {
      name: 'Document Name',
      selector: 'document_name',
      sortable: true,
      grow: 1,
    },
    {
      name: 'Created By',
      selector: 'created_by',
      sortable: true,
      grow: 1,
    },
    {
      name: 'Signed By',
      selector: 'signed_by',
      sortable: true,
      grow: 1,
    },
    {
      name: "Action",
      selector: 'action',
      sortable: false,
      grow: 1
    }
  ];
  const signStyle = {
    "font-family": "Times New Roman",
    "font-style": "italic",
    "font-size": "28px"
  }
  const astrix = <span className="required-field text-danger">*</span>;

  const handleAccordion = (accordion) => setAccordion(accordion);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    fetchTableData(1, {})
  }, [reqId])

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      submitAgreement();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  useEffect(() => {
    window.onload = function () {
      try {
        var url_string = (window.location.href);
        var url = new URL(url_string);
        var id = url.searchParams.get('id');
        setReqId(id);
        call('POST', 'getAgreementInfo', { "reqId": id }).then((result) => {
          console.log('running getAgreementInfo api-->', result);
          if (result[0].channel_partner_role === 'CP') {
            setdataTnc(franchiseTC);
          }
          if (result[0].channel_partner_role === 'FP') {
            setdataTnc(franchiseTC);
          }
          setAgreementUserInfo(result[0]);
        }).catch((e) => {
          console.log('error in getAgreementInfo', e);
          toastDisplay("Error", "error")
        });
      } catch (error) {
        console.log('error in getAgreementInfo ', error);
      }
    }
    setdataTnc(dataUser);
    settradetechAnnexure(tradeTechAnnexure);
    setfintechAnnexure(finTechAnnexure);
    setlogitechAnnexure(logiTechAnnexure);
    setinsuretechAnnexure(insureTechAnnexure);
  }, []);
  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (agreementUserInfo.channel_partner_role && agreementUserInfo.techTypeId) {
      getAgreementTemplate(agreementUserInfo.channel_partner_role, agreementUserInfo.techTypeId)
    }
  }, [agreementUserInfo])

  function getAgreementTemplate(role, type) {
    call('POST', 'getChannelPartnerAgreementTemplate', { role, type, reqId }).then(async (result) => {
      console.log("api result in getChannelPartnerAgreementTemplate-->", result)
      if (result.file_hash) {
        setAgreementTemplate(result)
      }
      else {
        setAgreementTemplate({})
      }
    }).catch((e) => {
      // console.log("Error while querying getChannelPartnerAgreementTemplate:", e);
    })
  }

  function fetchTableData(page, inptObject) {
    setLoading(true);

    call('POST', 'getAgreementInfo', { "reqId": reqId }).then((result) => {
      console.log('running getAgreementInfo api ---->', result);
      settableData(formatDataFortable(result))
      setLoading(false);
    }).catch((e) => {
      console.log('error in getAgreementInfo', e);
      setLoading(false);
      toastDisplay("Error", "error")
    });
  }

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {};
      tempObj.sr_no = (i + 1);
      tempObj.type_of_document = 'Party Agreement';
      tempObj.document_name = `${(dataArray[i]["channel_partner_role"] === "CP" ? 'Channel Partner' : 'Franchise Partner') + (' Agreement Document')}`;
      tempObj.created_by = 'TradeReboot Fintech Pvt. Ltd.';
      tempObj.signed_by = <>{(dataArray[i] && dataArray[i].docSignArray.length) ?
        <pre><span className="text-success mr-1">{dataArray[i].docSignArray.map((signV) => { return (<><i className="fa fa-certificate" /> {signV.sign}<br /></>) })}</span></pre>
        : <pre><span className="text-success mr-1"> <><i className="fa fa-certificate" /> Party<br /></> </span></pre>}</>
      dataArray[i].doc_name = 'Channel Partner Agreement Document';
      dataArray[i].document_name = 'Party Agreement';
      dataArray[i].uploaded_by = `TradeReboot Fintech Pvt. Ltd.`;
      dataArray[i].uploaded_on = dataArray[i].created_at;
      dataArray[i].type_id = 20;
      dataArray[i].tbl_doc_id = dataArray[i].agreement_doc_id;
      dataArray[i].signType = 'Channel_Partner_Agreement';
      dataArray[i].userId = dataArray[i].tbl_user_id;
      dataArray[i].email = dataArray[i].email_id;
      tempObj.action = <>
        <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
          () => callPreviewFileComponent(dataArray[i].agreement_doc_id, "view", true)}>
          <i class="fas fa-eye"></i>
        </button>
        <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
          () => callPreviewFileComponent(dataArray[i].agreement_doc_id, "download", true)}>
          <i className="fa fa-download" aria-hidden="true"></i>
        </button>
      </>
      resultArray.push(tempObj)
    }
    return resultArray
  }
  //--------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------
  //Functions

  // function callPreviewFileComponent(values, action) {
  //   setfileData({ tbl_doc_id: values, "action": action })
  //   setshowPreviewModal(true)
  // }

  function callPreviewFileComponent(values, action, viewFromMasterTable) {
    if (viewFromMasterTable) {
      setfileData({ tbl_doc_id: values, "action": action })
    }
    else {
      setfileData({
        id: values.id,
        file_hash: values.file_hash,
        file_name: values.file_name,
        file_type: values.doc_name,
        created_at: values.created_at,
        "action": action
      })
    }
    setshowPreviewModal(true)
    toggleViewDocFromMstTable(viewFromMasterTable)
  }


  function tncBodyGenerator() {
    let keyArray = Object.keys(dataTnc).length ? Object.keys(dataTnc) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!dataTnc[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{dataTnc[key].name ? dataTnc[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={dataTnc[key].value.replace(/<br>/g, "\n")} disabled></textarea>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  function tradeAnnexureBody() {
    let keyArray = Object.keys(tradetechAnnexure).length ? Object.keys(tradetechAnnexure) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!tradetechAnnexure[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{tradetechAnnexure[key].name ? tradetechAnnexure[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={tradetechAnnexure[key].value.replace(/<br>/g, "\n")} disabled></textarea>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  function finAnnexureBody() {
    let keyArray = Object.keys(fintechAnnexure).length ? Object.keys(fintechAnnexure) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!fintechAnnexure[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{fintechAnnexure[key].name ? fintechAnnexure[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={fintechAnnexure[key].value.replace(/<br>/g, "\n")} disabled></textarea>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  function logiAnnexureBody() {
    let keyArray = Object.keys(logitechAnnexure).length ? Object.keys(logitechAnnexure) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!logitechAnnexure[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{logitechAnnexure[key].name ? logitechAnnexure[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={logitechAnnexure[key].value.replace(/<br>/g, "\n")} disabled></textarea>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  function insureAnnexureBody() {
    let keyArray = Object.keys(insuretechAnnexure).length ? Object.keys(insuretechAnnexure) : []
    return (
      <div className="accordionWrapper">
        {keyArray.map((key, index) => {
          return (
            <div className="col-md-12 row">
              <div className={"accordionItem col-md-11" + (accordion === index ? " open" : " closed")}>
                <h2 className={"accordionItemHeading " + (!insuretechAnnexure[key].value ? "text-danger" : "")} onClick={() => handleAccordion(index)}>{insuretechAnnexure[key].name ? insuretechAnnexure[key].name : ''}</h2>
                <div className="accordionItemContent">
                  <textarea rows="7" className="form-control" name={key + ',value'} value={insuretechAnnexure[key].value.replace(/<br>/g, "\n")} disabled></textarea>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  function submitStamp(type) {

    console.log("data in sign submit==>", data)
    setshowLoader(true);
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })
    formData.append('signPadBase64', addFileData);
    formData.append('userTypeId', 20);
    formData.append('reqId', reqId);

    call('POST', 'saveImage', formData).then((result) => {
      console.log("=== saveImage === ", result)
      setshowLoader(false);
      alert("Digital Signature Added successfuly", "success");
      setrefreshDoc(refreshDoc + 1)
    }).catch(err => {
      setshowLoader(false);
      alert(err, "error");
    })
  }

  function submitAgreement() {
    setshowLoader(true);
    data.reqId = reqId;
    // data.dataTnc = dataTnc;
    // data.tradetechAnnexure = tradetechAnnexure;
    // data.fintechAnnexure = fintechAnnexure;
    // data.insuretechAnnexure = insuretechAnnexure;
    // data.logitechAnnexure = logitechAnnexure;
    data.company_name = agreementUserInfo.company_name;
    data.contact_person = agreementUserInfo.contact_person;
    data.organization_type = agreementUserInfo.organization_type;
    data.address = agreementUserInfo.user_address;
    data.submitBy = 'Party';
    data.baseUrl = config.baseUrl
    data.typeId = agreementUserInfo.type_id
    data.password = agreementUserInfo.password;
    data.tbl_user_id = agreementUserInfo.tbl_user_id;
    data.iec_no = agreementUserInfo.iec_no;
    data.gst_vat_no = agreementUserInfo.gst_vat_no;
    data.email_id = agreementUserInfo.email_id;
    data.techTypeId = agreementUserInfo.techTypeId;
    data.agreementFileHash = agreementTemplate.file_hash
    data.role = agreementUserInfo.channel_partner_role
    data.pan_no = agreementUserInfo.pan_no
    data.country_code = agreementUserInfo.country_code
    data.aadhar_no = agreementUserInfo.aadhar_no
    call('POST', 'submitAgreement', data).then((result) => {
      console.log("=== submitAgreement === ", result)
      alert(result);
      setshowLoader(false);
      setrefreshDoc(refreshDoc + 1)
      window.location.reload()
    }).catch(err => {
      console.log(err);
      setshowLoader(false);
      alert(err);
      window.location.reload()
    })
  }

  //---------------------------------------------------------------------------------------------------------------------
  //Handlers

  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  const handleFile = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.files[0] }));
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = (e) => {
      setaddFileData(e.target.result);
    }
  }

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data, true));
    setIsSubmitting(true);
  };
  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {/* logo start */}
      <div className="logo">
        <img height="60" className="default_logo" src="assets/images/logos/logo_1.png" />
        <img height="60" className="mini_logo" src="assets/images/logos/logo_mini.png" />
      </div>
      <div className="col-md-12 mb-2 text-center">
        <h1 className="green border-bottom pb-2"><b>Party Agreement of {agreementUserInfo.company_name} </b></h1>
      </div>
      {/* logo end */}
      <div className="col-md-12 pt-2 pb-5 mt-5">

        <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      Company Party Info
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">
                    <div className="row pt-2">
                      <div className="form-group col-md-12">
                        <div className="modal-padding">
                          <ul className="price-ul">
                            <li>
                              <div className="price-left"> {agreementUserInfo && agreementUserInfo.company_name ? agreementUserInfo.company_name : 'Not Available'}</div>
                              <div className="price-right">Company Name </div>
                            </li>
                            <li>
                              <div className="price-left">{agreementUserInfo && agreementUserInfo.email_id ? agreementUserInfo.email_id : 'Not Available'}</div>
                              <div className="price-right">Email Id</div>
                            </li>
                            <li>
                              <div className="price-left"> {agreementUserInfo && agreementUserInfo.contact_person ? agreementUserInfo.contact_person : 'Not Available'}</div>
                              <div className="price-right">Contact Person </div>
                            </li>
                            <li>
                              <div className="price-left"> {agreementUserInfo && agreementUserInfo.user_address ? agreementUserInfo.user_address : 'Not Available'}</div>
                              <div className="price-right">Address </div>
                            </li>
                            <li>
                              <div className="price-left">{agreementUserInfo && agreementUserInfo.country_code ? agreementUserInfo.country_code : 'Not Available'}</div>
                              <div className="price-right">Country</div>
                            </li>
                            <li>
                              <div className="price-left">{agreementUserInfo && agreementUserInfo.contact_number ? agreementUserInfo.contact_number : 'Not Available'}</div>
                              <div className="price-right">Contact Number</div>
                            </li>
                            <li>
                              <div className="price-left">{agreementUserInfo && agreementUserInfo.organization_type ? agreementUserInfo.organization_type : 'Not Available'}</div>
                              <div className="price-right">Entity</div>
                            </li>
                            {agreementUserInfo.country_code === 'IN' && agreementUserInfo.organization_type === 'individual' &&
                              <li>
                                <div className="price-left">{agreementUserInfo && agreementUserInfo.pan_no ? agreementUserInfo.pan_no : 'Not Available'}</div>
                                <div className="price-right">PAN Number</div>
                              </li>}
                            {agreementUserInfo.country === 'IN' && agreementUserInfo.organization_type !== 'individual' &&
                              <>
                                <li>
                                  <div className="price-left">{agreementUserInfo && agreementUserInfo.pan_no ? agreementUserInfo.pan_no : 'Not Available'}</div>
                                  <div className="price-right">PAN Number</div>
                                </li>
                                <li>
                                  <div className="price-left">{agreementUserInfo && agreementUserInfo.gst_vat_no ? agreementUserInfo.gst_vat_no : 'Not Available'}</div>
                                  <div className="price-right">GST Number</div>
                                </li>
                              </>
                            }
                            {agreementUserInfo.country_code === 'IN' && agreementUserInfo.organization_type === 'pvtPubLtd' &&
                              <li>
                                <div className="price-left">{agreementUserInfo && agreementUserInfo.cin_no ? agreementUserInfo.cin_no : 'Not Available'}</div>
                                <div className="price-right">CIN Number</div>
                              </li>}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {agreementUserInfo.reqStatus === 3 &&
          <>

            <div className="accordionWrapper row pr-0 pt-0">
              <div className="container-fluid accordionItem open">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <h3 className="green mt-3">
                          Party Credentials
                        </h3>
                        <hr />
                      </div>
                      <div className="col-md-12 row">

                        {/* <div className="col-md-4">
                          <div className="row form-group">
                            <div className="col-md-12">
                              <label>Designation {astrix} </label>
                              <select className="form-control" name="party_designation" value={data.party_designation}
                                onChange={handleChange}>
                                <option value="" selected>--Select Designation--</option>
                                <option value={"Proprietor"}>{"Proprietor"}</option>
                                <option value={"Partner"}>{"Partner"}</option>
                                <option value={"Director"}>{"Director"}</option>
                              </select>
                              <input type="text" className={" form-control" + (!error.party_designation ? '' : ' border-danger')} placeholder="Enter Company Name" name="party_designation" value={data.party_designation} onChange={handleChange} required />
                              {error.party_designation && <p className="text-danger error-contract">{error.party_designation}</p>}
                            </div>
                          </div>
                        </div> */}

                        {/* <div className="col-md-4">
                          <div className="row form-group">
                            <div className="col-md-12 ">
                              <label>Date {astrix} </label>
                              <input type="date" className={" form-control" + (!error.party_date ? '' : ' border-danger')} placeholder="Enter Company Name" name="party_date" value={data.party_date} onChange={handleChange} required />
                              {error.party_date && <p className="text-danger error-contract">{error.party_date}</p>}
                            </div>
                          </div>
                        </div> */}

                        {/* <div className="col-md-4">
                          <div className="row form-group">
                            <div className="col-md-12 ">
                              <label>Place {astrix} </label>
                              <input type="text" className={" form-control" + (!error.party_place ? '' : ' border-danger')} placeholder="Enter Company Name" name="party_place" value={data.party_place} onChange={handleChange} required />
                              {error.party_place && <p className="text-danger error-contract">{error.party_place}</p>}
                            </div>
                          </div>
                        </div> */}

                      </div>
                      <div className="col-md-12">
                        <div className="row pt-2">
                          {/* <h2 className="accordionItemHeading">Witness 1 Details</h2> */}
                          {/* <div className="col-md-12 row">
                            <div className="col-md-4">
                              <div className="row form-group">
                                <div className="col-md-12 ">
                                  <label>Name {astrix} </label>
                                  <input maxLength={30} type="text" className={" form-control" + (!error.party_witness1_name ? '' : ' border-danger')}
                                    placeholder="Enter Witness Name" name="party_witness1_name"
                                    value={data.party_witness1_name} onChange={handleChange} required />
                                  {error.party_witness1_name && <p className="text-danger error-contract">{error.party_witness1_name}</p>}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="row form-group">
                                <div className="col-md-12 ">
                                  <label>Aadhar/PAN Number {astrix} </label>
                                  <input maxLength={15} type="text" className={" form-control" + (!error.party_witness1_aadhar ? '' : ' border-danger')} placeholder="Enter Witness Aadhar" name="party_witness1_aadhar"
                                    value={data.party_witness1_aadhar} onChange={handleChange} required />
                                  {error.party_witness1_aadhar && <p className="text-danger error-contract">{error.party_witness1_aadhar}</p>}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="row form-group">
                                <div className="col-md-12 ">
                                  <label>Address {astrix} </label>
                                  <input maxLength={30} type="text" className={" form-control" + (!error.party_witness1_address ? '' : ' border-danger')} placeholder="Enter Witness Address" name="party_witness1_address" alue={data.party_witness1_address} onClick={handleChange} required />
                                  {error.party_witness1_address && <p className="text-danger error-contract">{error.party_witness1_address}</p>}
                                </div>
                              </div>
                            </div>

                          </div> */}
                          {/* <h2 className="accordionItemHeading">Witness 2 Details</h2>
                          <div className="col-md-12 row">
                            <div className="col-md-4">
                              <div className="row form-group">
                                <div className="col-md-12 ">
                                  <label>Name {astrix} </label>
                                  <input type="text" className={" form-control" + (!error.party_witness2_name ? '' : ' border-danger')}
                                    placeholder="Enter Witness Name" name="party_witness2_name" value={data.party_witness2_name} onChange={handleChange} required />
                                  {error.party_witness2_name && <p className="text-danger error-contract">{error.party_witness2_name}</p>}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="row form-group">
                                <div className="col-md-12 ">
                                  <label>Aadhar/PAN Number {astrix} </label>
                                  <input type="text" className={" form-control" + (!error.party_witness2_aadhar ? '' : ' border-danger')} placeholder="Enter Witness Aadhar" name="party_witness2_aadhar"
                                    value={data.party_witness2_aadhar} onChange={handleChange} required />
                                  {error.party_witness2_aadhar && <p className="text-danger error-contract">{error.party_witness2_aadhar}</p>}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="row form-group">
                                <div className="col-md-12 ">
                                  <label>Address {astrix} </label>
                                  <input type="text" className={" form-control" + (!error.party_witness2_address ? '' : ' border-danger')} placeholder="Enter Witness Address" name="party_witness2_address"
                                    value={data.party_witness2_address} onChange={handleChange} required />
                                  {error.party_witness2_address && <p className="text-danger error-contract">{error.party_witness2_address}</p>}
                                </div>
                              </div>
                            </div>

                          </div> */}
                        </div>
                      </div>

                      {agreementUserInfo.party_sign_fileHash == null &&
                        <div className="col-md-12">
                          {/* DIGITIAL SIGN */}
                          <div className={"accordionItem mt-3"}>
                            <h2 className="accordionItemHeading"> Electronic Signature Process</h2>
                            <div className="accordionItemContent">
                              <div className="row">

                                <ul className="nav nav-tabs-custom btn m-0 p-0" id="myTab" role="tablist">
                                  <li>
                                    <a className={"nav-link" + (tab === 0 ? " active show" : "")} onClick={() => setTab(0)}>Type Sign</a>
                                  </li>

                                  <li>
                                    <a className={"nav-link" + (tab === 1 ? " active show" : "")} onClick={() => setTab(1)}>E-Sign</a>
                                  </li>

                                  <li>
                                    <a className={"nav-link" + (tab === 2 ? " active show" : "")} onClick={() => setTab(2)}>Upload Sign</a>
                                  </li>
                                </ul>

                                <div className="d-flex m-0 p-0" style={{ "height": "90%" }}>
                                  {tab === 0 &&
                                    <div className="col-md-12">
                                      <br />
                                      <br />
                                      <h2>Type Sign</h2>
                                      <div className="col-md-12 row form-group float-right d-flex">
                                        <div className="col-md-5">
                                          <label >Place</label>
                                          <textarea rows="2" className="form-control" placeholder="Enter Comments" name="comment" onChange={handleChange} required></textarea>
                                        </div>
                                        <div className="col-md-5">
                                          <label >Digital Signature</label>
                                          <textarea rows="1" className="form-control" style={signStyle} placeholder="Enter Full Name" name="sign" onChange={handleChange} required></textarea>
                                        </div>
                                        <div className="col-md-2">
                                          <button type="button" className="btn btn-success mt-5 text-center btn-sm"
                                            onClick={() => submitStamp()}
                                          >Submit</button>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  {tab === 1 &&
                                    <div className="col-md-12">
                                      <br />
                                      <br />
                                      <h2>E-Sign Pad</h2>
                                      <SignPad reqId={reqId} refreshDoc={refreshDoc} setrefreshDoc={setrefreshDoc} />
                                    </div>
                                  }
                                  {tab === 2 &&
                                    <div className="form-group pb-0 mb-0">
                                      <br />
                                      <br />
                                      <h2>Upload E-Sign Image</h2>
                                      <ul className="other-documents pb-0 mb-0">
                                        <li style={{ "min-width": "300px" }}>
                                          <label className="mb-0" >
                                            <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                            E-Sign Image Upload
                                            <span className="required-field text-danger">*</span>
                                          </label>
                                        </li>
                                        <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                                          <div className="file-browse">
                                            <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                                            <input type="file" accept=".jpg,.png,.jpeg" id="file_1" name={"e_sign"} onChange={handleFile} />
                                          </div>
                                        </li>
                                        <li>
                                          {
                                            addFileData &&
                                            <iframe title="Document Preview" frameborder="0" src={addFileData} >
                                            </iframe>
                                          }
                                        </li>
                                      </ul>
                                      <button type="button" className="btn btn-success mt-5 text-center btn-sm"
                                        onClick={() => submitStamp()}
                                      >Submit</button>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>}

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={"accordionItem mt-3" + (" open")}>
              <h2 className="accordionItemHeading">Agreement Document</h2>
              <div className="accordionItemContent">
                <div className='row my-4'>
                  <div className='col-md-4'>
                    <input type="text" className={" form-control"} disabled placeholder={"Enter document name"} name={"docName"}
                      value={agreementTemplate.doc_name} />
                  </div>

                  <div className='col-md-4'>
                    {agreementTemplate.file_name ?
                      <div className="form-group mb-0"><div className="file-name mt-2">{`${agreementTemplate.file_name}`}</div></div> : ""}
                  </div>

                  <div className='col-md-2'>
                    {agreementTemplate.file_name ? (
                      <>
                        <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                          () => callPreviewFileComponent(agreementTemplate, "view", false)}>
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>

              </div>
            </div>

            {/* <div className={"accordionItem"}>
              <h2 className="accordionItemHeading">Terms & Conditions</h2>
              <div className="accordionItemContent">
                <div className="col-md-12 row">
                  {tncBodyGenerator()}
                </div>
              </div>
            </div> */}

            {/* {agreementUserInfo.techTypeId == 4 &&
              <div className={"accordionItem"}>
                <h2 className="accordionItemHeading">ANNEXURE A</h2>
                <div className="accordionItemContent">
                  <div className="col-md-12 row">
                    {tradeAnnexureBody()}
                  </div>
                </div>
              </div>} */}

            {/* {agreementUserInfo.techTypeId == 5 &&
              <div className={"accordionItem"}>
                <h2 className="accordionItemHeading">ANNEXURE A</h2>
                <div className="accordionItemContent">
                  <div className="col-md-12 row">
                    {finAnnexureBody()}
                  </div>
                </div>
              </div>} */}

            <button type="button" className="btn btn-primary float-right btn-sm"
              onClick={() => preHandleSubmit()}
            >Submit</button>
          </>}

        {agreementUserInfo.reqStatus === 4 && <div className="accordionWrapper row pr-0 pt-0">
          <div className="container-fluid accordionItem open">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="green mt-3">
                      Agreement Document
                    </h3>
                    <hr />
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={tableData}
                        progressPending={loading}
                        pagination
                        paginationServer
                        progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                        persistTableHead
                        highlightOnHover
                        sortServer
                        striped
                        noHeader
                        customStyles={customStyles}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
      {
        showPreviewModal &&
        <FilePreview
          mktPlaceShipment={!viewDocFromMstTable}
          userTokenDetails={userTokenDetails}
          fileData={fileData}
          showPreviewModal={showPreviewModal}
          setshowPreviewModal={setshowPreviewModal}
          viewTrail={false}
        />
      }
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelPartnerAgreement)