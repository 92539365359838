import React, { useEffect, useState } from 'react';
import { setAddShip } from '../../store/actions/action';
import { connect } from 'react-redux';
import call from '../../service';
import toastDisplay from '../../utils/toastNotification';
import { dateFormatter_DatePicker } from '../../utils/dateFormaters';
import validate from '../../utils/validation_files/addVesselValidationRules';
import commImageUrl from '../../utils/makeCommImageUrl'


const AddShip = ({ userTokenDetails, setAddShip, showAddShip, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({});
  const [dbSpecData, setdbSpecData] = useState({});
  const [commArray, setcommArray] = useState([]);
  const [updateData, setupdateData] = useState(false);
  const [shipsTypeData, setshipsTypeData] = useState([]);
  const [showLoader, setshowLoader] = useState(false);

  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    setData(data => ({ ...data, "owner": userId }));
    //------------------------------------------------------------------
    //API Calls
    call('POST', 'getCommodityList', { specifications: false, commadd: false }).then((result) => {
      console.log("API result getCommodityList:-->", result);
      setdbSpecData(result.message);
    }).catch((e) => {
      console.log("Error while querying getCommodityList:", e);
    })

    call('get', 'getShipsType').then((result) => {
      setshipsTypeData(result)
    }).catch((e) => {
      console.log('error in getShipsType', e);
    })

    SetformData()
    //------------------------------------------------------------------
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit()
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
    setError({ ...error, [event.target.name]: "" });
  };

  function handleCommodity(commID) {
    let tempArray = [...commArray]
    if (tempArray.indexOf(commID) >= 0) {
      tempArray.splice(tempArray.indexOf(commID), 1)
      setcommArray(tempArray)
    } else {
      tempArray.push(commID)
      setcommArray(tempArray)
    }
    setError({ ...error, "commodity": "" });
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age ? age : 0 + " years old";
  }

  function SetformData() {

    console.log("showAddShip.info.value===>", showAddShip)

    if (showAddShip.info.value && (Object.keys(showAddShip.info.value).length)) {
      setData(data => ({
        ...data,
        shipType: showAddShip.info.value.type,
        imoNo: showAddShip.info.value.imo_no,
        manufactureDate: dateFormatter_DatePicker(showAddShip.info.value.date_manufacture),
        owner: showAddShip.info.value.owner,
        registryPort: showAddShip.info.value.registry_port,
        shipCategory: showAddShip.info.value.category,
        shipCharter: showAddShip.info.value.ship_charter,
        shipCurrency: showAddShip.info.value.currency,
        shipName: showAddShip.info.value.ship_name,
        shipStatus: showAddShip.info.value.ship_status,
        breadthv: showAddShip.info.value.breadthv,
        depth: showAddShip.info.value.depth,
        grossTons: showAddShip.info.value.gross,
        lengthv: showAddShip.info.value.lengthv,
        netTons: showAddShip.info.value.net,
        shipDBid: showAddShip.info.value.id,
        draft: showAddShip.info.value.draft,
        deadWeight: showAddShip.info.value.dead_weight
      }));

      setcommArray(showAddShip.info.value.commodities.split(','))

      setupdateData(true)
    }
  }

  function getShipscategorybody() {
    let htmlBody
    for (let i = 0; i < shipsTypeData.length; i++) {
      if (shipsTypeData[i].ship_type === data.shipType) {
        let catArray = shipsTypeData[i].ship_category.split(',')
        htmlBody = catArray.length ? catArray.map((item, index) => {
          return (<option value={item}>{item}</option>)
        }) : ''
        return htmlBody
      }
    }

  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler

  const handleSubmit = () => {
    setshowLoader(true)
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    formData.append("commodityList", commArray);

    call('POST', 'ship', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        setAddShip(false, { refresh: true })
        toastDisplay("Ship Updated!", "success")
      }
    }).catch(e => {
      setshowLoader(false)
      toastDisplay(e, "error")
      //setAddShip(false, {})
    })
  }

  const prehandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data, commArray.length));
    setIsSubmitting(true);
  };
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Ship list Components
  function getshipsTypeList() {
    let shipsTypeList = shipsTypeData.length ? shipsTypeData.map((item, index) => {
      return (<option value={item.ship_type}>{item.ship_type_Name}</option>)
    }) : ''
    return shipsTypeList
  }
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {showAddShip.modal && <div className={"modal" + (showAddShip.modal ? " show" : "")} id="shipmodal">
        <div className="modal-dialog modal-xl border-inner" id="parent_class">
          <div className="modal-content">
            <div className="modal-header primary">
              <h4 className="modal-title text-white">{!updateData ? 'Add Vessel' : 'Update Vessel'}</h4>
              <button type="button" className="close" onClick={() => { setAddShip(false, {}); }}>×</button>
            </div>
            <div className="modal-body calc-inner-modal">
              {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
              <div className="row">
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">IMO No. {astrix} </label>
                    <div className="col-md-12">
                      <input type="number" name="imoNo" value={data.imoNo} placeholder="Enter IMO Number" className={" form-control" + (!error.imoNo ? '' : ' border-danger')} onChange={handleChange} disabled={updateData ? true : false} />
                      {error.imoNo ? <div class="text-danger error-contract">{error.imoNo}</div> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Name of Vessel {astrix} </label>
                    <div className="col-md-12">
                      <input type="text" name="shipName" value={data.shipName} placeholder="Enter Ship Name" className={" form-control" + (!error.shipName ? '' : ' border-danger')} onChange={handleChange} disabled={updateData ? true : false} />
                      {error.shipName ? <div class="text-danger error-contract">{error.shipName}</div> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Vessel Type {astrix} </label>
                    <div className="col-md-12">
                      <select className={" form-control" + (!error.shipType ? '' : ' border-danger')} name="shipType" value={data.shipType} onChange={handleChange}>
                        <option value="" selected>--Select Ship Type--</option>
                        {getshipsTypeList()}
                      </select>
                      {error.shipType ? <div class="text-danger error-contract">{error.shipType}</div> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Date of Manufacture {astrix} </label>
                    <div className="col-md-6">
                      <input type="date" name="manufactureDate" value={data.manufactureDate} className={" form-control" + (!error.manufactureDate ? '' : ' border-danger')} onChange={handleChange} required />
                      {error.manufactureDate ? <div class="text-danger error-contract">{error.manufactureDate}</div> : ''}
                    </div>
                    {/* <label className="col-md-12">Vessel Age (year)</label> */}
                    <div className="col-md-6">
                      <input type="text" name="age" value={getAge(data.manufactureDate)} className="form-control" disabled />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Port of Registry {astrix} </label>
                    <div className="col-md-12">
                      <input type="text" name="registryPort" value={data.registryPort} placeholder="Enter Registry Port" className={" form-control" + (!error.registryPort ? '' : ' border-danger')} onChange={handleChange} />
                      {error.registryPort ? <div class="text-danger error-contract">{error.registryPort}</div> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Open From {astrix} </label>
                    <div className="col-md-12">
                      <input type="text" name="openFromCountry" value={data.openFromCountry} placeholder="" className={" form-control" + (!error.openFromCountry ? '' : ' border-danger')} onChange={handleChange} />
                      {error.openFromCountry ? <div class="text-danger error-contract">{error.openFromCountry}</div> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Vessel Status {astrix} </label>
                    <div className="col-md-12">
                      <select className={" form-control" + (!error.shipStatus ? '' : ' border-danger')} name="shipStatus" value={data.shipStatus} onChange={handleChange}>
                        <option value="" selected>--Select Ship Status--</option>
                        <option value="1">Busy/In-Use</option>
                        <option value="2">Free/Not In-use</option>
                        <option value="3">Under Booking</option>
                        <option value="4">Already Booked</option>
                      </select>
                      {error.shipStatus ? <div class="text-danger error-contract">{error.shipStatus}</div> : ''}
                    </div>
                  </div>
                </div>
              </div>

              <p className="font-weight-bold mt-2">Specification {astrix} </p>
              <hr />
              <div className="col-md-12 our-webcoderskull commodity-edit-modal">
                <div id="SelectName" className="mb-4">
                </div>
                <ul className="row" id="">
                  {dbSpecData && dbSpecData.length ? dbSpecData.map((value, index) => {
                    return (
                      <>
                        <li className={`col-md-2 ${(commArray.indexOf(value.id + "") >= 0) ? 'active' : ''} `} true onclick="getText(0)" id="myBtn0">
                          <div className="cnt-block equal-hight">
                            <figure onClick={() => handleCommodity(value.id + "")}>
                              <img src={commImageUrl(value.filename)} className="img-responsive" alt="" />
                            </figure>
                            <h3>
                              {value.commodity_pretty_name}
                            </h3>
                          </div>
                        </li>
                      </>
                    )
                  }) : ''}
                </ul>
                {error.commodity ? <div class="text-danger error-contract">{error.commodity}</div> : ''}
              </div>
              <div className="row">
                {(data.shipType) && <>
                  <div className="col-md-6">
                    <div className="row form-group">
                      <label className="col-md-12">Vessel Category {astrix} </label>
                      <div className="col-md-12">
                        <select className={" form-control" + (!error.shipCategory ? '' : ' border-danger')} name="shipCategory" value={data.shipCategory} onChange={handleChange}>
                          <option value="" selected>--Select Category--</option>
                          {getShipscategorybody()}
                        </select>
                        {error.shipCategory ? <div class="text-danger error-contract">{error.shipCategory}</div> : ''}
                      </div>
                    </div>
                  </div>
                </>}
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Length {astrix} </label>
                    <div className="col-md-12">
                      <input type="number" name="lengthv" value={data.lengthv} placeholder="Enter Vessel Length" className={" form-control" + (!error.lengthv ? '' : ' border-danger')} onChange={handleChange} />
                      {error.lengthv ? <div class="text-danger error-contract">{error.lengthv}</div> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Breadth {astrix} </label>
                    <div className="col-md-12">
                      <input type="number" name="breadthv" value={data.breadthv} placeholder="Enter Vessel Breadth" className={" form-control" + (!error.breadthv ? '' : ' border-danger')} onChange={handleChange} />
                      {error.breadthv ? <div class="text-danger error-contract">{error.breadthv}</div> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Depth {astrix}  </label>
                    <div className="col-md-12">
                      <input type="number" name="depth" value={data.depth} placeholder="Enter Vessel Depth" className={" form-control" + (!error.depth ? '' : ' border-danger')} onChange={handleChange} />
                      {error.depth ? <div class="text-danger error-contract">{error.depth}</div> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Draft {astrix}  </label>
                    <div className="col-md-12">
                      <input type="number" name="draft" value={data.draft} placeholder="Enter Vessel Draft" className={" form-control" + (!error.draft ? '' : ' border-danger')} onChange={handleChange} />
                      {error.draft ? <div class="text-danger error-contract">{error.draft}</div> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Deadweight {astrix} </label>
                    <div className="col-md-12">
                      <input type="number" name="deadWeight" value={data.deadWeight} placeholder="Enter Gross Ton" className={" form-control" + (!error.deadWeight ? '' : ' border-danger')} onChange={handleChange} />
                      {error.deadWeight ? <div class="text-danger error-contract">{error.deadWeight}</div> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Gross Tons {astrix} </label>
                    <div className="col-md-12">
                      <input type="number" name="grossTons" value={data.grossTons} placeholder="Enter Gross Ton" className={" form-control" + (!error.grossTons ? '' : ' border-danger')} onChange={handleChange} />
                      {error.number ? <div class="text-danger error-contract">{error.number}</div> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Net Tons {astrix} </label>
                    <div className="col-md-12">
                      <input type="number" name="netTons" value={data.netTons} placeholder="Enter Net Ton" className={" form-control" + (!error.netTons ? '' : ' border-danger')} onChange={handleChange} />
                      {error.number ? <div class="text-danger error-contract">{error.number}</div> : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer primary">
              <button type="button" className="btn btn-danger btn-sm" onClick={() => { setAddShip(false, {}); }}>Cancel</button>
              <button type="button" className="btn btn-info btn-sm" onClick={() => prehandleSubmit()}>Submit</button>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    showAddShip: state.showAddShip
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAddShip: (flag, data) => { dispatch(setAddShip({ modal: flag, info: data })) }
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddShip)
