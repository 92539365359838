import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { setshowModalAddComm } from '../../store/actions/action';
import toastDisplay from '../../utils/toastNotification';
import SpecificationsForm from '../specifications/specificationForm'
import commImageUrl from '../../utils/makeCommImageUrl'
import { is_valid } from '../../utils/myFunctions';


const UserCommodityAddModal = ({ userTokenDetails, showModalAddComm, setshowModalAddComm, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [dbData, setdbData] = useState([]);
  const [selectedComm, setselectedComm] = useState('');
  const [commQuantity, setcommQuantity] = useState(0);
  const [refresh, setrefresh] = useState(0);
  // const [errorCommodities, seterrorCommodities] = useState(false);
  const [spec, setSpecification] = useState({});
  const [specOrder, setspecOrder] = useState([])
  const [commodityCategories, setcommodityCategories] = useState([])
  let [formError, setformError] = useState({});
  const [data, setdata] = useState({});
  const [userDbData, setuserDbData] = useState([])

  // const [extraSpec, setextraSpec] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [quotaAvail, setQuotaAvail] = useState(false)


  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {
    call('GET', 'getcommoditycategory').then((result) => {
      //console.log("API result getcommoditycategory in comm modal:-->", result);
      setshowLoader(false)
      if (result.length) {
        setcommodityCategories(result);
        if (showModalAddComm.info.status) {
          setdata({
            "commdCategorys": result.filter(item => { if (item.category == showModalAddComm.info.category) return item })[0]['id'],
            "commName": showModalAddComm.info.commodity_pretty_name
          })
          setdbData([{
            "category": showModalAddComm.info.category,
            "commodity": showModalAddComm.info.commodity,
            "commodity_name": showModalAddComm.info.commodity_name,
            "commodity_pretty_name": showModalAddComm.info.commodity_pretty_name,
            "filename": showModalAddComm.info.filename,
            "id": showModalAddComm.info.id
          }])
          setcommQuantity(showModalAddComm.info.total_qty ? showModalAddComm.info.total_qty : 0)
          setSpecification(showModalAddComm.info.specifications)
          setspecOrder((showModalAddComm.info && showModalAddComm.info.spec_order_string) ? showModalAddComm.info.spec_order_string.split(":") : [])
          setselectedComm(showModalAddComm.info.id)
        }
      }
    }).catch((e) => {
      console.log("Error while querying getcommoditycategory:", e);
      setshowLoader(false)
    })

    if (!showModalAddComm.info.status) {
      call('POST', 'getusercommoditylist', { "userId": userId, 'userTypeId': userTypeId }).then((result) => {
        // console.log("getusercommoditylist->", result)
        if (result.length) {
          setuserDbData(result)
        }
      }).catch((e) => {
        console.log("Error while querying users:", e);
      })
    }

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)

    if (!showModalAddComm.info.status) {
      call('POST', 'getCommodityList', { specifications: true, commadd: true, userId: userId }).then((result) => {
        console.log("API result getCommodityList in comm modal:-->", result);
        setdbData(result);
        setshowLoader(false)
      }).catch((e) => {
        setshowLoader(false)
        console.log("Error while querying getCommodityList:", e);
      })
    }
    // else {
    //   setdbData([{
    //     "category": showModalAddComm.info.category,
    //     "commodity": showModalAddComm.info.commodity,
    //     "commodity_name": showModalAddComm.info.commodity_name,
    //     "commodity_pretty_name": showModalAddComm.info.commodity_pretty_name,
    //     "filename": showModalAddComm.info.filename,
    //     "id": showModalAddComm.info.id
    //   }])
    //   setcommQuantity(showModalAddComm.info.total_qty)
    //   setSpecification(showModalAddComm.info.specifications)
    //   setspecOrder((showModalAddComm.info && showModalAddComm.info.spec_order_string) ? showModalAddComm.info.spec_order_string.split(":") : [])
    //   setselectedComm(showModalAddComm.info.id)
    //   setshowLoader(false)
    // }
    //------------------------------------------------------------------
    if (showModalAddComm.info.type === "editCommodityUser") {
      setQuotaAvail(true)
    } else {
      call('get', 'v1/plan/quota').then((result) => {
        console.log("result while  getUserCurrentPlanQuota:-->", result);
        if (result && result.commodities && result.commodities.enable && ((result.commodities.limitLeft / 1) > 0 || (result.commodities.limitLeft / 1) === -1))
          setQuotaAvail(true)
      }).catch((e) => {
        console.log("Error while  getUserCurrentPlanQuota:-->", e);
      })
    }


    //------------------------------------------------------------------

  }, [refresh]);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handlequantity = (event) => {
    event.persist();
    setcommQuantity(event.target.value);
  }
  const handleSpecification = (event) => {
    event.persist();
    let tempArray = event.target.name.split(",")
    let specdataElement = { ...spec }
    specdataElement[tempArray[0]][tempArray[1]] = event.target.value
    setSpecification(specdataElement);
  }

  //---------------------------------------------------------------------------------------------------------------------

  console.log("specOrder -->", specOrder)
  // console.log("showModalAddComm -->", showModalAddComm)

  // console.log("quotaAvail-->", quotaAvail)
  //console.log("commQuantity-->", commQuantity)
  //console.log("spec in user modal-->", spec)

  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  async function handleSubmit() {
    setshowLoader(true)
    if (selectedComm === "temp") {
      let formData = new FormData();
      formData.append('commdCategory', data["commdCategorys"]);
      formData.append('commodityPrettyName', data["commName"]);
      formData.append('commodityName', data["commName"].toLowerCase().replace(/\s/g, ''));
      formData.append('spec', JSON.stringify({}));
      formData.append('specOrder', "");
      let result = await call('POST', 'savecommoditymaster', formData)
      if (result && result.message && result.message.id) {
        setshowLoader(false)
        setselectedComm(result.message.id)
        add_update_user_commodity(result.message.id)
      }
      else {
        setshowLoader(false)
        toastDisplay("Failed to add commodity master", "error")
      }
    } else {
      add_update_user_commodity()
      // let formData = new FormData();
      // formData.append('owner_id', userId)
      // formData.append('selectedComm', selectedComm);
      // formData.append('commQuantity', commQuantity);
      // formData.append('spec', JSON.stringify(spec));
      // formData.append('specOrder', specOrder.join(":"));
      // console.log("formdata to submit=>", formData);

      // call('POST', (!showModalAddComm.info.status) ? 'setCommodityuser' : 'updateCommodityuser', formData).then((result) => {
      //   console.log("set comm user==>", result);
      //   if (result) {
      //     setshowLoader(false)
      //     setshowModalAddComm(false, { refresh: true })
      //     setrefresh(refresh + 1)
      //     setselectedComm("")
      //     setSpecification({})
      //     // seterrorCommodities(false)
      //     toastDisplay(!showModalAddComm.info.status ? "New Commodity added" : "Commodity Updated", "success")
      //   }
      // }).catch(err => {
      //   setshowLoader(false)
      //   console.log("Error:", err)
      //   toastDisplay(err, "error");
      // })
    }

  }

  function add_update_user_commodity(commodity_id) {
    let formData = new FormData();
    formData.append('owner_id', userId)
    formData.append('selectedComm', commodity_id ? commodity_id : selectedComm);
    formData.append('commQuantity', commQuantity);
    formData.append('spec', JSON.stringify(spec));
    formData.append('specOrder', specOrder.join(":"));
    // console.log("formdata to submit=>", formData);

    call('POST', (!showModalAddComm.info.status) ? 'setCommodityuser' : 'updateCommodityuser', formData).then((result) => {
      // console.log("set comm user==>", result);
      if (result) {
        setshowLoader(false)
        setshowModalAddComm(false, { refresh: true })
        setrefresh(refresh + 1)
        setselectedComm("")
        setSpecification({})
        // seterrorCommodities(false)
        toastDisplay(!showModalAddComm.info.status ? "New Commodity added" : "Commodity Updated", "success")
      }
    }).catch(err => {
      setshowLoader(false)
      console.log("Error:", err)
      toastDisplay(err, "error");
    })
  }

  useEffect(() => {
    if (showModalAddComm.info.status) {
      return
    }
    setCommodity()
  }, [data])

  const setCommodity = () => {
    setselectedComm('');
    setSpecification({});
    setspecOrder([]);
    setformError({})

    if (is_valid(data["commdCategorys"]) && is_valid(data["commName"])) {
      let alredySelected = userDbData.filter((item) => {
        if (item.commodity_name == data["commName"].toLowerCase().replace(/\s/g, '') && item.category_id == data["commdCategorys"]) return item
      })
      if (alredySelected.length) {
        setformError({ "commName": "Commodity with same name already added" })
        return
      }
      let commDetails = dbData.filter((item) => {
        if (item.commodity_name == data["commName"].toLowerCase().replace(/\s/g, '') && item.category_id == data["commdCategorys"]) return item
      })
      if (commDetails.length) {
        setselectedComm(commDetails[0].id);
        setSpecification(commDetails[0].specifications ? commDetails[0].specifications : {});
        setspecOrder(commDetails[0].spec_order_string ? commDetails[0].spec_order_string.split(":") : []);
      }
      else {
        setselectedComm('temp');
        setSpecification({});
        setspecOrder([]);
      }
    }
  }

  const handleChange = (event) => {
    event.persist();
    // alert("eventcame")
    setdata(data => ({ ...data, [event.target.name]: (event.target.value).replace(/^\s?/, '') }));
  }
  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {(quotaAvail && showModalAddComm.modal) &&
        <div className={(showModalAddComm.modal ? " show" : "")} id="EditCommodity">
          <div className="modal-dialog d-flex modal-xl border-inner mw-100" id="parent_class">
            {/* <div className="modal-content"> */}
            <div style={{ "pointer-events": "auto" }} className='w-100'>
              {/* <div className="modal-header primary">
                <h4 className="modal-title text-white">{showModalAddComm.info.type === "editCommodityUser" ? " Edit Commodity" : "Add New Commodity"}</h4>
                <button type="button" className="close" onClick={() => { setshowModalAddComm(false, {}); }}>×</button>
              </div> */}
              {/* <div className="modal-body"> */}
              <div>
                {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                <h3 className="green pb-2">Choose Commodity</h3>
                <hr className="pb-1 pt-1" />
                <div className='row pb-2'>
                  <div className='col-6'>
                    <label >Commodity Type/Category {astrix} </label>
                    <div >
                      <select
                        disabled={showModalAddComm.info.status}
                        className={" form-control" + (!formError["commdCategorys"] ? '' : ' border-danger')} name={"commdCategorys"}
                        value={data["commdCategorys"]} onChange={handleChange}>
                        <option value="" selected>--Select Category--</option>
                        {
                          commodityCategories.length ? commodityCategories.map((item, index) => {
                            return (<option value={item.id}>{item.category}</option>)
                          }) : ''
                        }
                      </select>
                      {formError["commdCategorys"] ? <div class="text-danger error-contract">{formError["commdCategorys"]}</div> : ''}
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="row form-group">
                      <label className="col-md-12">Commodity name {astrix} </label>
                      <div className="col-md-12">
                        <input disabled={showModalAddComm.info.status} type="text" name="commName" value={data.commName} placeholder="Commodity name" className={" form-control" + (!formError.commName ? '' : ' border-danger')} onChange={handleChange} />
                        {formError.commName ? <div class="text-danger error-contract">{formError.commName}</div> : ''}
                      </div>
                    </div>
                  </div>
                  {/* <button type="button" style={{ "margin-left": '45%' }} className="btn btn-primary btn-sm mb-1" onClick={() => validateFields()}>SUBMIT</button> */}
                </div>

                {selectedComm && <>
                  <h3 className="green pb-2">Commodity Specification</h3>
                  <hr className="pb-1 pt-1" />
                  <h3 className="mt-4 mb-4">Total Quantity</h3>
                  <div className="col-md-6 pl-0">
                    <div className="row form-group">
                      {/* <label className="col-md-12">Total Quantity</label> */}
                      <div className="col-md-12"><input type='number' name="totalQuantity" value={commQuantity} placeholder="Enter Total Quantity" className="form-control" onChange={handlequantity} /></div>
                    </div>
                  </div>
                  <SpecificationsForm
                    commodity={selectedComm}
                    spec={spec}
                    handleSpecification={handleSpecification}
                    setSpecification={setSpecification}
                    specOrder={specOrder}
                    setspecOrder={setspecOrder}
                    editLevel={true}
                    masterid={(!showModalAddComm.info.status) ? true : false}
                    commName={data.commName ? data.commName : '-'}
                  />
                </>}
              </div>
              <div className="modal-footer primary">
                {(dbData && dbData.length > 0) ? <>
                  <button type="button" className="btn btn-danger btn-sm" onClick={() => { setshowModalAddComm(false, {}); }}>Cancel</button>
                  <button
                    disabled={!(is_valid(specOrder))}
                    type="button" className="btn btn-info btn-sm" onClick={() => handleSubmit()}>Save Commodity</button>
                </> : <>
                  <button type="button" className="btn btn-danger btn-sm" onClick={() => { setshowModalAddComm(false, {}); }}>Close</button>
                </>}
              </div>
            </div>
          </div>
        </div>}
    </>
  )
};

const mapStateToProps = state => {

  return {
    showModalAddComm: state.showModalAddComm
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setshowModalAddComm: (flag, data) => { dispatch(setshowModalAddComm({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCommodityAddModal)
