// const env = "local"
const env = "dev"
// const env = "prod"

function getTradeFinBackendUrl() {
  if (env === "local") {
    return "http://localhost:3004"
  }
  if (env === "dev") {
    return "https://webfindev-backend.sofodev.co"
  }
  if (env === "prod") {
    return "https://backend.traderebootfin.com"
  }
}

function getPlatFormUrl() {
  if (env === "local") {
    return "http://localhost:3000"
  }
  if (env === "dev") {
    return "https://tech.tradereboot.com"
  }
  if (env === "prod") {
    return "https://tech.tradereboot.com"
  }
}

function getWebsiteBackendURL() {
  if (env === "local") {
    return "http://localhost:3212"
  }
  if (env === "dev") {
    return "https://backendwebdev.sofodev.co"
  }
  if (env === "prod") {
    return "https://backend.tradereboot.com"
  }
}

function getAIServerBackendURL() {
  if (env === "local") {
    return "https://serverai.tradereboot.com"
  }
  if (env === "dev") {
    return "https://serverai.tradereboot.com"
  }
  if (env === "prod") {
    return "https://serverai.tradereboot.com"
  }
}

export const environment = env
export const platformURL = getPlatFormUrl()
export const websiteBackendURL = getWebsiteBackendURL()
export const tradeFinBackendUrl = getTradeFinBackendUrl()
export const googleApiKey = ""
export const aiServerBackendUrl = getAIServerBackendURL()