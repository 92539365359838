import React, { useEffect, useState } from 'react';
import call from "../../../service";
import { connect } from "react-redux";
import { setFinanceDetails } from '../../../store/actions/action';
import toastDisplay from "../../../utils/toastNotification";
import toolTipText from '../../../utils/toolTips/financeForm.json'
import { formatDate_Application, dateFormatter_DatePicker } from '../../../utils/dateFormaters';
import FilePreview from '../../utilComponents/previewFile';
import DigitalSignDoc from '../../contractDocument/digitalSignDoc2';
import OtherDocs from "./otherDocs";
import validate from '../../../utils/validation_files/FreightFinValidationRules'
import config from '../../../config.json';
import { ClearCache, convertImageToPdf, GetCache, SetCache } from '../../../utils/myFunctions';


const FreightFinance = ({ userTokenDetails, setFinanceDetails, financeDetails, dispatch }) => {
  let cache_data = financeDetails.info.action === "applyFinance" ? GetCache("freight_form_data") : {}


  //---------------------------------------------------------------------------------------------------------------------
  // Const State and their handlers declaration and initiation.
  const [bankMaster, setbankMasters] = useState([]);
  const [buyerArr, setBuyerArr] = useState([]);
  const [buyerType, setBuyerType] = useState(1);
  const [logiArr, setLogiArr] = useState([]);
  const [data, setData] = useState(cache_data['data'] || {});
  const [refresh, setrefresh] = useState(0);
  const [file, setFile] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [amount, setAmount] = useState(0);
  const [fileData, setfileData] = useState({});
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [showAclAuthModal, setAclAuthModal] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [userBank, setUserBank] = useState([]);
  const [counterPart, setCounterPart] = useState([]);
  const [accord, setAccord] = useState({
    0: false
  });
  const [tab, setTab] = useState(0)

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------

  //--------------------------------------------------------------------------------------------------------------------
  //UseEffect

  useEffect(() => {
    setAclAuthModal(false)
    if (financeDetails.info.tab) {
      setTab(financeDetails.info.tab)
    }
    if (financeDetails.info.action === "viewFinance") {
      setshowLoader(true)
      call('POST', 'getDiscounting', { id: financeDetails.info.FinanceID, type: financeDetails.type }).then((result) => {
        console.log('Freight Get Discounting API  =====>', result);

        let bankName = result.bankList.split(':');

        let poDbData = {
          id: result.id,
          userType: result.user_type,
          currency: result.currency,
          currencyName: result.amountCurrencyName,
          contractAmount: result.contract_amount,
          invoiceRefrenceNo: result.reference_no,
          poRefrenceNo: result.po_no,
          invoiceIssueDate: result.issue_date,
          invoiceDueDate: result.due_date,
          lenderId: result.lender_id,
          lenderName: result.lenderName,
          buyerId: result.buyer_id,
          buyerName: result.buyerName,
          reqAmount: result.amount,
          interestRate: result.interest_rate,
          bankListId: bankName[0],
          bankListName: bankName[1],
          creditDays: result.credit_days,
          fintnc: result.fin_tnc,
          doc: result.docData.docList,
        }

        setAccord({
          0: false
        });

        setAmount(result.amount)
        setData({ ...poDbData, userId: userId })
        setshowLoader(false)
      }).catch((e) => {
        setshowLoader(false)
        console.log('error in getDiscounting', e);
      })

    } else {
      setData({ ...data, userId: userId, applicantEmail: userEmail });
    }

    if (financeDetails.info.action === "applyFinance") {

      // Get bank master list that are involved in trade finance.
      call("GET", "getBanksListMaster")
        .then((result) => {
          setbankMasters(result);
        })
        .catch((e) => {
          console.log("Error while querying getBanksListMaster:", e);
        });

      call('POST', 'getUsersListing', { "searchUserType": 5, "searchKey": '', "network": "innetwork", "userAvatars": false })
        .then((result) => {
          // console.log("result====>", result)
          setLogiArr([...result]);
        })
        .catch((e) => {
          console.log("error in getcurrencylist", e);
        });

      call('POST', 'getUsersListing', { "searchUserType": 10, "searchKey": '', "network": "innetwork", "userAvatars": false })
        .then((result) => {
          // console.log("result====>", result)
          setLogiArr([...result]);
        })
        .catch((e) => {
          console.log("error in getcurrencylist", e);
        });
    }

    let buyerObjectAPI = {
      "userId": userId,
      "userTypeId": userTypeId
    }

    buyerObjectAPI = { ...buyerObjectAPI }

    call('POST', 'getBuyersDetail', buyerObjectAPI).then((result) => {
      console.log('running getBuyersDetail api -->', result.buyerData);
      setCounterPart(result.buyerData);
    }).catch((e) => {
      console.log('error in getBuyersDetail', e);
    });

    call('POST', 'getUserBanks', { email: userEmail, inProfile: true }).then((result) => {
      console.log('running getUserBanks api -->', result);
      setUserBank(result);
    }).catch((e) => {
      console.log('error in getUserBanks', e);
    });

  }, [refresh])

  useEffect(() => {
    if (financeDetails.info.action === "applyFinance") {
      SetCache('freight_form_data', { data })
    }
  }, [data])

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      if (tab === 0) {
        setTab(1)
      }
      else {
        setTab(0)
        forward()
      }
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  //---------------------------------------------------------------------------------------------------------------------
  //Handlers

  const handleChange = (event) => {
    event.persist();
    if (event.target.name === 'bankListId') {
      let val = event.target.value.split(':');
      setData({ ...data, [event.target.name]: +val[0], bankListName: val[1] });
      setError({ ...error, [event.target.name]: "" });
    }
    else if (event.target.name === "currency") {
      let val = event.target.value.split(':');
      setData({ ...data, [event.target.name]: val[0], currencyName: val[1] });
      setError({ ...error, [event.target.name]: "" });
    }
    else if (event.target.name === "buyerId") {
      let val = event.target.value.split(':');
      setData({ ...data, [event.target.name]: (val[0] === "ME" ? val[0] : +val[0]), buyername: val[1], buyerEmail: val[2] });
      setError({ ...error, [event.target.name]: "" });
    }
    else if (event.target.name === "lenderId") {
      let val = event.target.value.split(':');
      setData({ ...data, [event.target.name]: +val[0], lenderName: val[1], lenderEmail: val[2] });
      setError({ ...error, [event.target.name]: "" });
    }
    else if (event.target.name === "dnc") {
      if (data.dnc === "on") {
        setData({ ...data, "dnc": "" });
      } else {
        setData({ ...data, [event.target.name]: event.target.value });
      }
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value });
      setError({ ...error, [event.target.name]: "" });
    }
  };

  const handleFiles = (event) => {
    event.persist();
    let file_type = event.target.files[0]["type"].toLowerCase()
    if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
      toastDisplay('Files with pdf, png & jpeg extension are allowed', 'info')
      return
    }
    // setfileDetail({
    //   "file_name": event.target.name,
    //   "buyerId": data.buyerId,
    //   "buyerEmail": data.buyerEmail,
    //   "buyerName": data.buyerName,
    //   "lenderId": data.lenderId,
    //   "lenderEmail": data.lenderEmail,
    //   "lenderName": data.lenderName,
    //   "file_data": { [event.target.name]: event.target.files[0] }
    // });
    // setaddPreviewModal(true);
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = async (e) => {
      let fileObj = event.target.files[0]
      let fileDataUrl = e.target.result
      if (!file_type.includes("pdf")) {
        let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
        fileObj = response["file"]
        fileDataUrl = response['dataUrl']
        toastDisplay("File converted into pdf format", "success")
        // console.log("response==>", response);
      }
      setFile({ ...file, [event.target.name]: fileObj });
      setData({ ...data, [event.target.name]: fileObj })
      setError({ ...error, [event.target.name]: "" });
      // setaddFileData(e.target.result);
    }
  };

  const prehandleForward = (event) => {
    if (event) event.preventDefault();
    // data['selectedBuyerLimit'] = (typeof (buyerCredit.data) != 'string') ? JSON.stringify(buyerCredit.data) : buyerCredit.data
    setError(validate(data, file, financeDetails, userTypeId, tab));
    setIsSubmitting(true);
  };

  //---------------------------------------------------------------------------------------------------------------------
  //Function

  function toolTip(inputName) {
    return (
      <span className="help-icon" flow="right" tooltip={toolTipText[inputName]}>i</span>
    )
  }

  function forward() {
    setFinanceDetails(financeDetails.status, financeDetails.type, (financeDetails.page / 1) + 1, financeDetails.info)
  }

  function docPreviewGenerator(doc) {
    let docElements = doc.length ? doc.map((values, index) => {
      // console.log("value in doc render =>", values)

      values.tbl_doc_id = values.id
      values.document_name = values.doc_name
      values.uploaded_by = values.createdBy
      values.uploaded_on = values.created_at
      values.signType = 'withoutBlockChain'

      return (<tr>
        <td>{index + 1}</td>
        <td>{(values && values.document_name) ? values.document_name : values.name ? values.name : 'NA'}</td>
        <td>{(values && values.doc_no) ? values.doc_no : 'NA'}</td>
        <td>{(values && values.createdBy) ? values.createdBy : 'NA'}</td>
        <td>{(values && values.created_at) ? formatDate_Application(values.created_at) : 'NA'}</td>
        <td>
          {(values && values.sign) ? <pre><span className="text-success mr-1">{values.sign.map((signV) => { return (<><i className="fa fa-certificate" /> {signV}<br /></>) })}</span></pre> : ''}
        </td>
        <td className="row justify-content-center  mt-0 pt-0 m-0 ">

          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "view")}>
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
            () => callPreviewFileComponent(values, "download")}>
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>
          <button
            disabled={values.signByUserId && values.signByUserId.length && values.signByUserId.indexOf(userId) != -1}
            title="Authenticate File" className="text-center btn btn-dark btn-sm m-1" onClick={
              () => callAclAuthComponent(values)}>
            <i class="fas fa-file-signature"></i>
          </button>

        </td>
      </tr>)
    }) : ''
    return (docElements)
  }

  function acceptInvoiceDiscounting(party) {
    // call("POST", "acceptInvoiceDiscounting", { party: userTypeId, id: data.id, manualUserEmail: userEmail })
    //   .then((result) => {
    //     toastDisplay("Request Accepted successfuly", "success");
    //     setFinanceDetails(false, 0, 0, {})
    //   })
    //   .catch((e) => {
    //     toastDisplay(e, "error");
    //     console.log("error in acceptPO", e);
    //   });
  }

  function updateDiscounting() {

    setshowLoader(true)
    delete data.doc
    // console.log('data', data);
    // console.log('file', file);

    data.otherDocMetaData = data.otherDocMetaData ? JSON.stringify(data.otherDocMetaData) : null

    let formData = new FormData();

    formData.append("id", data.id);
    formData.append("invoiceRefrenceNo", data.invoiceRefrenceNo);
    formData.append("reqAmount", data.reqAmount);
    formData.append("creditDays", data.creditDays);
    formData.append("interestRate", data.interestRate);
    formData.append("fintnc", data.fintnc);
    formData.append("otherDocMetaData", data.otherDocMetaData);
    formData.append("sellerId", data.sellerId);
    formData.append("lenderId", data.lenderId);
    formData.append("buyerId", data.buyerId);

    // Object.keys(file).forEach((key) => {
    //   formData.append(key, file[key]);
    // })

    // call("POST", "updateInvoiceDiscounting", formData)
    //   .then((result) => {
    //     setshowLoader(false)
    //     toastDisplay("Discounting Updated successfuly", "success");
    //     setFinanceDetails(false, 0, 0, {})
    //   })
    //   .catch((e) => {
    //     setshowLoader(false)
    //     toastDisplay(e, "error");
    //     console.log("error in updateInvoiceDiscounting", e);
    //   });
  }

  const submitFreightFinanceForm = (event) => {

    setshowLoader(true)
    const currentRoute = "applyFreightDiscounting"// = "applyLCFinance"
    data.sellerId = userId

    data.otherDocMetaData = data.otherDocMetaData ? JSON.stringify(data.otherDocMetaData) : null
    data.baseUrl = config.baseUrl;
    data.applicantName = userName;

    let formData = new FormData();

    let tempOffline = { buyer_type: buyerType }
    if (data.buyerId === "ME") {
      tempOffline = { ...tempOffline, buyer_name: data.buyerNameManual, buyer_addr: data.buyerAddrManual }
    }
    if (data.logisticName == "Others") {
      tempOffline = { ...tempOffline, logistic_name: data.logisticNameManual }
    }
    formData.append("offline_data", JSON.stringify(tempOffline));

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })


    formData.append('userEmail', userEmail);

    call("post", currentRoute, formData)
      .then((result) => {
        setshowLoader(false)
        toastDisplay("Loan Applied successfuly", "success");
        ClearCache("freight_form_data")
        setFinanceDetails(false, 0, 0, {})
      })
      .catch((e) => {
        setshowLoader(false)
        toastDisplay(e, "error");
        console.log("error in create finance modal", e);
      });
  };

  function backward() {
    if (financeDetails.page === 1) {
      if (financeDetails.info.action === "viewFinance") {
        setFinanceDetails(false, 0, 0, {})
      } else {
        setFinanceDetails(financeDetails.status, 0, 0, financeDetails.info)
      }
    } else if (financeDetails.page === 2 && financeDetails.info.sellerStatus === 2 && financeDetails.info.lenderStatus === 2) {
      setFinanceDetails(false, 0, 0, {})
    } else {
      setFinanceDetails(financeDetails.status, financeDetails.type, (financeDetails.page / 1) - 1, financeDetails.info)
      setIsSubmitting(false)

    }
  }

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values.id, "action": action })
    setshowPreviewModal(true)
  }

  function callAclAuthComponent(values) {
    console.log("in acl call ", values);
    setfileData({ ...values })
    setAclAuthModal(true);
  }

  const NavigationButtons = () => {
    return (
      <div className="col-md-12">
        {(financeDetails.type === 6 && financeDetails.page < 2) ?
          <button type="button" className="btn btn-success float-right btn-sm" onClick={() => prehandleForward()}>
            Next<i className="fa fa-chevron-right pl-2" aria-hidden="true"></i>
          </button> :


          data.sellerStatus === 2 && data.lenderStatus === 2 ?
            <button type="button" className="btn btn-success border border-success float-right btn-sm" disabled>
              <b> Approved </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
            </button> :

            ((data.sellerStatus === 2 && data.sellerId === userId && data.lenderStatus === 1) || (data.sellerStatus === 1 && data.lenderId === userId && data.lenderStatus === 2)) ?
              <button type="button" className="btn btn-warning btn-sm border border-warning float-right mr-2" disabled>
                <b> Approved By You</b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
              </button> :

              ((data.sellerStatus === 1 && data.sellerId === userId && data.status === 3) || (data.lenderStatus === 1 && data.lenderId === userId && data.status === 3 && data.sellerStatus === 2 && data.noa_signedBy_buyer == 1 && data.noa_signedBy_supplier == 1)) ?
                <>
                  {((amount / 1 === data.reqAmount / 1) && data.exhibitStatus == 1 && data.noaSignedByBuyer == 1 && data.noaSignedBySupplier == 1) ?
                    <button type="button" className="btn btn-warning btn-sm border border-warning float-right mr-2" onClick={() => acceptInvoiceDiscounting()}>
                      <b> Approve </b><i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    </button> :
                    <button type="button" className="btn btn-success btn-sm border border-success float-right  mr-2" onClick={updateDiscounting}>
                      <b> Update </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    </button>
                  }
                  {(data.sellerStatus === 1 && data.lenderStatus === 1) &&
                    <>
                      {userTypeId == 8 ?
                        <button type="button" className="btn btn-success btn-sm border border-success float-right  mr-2" onClick={updateDiscounting}>
                          <b> Update </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                        </button>
                        :
                        <button type="button" className="btn btn-warning btn-sm border border-warning float-right mr-2" onClick={acceptInvoiceDiscounting}>
                          <b> Approve </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                        </button>}

                    </>
                  }
                </> :

                ((data.sellerStatus === 1 && data.lenderStatus === 1) && financeDetails.info.action === "viewFinance" && data.sellerId === userId) ?
                  <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={updateDiscounting}>
                    <b> Update </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                  </button> :

                  ((data.sellerStatus === 1 && data.lenderStatus === 1) && financeDetails.info.action === "viewFinance" && data.lenderId === userId) ?
                    <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={updateDiscounting}>
                      <b> Send Offer </b> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    </button> :

                    <button type="button" className="btn btn-success btn-sm border border-success float-right" onClick={submitFreightFinanceForm} disabled={data.dnc === "on" ? false : true} > <b> Apply </b>
                      <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                    </button>}

        {(financeDetails.type === 6 && financeDetails.page > 0) &&
          <button type="button" className="btn btn-danger float-left btn-sm" onClick={() => { tab === 1 ? setTab(0) : backward() }}>
            <i className="fa fa-chevron-left pr-2" aria-hidden="true"></i>Back
          </button>}
      </div>
    )
  }


  // JSX return
  return (
    <>
      {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {(financeDetails.status && financeDetails.type === 6 && financeDetails.page === 1) ?
        <>
          <div className="col-md-12 shadow bg-light pt-2 pb-5">
            <ul className="nav nav-tabs-custom" id="myTab" role="tablist">
              <li>
                <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {
                  setTab(0);
                }}>Application Details</a>
              </li>
              {data.userType ? (
                <li>
                  <a className={"nav-link pl-4 pr-4 cursor-pointer " + (tab === 1 ? " active show" : "")} onClick={() => {
                    setTab(1);
                  }}>Documents</a>
                </li>
              ) : null}
            </ul>

            {tab === 0 ? (
              <div className="row">

                <div className="col-md-6">
                  <div className="row form-group">
                    <label className="col-md-12">Apply as  {astrix} {toolTip('userType')} </label>
                    <div className="col-md-12">
                      <select className={"form-control" + (error.userType ? " border-danger" : "")} name="userType" value={data.userType} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"}>
                        <option value="" selected>--Select apply as--</option>
                        <option value="exporter" >Exporter</option>
                        <option value="shippingBroker" >Shipping Broker</option>
                        <option value="bunkers" >Bunkers</option>
                        <option value="charteredServices" >Chartered Services</option>
                      </select>
                      {error.userType ? <div class="text-danger error-contract">{error.userType}</div> : ''}
                    </div>
                  </div>
                </div>

                {data.userType &&
                  <>
                    <div className="col-md-6">
                      <div className="row form-group">
                        <label className="col-md-12">Applicant  {astrix} {toolTip('buyerId')}</label>
                        <div className="col-md-12">
                          <input type="text" className={"form-control" + (error.userEmail ? " border-danger" : "")} name="applicant" value={userEmail} readOnly />
                          {error.userEmail ? <div class="text-danger error-contract">{error.userEmail}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="row form-group">
                        <label className="col-md-12">Currency {astrix}  {toolTip('loanCurrency')}</label>
                        <div className="col-md-12 ">
                          {
                            financeDetails.info.action !== "viewFinance" ?
                              <select className={"form-control" + (error.currency ? " border-danger" : "")} name="currency" value={data.currency + ":" + data.currencyName} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"}>
                                <option value="" selected>--Select Currency--</option>
                                <option value="2:USD">Dollars ( USD )</option>
                                <option value="11:EUR">Euro ( EUR )</option>
                                <option value="19:GBP">Pounds ( GBP )</option>
                              </select> :
                              <input type="text" name="currency" value={data.currencyName} className={"form-control" + (error.currency ? " border-danger" : "")} disabled />}
                          {error.currency ? <div class="text-danger error-contract">{error.currency}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="row form-group">
                        <label className="col-md-12">Invoice Amount {astrix}  {toolTip('contractAmount')}</label>
                        <div className="col-md-12">
                          <input type="number" step="0.01" className={"form-control" + (error.contractAmount ? " border-danger" : "")} name="contractAmount" value={data.contractAmount} placeholder="Enter Contract Amount" onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                          {error.contractAmount ? <div class="text-danger error-contract">{error.contractAmount}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Invoice No  {astrix} {toolTip('invoiceRefrenceNo')}</label>
                        <div className="col-md-12">
                          <input type="text" className={"form-control" + (error.invoiceRefrenceNo ? " border-danger" : "")} name="invoiceRefrenceNo" value={data.invoiceRefrenceNo} placeholder="Enter Invoice Refrence No" onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                          {error.invoiceRefrenceNo ? <div class="text-danger error-contract">{error.invoiceRefrenceNo}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">PO No {astrix}  {toolTip('poRefrenceNo')}</label>
                        <div className="col-md-12">
                          <input type="text" className={"form-control" + (error.poRefrenceNo ? " border-danger" : "")} name="poRefrenceNo" value={data.poRefrenceNo} placeholder="Enter PO Refrence No" onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                          {error.poRefrenceNo ? <div class="text-danger error-contract">{error.poRefrenceNo}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Invoice Issue Date {astrix}  {toolTip('invoiceIssueDate')}</label>
                        <div className="col-md-12">
                          <input type="date" name="invoiceIssueDate" value={data.invoiceIssueDate} className={"form-control" + (error.invoiceIssueDate ? " border-danger" : "")} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                          {error.invoiceIssueDate ? <div class="text-danger error-contract">{error.invoiceIssueDate}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Invoice Due Date {astrix}  {toolTip('invoiceDueDate')}</label>
                        <div className="col-md-12">
                          <input type="date" name="invoiceDueDate" value={data.invoiceDueDate} className={"form-control" + (error.invoiceDueDate ? " border-danger" : "")} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"} />
                          {error.invoiceDueDate ? <div class="text-danger error-contract">{error.invoiceDueDate}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Select Lender  {astrix} {toolTip('lenderId')}</label>
                        <div className="col-md-12">
                          {financeDetails.info.action !== "viewFinance" ?
                            <select className={"form-control" + (error.lenderId ? " border-danger" : "")} name="lenderId" id="select_lenderId"
                              value={data.lenderId + ':' + data.lenderName + ':' + data.lenderEmail} onChange={handleChange} >
                              <option value="">--Select Lender--</option>
                              {bankMaster.map((values) => {
                                return (<option value={values.id + ':' + values.company_name + ':' + values.email_id} >{values.company_name}</option>)
                              })}
                            </select>
                            :
                            <input type="text" name="lenderId" value={data.lenderName} className={"form-control" + (error.lenderId ? " border-danger" : "")} onChange={handleChange} disabled />}
                          <br />
                          {error.lenderId ? <div class="text-danger error-contract">{error.lenderId}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Select Buyer {astrix}  {toolTip('buyerId')}</label>
                        <div className="col-md-12">
                          {financeDetails.info.action !== "viewFinance" ?
                            <select className={"form-control" + (error.buyerId ? " border-danger" : "")} name="buyerId"
                              value={data.buyerId + ':' + data.buyername + ':' + data.buyerEmail} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"}>
                              <option value="" selected>--Select Buyer--</option>
                              <optgroup label="My Buyers">
                                {counterPart.length > 0 ? counterPart.map((counter) => {
                                  return (
                                    <option value={counter.id + ':' + counter.buyerName + ':' + counter.buyerEmail} >{counter.buyerName}</option>
                                  )
                                })
                                  :
                                  <option disabled>Not Available</option>}
                              </optgroup>
                              <optgroup label="Public Buyer">
                                {buyerArr.length > 0 ? buyerArr.map((buyer) => {
                                  return (
                                    <option value={buyer.id + ':' + buyer.company_name + ':' + buyer.user_name} >{buyer.company_name}</option>
                                  )
                                })
                                  :
                                  <option disabled>Not Available</option>}
                              </optgroup>
                            </select>
                            :
                            <input type="text" name="buyerId" value={data.buyerName} className={"form-control" + (error.buyerId ? " border-danger" : "")} disabled />}
                          {error.buyerId ? <div class="text-danger error-contract">{error.buyerId}</div> : ''}
                          {/* {financeDetails.info.action === "viewFinance" ? '' : (data.lenderName && data.buyername) &&
                      (buyerCredit.modal && buyerCredit.data.limit != 'No values assigned to buyer') ?
                      <div><br /><p>Approved Finance Amount: {buyerCredit.data.limit}</p></div>
                      :
                      (buyerCredit.modal && buyerCredit.data.limit == 'No values assigned to buyer') ?
                        <div class="text-danger"><br /><p>  Approved Finance Amount: No values assigned to buyer</p></div>
                        : ''
                    } */}
                          {financeDetails.info.action === "viewFinance" ? '' : error.selectedBuyerLimit ? <div class="text-danger error-contract">{error.selectedBuyerLimit}</div> : ''}

                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="row form-group">
                        <label className="col-md-12">Currency {astrix}  {toolTip('loanCurrency')}</label>
                        <div className="col-md-12 ">
                          {
                            financeDetails.info.action !== "viewFinance" ?
                              <select className={"form-control" + (error.currency ? " border-danger" : "")} name="currency" value={data.currency + ":" + data.currencyName} onChange={handleChange} disabled={financeDetails.info.action === "viewFinance"}>
                                <option value="" selected>--Select Currency--</option>
                                <option value="2:USD">Dollars ( USD )</option>
                                <option value="11:EUR">Euro ( EUR )</option>
                                <option value="19:GBP">Pounds ( GBP )</option>
                              </select> :
                              <input type="text" name="currency" value={data.currencyName} className={"form-control" + (error.currency ? " border-danger" : "")} disabled />}
                          {error.currency ? <div class="text-danger error-contract">{error.currency}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="row form-group">
                        <label className="col-md-12">Requested Amount {astrix}  {toolTip('loanAmount')}</label>
                        <div className="col-md-12">
                          <input type="number" step="0.01" className={"form-control" + (error.reqAmount ? " border-danger" : "")} name="reqAmount" value={data.reqAmount} placeholder="Enter Finance Amount" onChange={handleChange} disabled={data.sellerStatus === 1 && data.lenderStatus === 1 && userTypeId != 8} />
                          {error.reqAmount ? <div class="text-danger error-contract">{error.reqAmount}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Interest Rate (<small>To be filled by Banker</small>){astrix}  {toolTip('interestRate')}</label>
                        <div className="col-md-12">
                          <input className={"form-control" + (error.interestRate ? " border-danger" : "")} type="number" name="interestRate" value={data.interestRate} placeholder="Enter Interest rate" onChange={handleChange} readOnly={userTypeId !== 8 || data.sellerStatus / 1 === 2} />
                          {error.interestRate ? <div class="text-danger error-contract"> {error.interestRate} </div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Select Bank {astrix} {toolTip('bankListId')}</label>
                        <div className="col-md-12">
                          {financeDetails.info.action !== "viewFinance" ?
                            <select className={"form-control" + (error.bankListId ? " border-danger" : "")} name="bankListId" value={data.bankListId + ":" + data.bankListName} onChange={handleChange}>
                              <option value="" selected>--Select Bank--</option>
                              {userBank.map((bank) => {
                                if (bank.beneficiaryBankName !== null) {
                                  return (
                                    <option value={bank.id + ':' + bank.beneficiaryBankName} >{bank.beneficiaryBankName}</option>
                                  )
                                }
                              })}
                            </select>
                            :
                            <input type="text" name="bankListId" value={data.bankListName} className={"form-control" + (error.bankListId ? " border-danger" : "")} onChange={handleChange} disabled />}
                          {error.bankListId ? <div class="text-danger error-contract">{error.bankListId}</div> : ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="row form-group">
                        <label className="col-md-12">Finance Credit Days {astrix}  {toolTip('loanCreditDays')}</label>
                        <div className="col-md-12">
                          <input className={"form-control" + (error.creditDays ? " border-danger" : "")} type="number" name="creditDays" value={data.creditDays} placeholder="Enter Finance Credit Days" onChange={handleChange} disabled={data.sellerStatus === 1 && data.lenderStatus === 1 && userTypeId != 8} />
                          {error.creditDays ? <div class="text-danger error-contract"> {error.creditDays} </div> : ''}
                        </div>
                      </div>
                    </div>

                    {/* {userTypeId == 8 && */}
                    <div className="col-md-12">
                      <div className="row form-group">
                        <label className="col-md-12">Finance TNC (<small>To be filled by Banker</small>) {astrix}  {toolTip('fintnc')}</label>
                        <div className="col-md-12">
                          <textarea rows="4" className={"form-control" + (error.fintnc ? " border-danger" : "")} name="fintnc" value={data.fintnc} onChange={handleChange} readOnly={userTypeId !== 8 || data.sellerStatus / 1 === 2}></textarea>
                          {error.fintnc ? <div class="text-danger error-contract"> {error.fintnc} </div> : ''}
                        </div>
                      </div>
                    </div>
                    {/* } */}
                  </>
                }
              </div>
            ) : null}

            {tab === 1 ? (
              <div className='row'>
                {(data.userType && financeDetails.info.action === "applyFinance") ? <>
                  <div className="col-md-12">
                    <h3 className="pt-2 pb-2"><u>Supporting Documents</u></h3>
                    <div className="col-md-12 pt-2 pb-3">
                      <ul className="other-documents">
                        <li>
                          <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                        </li>
                        <li>
                          <h3 className={"custum-control" + (error.invoiceDocument ? " border-danger" : "")} style={{ "min-width": "205px" }}>Invoice Document {astrix}  {toolTip('invoiceDocument')}</h3>
                        </li>
                        {/* <li>
                        <div className="document-name-top">Valid Upto </div>
                        <div className="document-name-bottom">(If applicable )</div>
                      </li>
                      <li>
                        <input type="text" className="custum-control year" name={"invoiceDocumentMM"} value={data.invoiceDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                        <input type="text" className="custum-control year" name={"invoiceDocumentYY"} value={data.invoiceDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                      </li> */}
                        <li>
                          <div className="file-browse">
                            <button className="btn btn-primary btn-sm">Upload Document</button>
                            <input type="file" accept=".png,.jpg,.pdf" name="invoiceDocument" onChange={handleFiles} />
                          </div>
                        </li>
                        <li> </li>
                      </ul>
                      {file.invoiceDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.invoiceDocument.name}`}</div></div> : ""}
                      {error.invoiceDocument ? <div class="text-danger error-contract">{error.invoiceDocument}</div> : ''}
                    </div>
                    <hr />
                    <div className="col-md-12 pt-2 pb-3">
                      <ul className="other-documents">
                        <li>
                          <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                        </li>
                        <li>
                          <h3 className="custum-control" style={{ "min-width": "205px" }}>PO Document {toolTip('poDocument')}</h3>
                        </li>
                        {/* <li>
                        <div className="document-name-top">Valid Upto </div>
                        <div className="document-name-bottom">(If applicable )</div>
                      </li>
                      <li>
                        <input type="text" className="custum-control year" name={"poDocumentMM"} value={data.poDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                        <input type="text" className="custum-control year" name={"poDocumentYY"} value={data.poDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                      </li> */}
                        <li>
                          <div className="file-browse">
                            <button className="btn btn-primary btn-sm">Upload Document</button>
                            <input type="file" accept=".png,.jpg,.pdf" name="poDocument" onChange={handleFiles} />
                          </div>
                        </li>
                        <li> </li>
                      </ul>
                      {file.poDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.poDocument.name}`}</div></div> : ""}
                    </div>
                    <hr />
                    <div className="col-md-12 pt-2 pb-3">
                      <ul className="other-documents">
                        <li>
                          <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                        </li>
                        <li>
                          <h3 className="custum-control" style={{ "min-width": "205px" }}>Investigation Report {toolTip('invReportDocument')}</h3>
                        </li>
                        {/* <li>
                        <div className="document-name-top">Valid Upto </div>
                        <div className="document-name-bottom">(If applicable )</div>
                      </li>
                      <li>
                        <input type="text" className="custum-control year" name={"invReportDocumentMM"} value={data.invReportDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                        <input type="text" className="custum-control year" name={"invReportDocumentYY"} value={data.invReportDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                      </li> */}
                        <li>
                          <div className="file-browse">
                            <button className="btn btn-primary btn-sm">Upload Document</button>
                            <input type="file" accept=".png,.jpg,.pdf" name="invReportDocument" onChange={handleFiles} />
                          </div>
                        </li>
                        <li> </li>
                      </ul>
                      {file.invReportDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.invReportDocument.name}`}</div></div> : ""}
                    </div>
                    <hr />
                    <div className="col-md-12 pt-2 pb-3">
                      <ul className="other-documents">
                        <li>
                          <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                        </li>
                        <li>
                          <h3 className="custum-control" style={{ "min-width": "205px" }}>Shipment (Bill of Leading) {toolTip('logisticDocument')}</h3>
                        </li>
                        {/* <li>
                        <div className="document-name-top">Valid Upto </div>
                        <div className="document-name-bottom">(If applicable )</div>
                      </li>
                      <li>
                        <input type="text" className="custum-control year" name={"logisticDocumentMM"} value={data.logisticDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                        <input type="text" className="custum-control year" name={"logisticDocumentYY"} value={data.logisticDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                      </li> */}
                        <li>
                          <div className="file-browse">
                            <button className="btn btn-primary btn-sm">Upload Document</button>
                            <input type="file" accept=".png,.jpg,.pdf" name="logisticDocument" onChange={handleFiles} />
                          </div>
                        </li>
                        <li> </li>
                      </ul>
                      {file.logisticDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.logisticDocument.name}`}</div></div> : ""}
                    </div>
                    <hr />
                    <div className="col-md-12 pt-2 pb-3">
                      <ul className="other-documents">
                        <li>
                          <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                        </li>
                        <li>
                          <h3 className="custum-control" style={{ "min-width": "205px" }}>Certificate of Origin {toolTip('grnDocument')}</h3>
                        </li>
                        {/* <li>
                        <div className="document-name-top">Valid Upto </div>
                        <div className="document-name-bottom">(If applicable )</div>
                      </li>
                      <li>
                        <input type="text" className="custum-control year" name={"grnDocumentMM"} value={data.grnDocumentMM} placeholder="MM" maxlength="2" onChange={handleChange} />
                        <input type="text" className="custum-control year" name={"grnDocumentYY"} value={data.grnDocumentYY} placeholder="YYYY" maxlength="4" onChange={handleChange} />
                      </li> */}
                        <li>
                          <div className="file-browse">
                            <button className="btn btn-primary btn-sm">Upload Document</button>
                            <input type="file" accept=".png,.jpg,.pdf" name="grnDocument" onChange={handleFiles} />
                          </div>
                        </li>
                        <li> </li>
                      </ul>
                      {file.grnDocument ? <div className="form-group mb-0"><div className="file-name">{`${file.grnDocument.name}`}</div></div> : ""}
                    </div>
                  </div></> :
                  (data.userType && financeDetails.info.action === "viewFinance") ? <>
                    <div className="col-md-12">
                      <div className={"accordionItem mt-3" + (accord['0'] ? " closed" : " open")}>
                        <h2 className="accordionItemHeading" onClick={() => setAccord({ ...accord, '0': !accord['0'] })}>Supporting Documents</h2>
                        <div className="accordionItemContent">
                          <div className="col-md-12">
                            <div className="table-responsive" style={{ "min-height": "150px" }}>
                              <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                                <thead>
                                  <tr>
                                    <th width="20">#</th>
                                    <th>Type of Document</th>
                                    <th width="200">Document Name</th>
                                    <th width="200">Uploaded By</th>
                                    <th width="200">Uploaded On</th>
                                    <th>Signed By</th>
                                    <th className="text-center" width="150">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(data && data.doc && data.doc.length) && docPreviewGenerator(data.doc)}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div></> : ''}

              </div>
            ) : null}

            {data.userType ? (
              <>
                <hr />
                <NavigationButtons />
              </>
            ) : null}
          </div>

          {showPreviewModal && <FilePreview
            userTokenDetails={userTokenDetails}
            fileData={fileData}
            showPreviewModal={showPreviewModal}
            setshowPreviewModal={setshowPreviewModal}
          />}

          {showAclAuthModal &&
            <DigitalSignDoc
              userTokenDetails={userTokenDetails}
              fileData={fileData}
              showAclAuthModal={showAclAuthModal}
              setAclAuthModal={setAclAuthModal}
              refreshDoc={refresh}
              setrefreshDoc={setrefresh} />
          }

        </>
        : (financeDetails.status && financeDetails.type === 6 && financeDetails.page === 2) ?
          <>
            <div class="col-md-12 p-2">
              <h1 className="text-center"><u>Finance against Freight Invoice Form - Review</u></h1><br />
              <div className="row m-2">
                <div class="col-md-2 p-2">
                  <label>Applicant</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={userEmail} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Buyer</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.buyerNameManual ? data.buyerNameManual : data.buyerName} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Invoice No</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.invoiceRefrenceNo} disabled />
                </div>

                <div class="col-md-2 p-2">
                  <label>Po No</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.poRefrenceNo} disabled />
                </div>

                <div class="col-md-2 p-2">
                  <label>Invoice Issue date</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.invoiceIssueDate} disabled />
                </div>

                <div class="col-md-2 p-2">
                  <label>Invoice Due date</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.invoiceDueDate} disabled />
                </div>

                <div class="col-md-2 p-2">
                  <label>Currency</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.currencyName} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Invoice Amount</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.contractAmount} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Currency</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.currencyName} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Requested Amount</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.reqAmount} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Lender</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.lenderName} disabled />
                </div>
                {userTypeId == 8 &&
                  <><div class="col-md-2 p-2">
                    <label>Interest Rate</label>
                  </div>
                    <div class="col-md-4 p-2">
                      <input type="text" class="form-control" value={data.interestRate} disabled />
                    </div></>}
                <div class="col-md-2 p-2">
                  <label>Credit Days</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.creditDays} disabled />
                </div>
                <div class="col-md-2 p-2">
                  <label>Bank Name</label>
                </div>
                <div class="col-md-4 p-2">
                  <input type="text" class="form-control" value={data.bankListName} disabled />
                </div>
                <div className="col-md-12 text-left">
                  <div className="row form-group">

                    {financeDetails.info.action === "viewFinance" &&
                      <>
                        {/* <div class="col-md-12"> */}
                        <div className="col-md-12 mt-3">
                          <h3 className="pt-2 pb-2"><u>Supporting Documents</u></h3>
                          <div className="table-responsive" style={{ "min-height": "150px" }}>
                            <table className="table table-striped table-sm m-0" cellSpacing={0} cellPadding={0}>
                              <thead>
                                <tr>
                                  <th width="20">#</th>
                                  <th>Type of Document</th>
                                  <th width="200">Document Name</th>
                                  <th width="200">Uploaded By</th>
                                  <th width="150">Uploaded On</th>
                                  <th width="150">Signed By</th>
                                  <th className="text-center" width="150">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(data && data.doc && data.doc.length) && docPreviewGenerator(data.doc)}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* </div> */}
                      </>}

                    {(file && Object.keys(file).length > 0) &&
                      <div className="col-md-12 row mt-3">

                        <h3 className="pt-2 pb-2 col-md-12 "><u>Added Documents</u></h3>
                        <div className="col-md-6">
                          <h3 className="col-md-12 pl-0 ml-0"><u>Document Name</u></h3>
                        </div>
                        <div className="col-md-6">
                          <h3 className="col-md-12  pl-0 ml-0"><u>File Name</u></h3>
                        </div>

                        {file && Object.keys(file).length ? Object.keys(file).map((doc) => {
                          return (
                            <>
                              <div className="col-md-6">
                                <div className="row form-group">
                                  <label className="col-md-12">{doc.toUpperCase() || '--'}</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row form-group">
                                  <label className="col-md-12 text-primary">{file[doc].name}</label>
                                </div>
                              </div>
                            </>
                          )
                        }) : ''}
                      </div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 pt-3">
              <input type="checkbox" id="checkDNC" name="dnc" value="on" onChange={handleChange} required checked={data.dnc === "on" ? true : false} />
              <label className="checkbox-smallLabel width-auto" for="checkDNC">
                <span>
                  I hereby declare that the given information by me is best in my
                  knowledge and I did not apply any loan against this contract.
                </span>
              </label>
            </div>
          </> : "oops something is wrong , your were not supposed to get here."}

      {data.userType && financeDetails.page === 2 ? (
        <>
          <hr className="pt-2 pb-2 text-primary" />
          <NavigationButtons />
        </>
      ) : null}
    </>
  );
};


//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    financeDetails: state.financeDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFinanceDetails: (status, type, page, info) => { dispatch(setFinanceDetails({ status: status, type: type, page: page, info: info })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FreightFinance)