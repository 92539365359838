import { combineReducers } from 'redux';
import {
  contractDetails,
  supplierInfo,
  ViewTrack,
  contractDetailLogi,
  contractLogi,
  InvoiceDetail,
  showViewProfileModal,
  showCreateProfileAdminModal,
  showModalAddComm,
  contract,
  contractDocList,
  payment,
  clientType,
  contractAgreement,
  laytimesheet,
  showAddShip,
  registrationData,
  bookShip,
  viewShip,
  getAlert,
  addBankObj,
  disbursed,
  financeDetails,
  productDetails,
  creditLineDetails,
  CreditDocuments,
  addDirectory,
  addQuotation,
  offContract,
  navToggleState,
  creditlineDataBank,
  channelPartnerAccountList,
  channelPartnerDisbursedInvoice,
  channelPartnerRaisedInvoice
} from './reducers/reducer';

const rootReducer = combineReducers({
  contractDetails,
  supplierInfo,
  ViewTrack,
  showViewProfileModal,
  showCreateProfileAdminModal,
  contract,
  contractDocList,
  payment,
  showModalAddComm,
  clientType,
  contractAgreement,
  InvoiceDetail,
  contractLogi,
  laytimesheet,
  contractDetailLogi,
  showAddShip,
  registrationData,
  bookShip,
  viewShip,
  getAlert,
  addBankObj,
  disbursed,
  financeDetails,
  productDetails,
  creditLineDetails,
  CreditDocuments,
  addDirectory,
  addQuotation,
  offContract,
  navToggleState,
  creditlineDataBank,
  channelPartnerAccountList,
  channelPartnerDisbursedInvoice,
  channelPartnerRaisedInvoice
})

export default rootReducer;
