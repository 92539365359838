import React, { useEffect, useState } from 'react';
// useState,
import { connect } from 'react-redux';
import { setRegistrationData } from '../../store/actions/action';

// Components:
import TechTypePicker from './techTypePicker';
import ProfilePicker from './profilePicker';
import CompanyProfileForm from "./companyProfileForm";
import CommodityPicker from "./commodityPicker";
import DocUploadForm from "./docsUploadForm";
import BankForm from "./bankForm";
import TraderProfilePicker from "./traderProfilePicker";
import FinTechKYCForm from './finTechKYCForm';
import ThankYouPage from "./thankYou"
import Footer from '../partial/footer';
import { decryptData } from '../../utils/myFunctions';
import axios from 'axios';
import { tradeFinBackendUrl } from '../../urlConstants';
import ServiceLocationPicker from './serviceLocationPicker';


const Register = ({ userTokenDetails, registrationData, setRegistrationData, employeeData, dispatch }) => {
  console.log("type in reg:", registrationData);
  console.log('employeeData: ', employeeData);

  const [dbData, setdbData] = useState({})

  useEffect(() => {
    // setRegistrationData(2, 1, {})
    let tradefin_ref = window.location.href.split("tradefin_ref=")[1];
    if (tradefin_ref) {
      let email = JSON.parse(decryptData(tradefin_ref))["username"];
      axios.post(tradeFinBackendUrl + "/users/getUserInquiryDetails", { email }).then(result => {
        console.log("getUserInquiryDetails", result);
        if (result.data.length) {
          setdbData(result.data[0])
          // if (result.data[0].user_type_id === "14") {
          //   setRegistrationData(14, 2, {
          //     "techType": 1,
          //     "step1": {
          //       "loggedUserId": null,
          //       "loggedUserType": null,
          //       "referralCode": null
          //     },
          //     "modalNav": 2
          //   })
          // }
          if (result.data[0].user_type_id === "19") {
            setRegistrationData(19, 8, {
              "techType": 2
            })
          }
          if (result.data[0].user_type_id === "8") {
            setRegistrationData(8, 2, {
              "techType": 2,
              "step1": {
                "loggedUserId": null,
                "loggedUserType": null,
                "referralCode": null
              },
              "modalNav": 2
            })
          }
        }
      })
    }
  }, [])

  // if (registrationData.step === 0) {
  //   registrationData.type = 2;
  //   registrationData.step = 1;
  //   registrationData.payload = { "techType": 2 };
  // }

  if (employeeData && employeeData.hasOwnProperty('type')) {
    registrationData.type = employeeData.type;
    registrationData.step = employeeData.step;
    registrationData.payload = {};
  }

  switch (registrationData.step) {
    case 0:
      return (<><TechTypePicker userTokenDetails={userTokenDetails} /><Footer /></>)
    case 1:
      return (<><ProfilePicker userTokenDetails={userTokenDetails} /><Footer /></>);
    case 2:
      return (<><CompanyProfileForm userTokenDetails={userTokenDetails} employeeData={employeeData} dbData={dbData} /><Footer /></>);
    case 3:
      return (<><CommodityPicker /><Footer /></>);
    case 4:
      return (<><DocUploadForm employeeData={employeeData} /><Footer /></>);
    case 5:
      return (<><BankForm /><Footer /></>);
    case 6:
      return (<><ThankYouPage /><Footer /></>);
    case 7:
      return (<><TraderProfilePicker /><Footer /></>)
    case 8:
      return (<><FinTechKYCForm dbData={dbData} /><Footer /></>)
    case 9:
      return (<><ServiceLocationPicker /><Footer /></>);
    default:
      return (<><ProfilePicker userTokenDetails={userTokenDetails} /><Footer /></>);
  }
}



const mapStateToProps = state => {
  return {
    registrationData: state.registrationData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setRegistrationData: (type, step, payload) => { dispatch(setRegistrationData({ type, step, payload })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register)