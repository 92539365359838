export default function validate(values) {
  let errors = {};
  if (!values.email) {
    errors.email = 'Email ID is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email ID is invalid';
  }
  // if (!values.companyName) {
  //   errors.companyName = 'Company Name is required';
  // }
  if (!values.contactPerson) {
    errors.contactPerson = 'Contact Person Name is required';
  }
  if (!values.address) {
    errors.address = 'Address is required';
  }
  if (!values.contactNo) {
    errors.contactNo = 'Contact No is required';
  } else if (isNaN(values.contactNo)) {
    errors.contactNo = 'Contact No Should be a number';
  }
  if (!values.country) {
    errors.country = 'Country is required';
  }
  // if (!values.iecNo && (values.userTypeId === 14 || values.userTypeId === 3 || values.userTypeId === 4)) {
  //   errors.iecNo = 'IEC No is required';
  // }
  // if (!values.avatarBase64 && !values.userAvatar) {
  //   errors.avatar = 'Avatar is Required';
  // }

  console.log(errors)
  return errors;
}
