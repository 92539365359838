import {
  SET_CONTRACT_DETAILS,
  SET_SUPPLIER,
  SET_CONTRACT_DETAIL_LOGI,
  SET_INVOICE_DETAILS,
  SET_USER_VIEW,
  SET_TRACK_VIEW,
  SET_CREATE_PROFILE_VIEW,
  SET_CONTRACT, SET_CONTRACT_LOGI,
  SET_CONTRACT_DOC_LIST, SET_PAYMENT,
  SET_ADD_COMMODITIES, SET_CLIENT_TYPE,
  SET_CONTRACT_AGREEMENT, SET_LAYTIMESHEET,
  SET_ADD_SHIP, SET_BOOK_SHIP, SET_VIEW_SHIP,
  SET_REGISTRATION_DATA,
  SET_ALERT,
  SET_ADDBANK_OBJ,
  SET_DISBURSED,
  SET_FINANCE_DETAILS,
  SET_CREDIT,
  SET_ADD_DIRECTORY,
  SET_OFF_CONTRACT,
  NAV_TOGGLE_STATE,
  SET_CREDITLINE_DATA_BANK,
  SET_CREDIT_DOCUMENTS,
  SET_PRODUCT_DETAILS,
  SET_RAISE_QUOTATION,
  SET_CHANNEL_PARTNER_ACCOUNT_LIST,
  SET_CHANNEL_PARTNER_DISBURSED_INVOICE,
  SET_CHANNEL_PARTNER_RAISED_INVOICE
} from '../actions/action'

export function contractDetails(state = { type: SET_CONTRACT_DETAILS, modal: false, cId: null }, action) {
  if (action.type === SET_CONTRACT_DETAILS) {
    return {
      ...state,
      type: SET_CONTRACT_DETAILS,
      modal: action.data.modal,
      cId: action.data.cId
    }
  } else {
    return state
  }
}

export function supplierInfo(state = {}, action) {
  if (action.type === SET_SUPPLIER) {
    return action.info
  } else {
    return state
  }
}

export function showViewProfileModal(state = { modal: false, info: {} }, action) {

  if (action.type === SET_USER_VIEW) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function InvoiceDetail(state = { modal: false, info: {} }, action) {
  if (action.type === SET_INVOICE_DETAILS) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function ViewTrack(state = { modal: false, info: {} }, action) {
  if (action.type === SET_TRACK_VIEW) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function showModalAddComm(state = { modal: false, info: {} }, action) {
  if (action.type === SET_ADD_COMMODITIES) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function showAddShip(state = { modal: false, info: {} }, action) {
  if (action.type === SET_ADD_SHIP) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function bookShip(state = { modal: false, info: {} }, action) {

  if (action.type === SET_BOOK_SHIP) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function viewShip(state = { modal: false, info: {} }, action) {
  if (action.type === SET_VIEW_SHIP) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function showCreateProfileAdminModal(state = { modal: false, info: {} }, action) {
  if (action.type === SET_CREATE_PROFILE_VIEW) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function contract(state = { modal: false, info: {} }, action) {
  if (action.type === SET_CONTRACT) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function contractLogi(state = { modal: false, info: {} }, action) {
  if (action.type === SET_CONTRACT_LOGI) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function contractDetailLogi(state = { page: false, info: {} }, action) {
  if (action.type === SET_CONTRACT_DETAIL_LOGI) {
    return {
      ...state,
      page: action.info.page,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function productDetails(state = { modal: false, info: {} }, action) {
  if (action.type === SET_PRODUCT_DETAILS) {
    return {
      ...state,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function creditLineDetails(state = { modal: false, page: 0, info: {} }, action) {
  if (action.type === SET_CREDIT) {
    return {
      ...state,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function CreditDocuments(state = { modal: false, info: {} }, action) {
  if (action.type === SET_CREDIT_DOCUMENTS) {
    return {
      ...state,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function contractAgreement(state = { modal: false, info: {} }, action) {
  if (action.type === SET_CONTRACT_AGREEMENT) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function contractDocList(state = { modal: false, contractId: null, info: {} }, action) {
  if (action.type === SET_CONTRACT_DOC_LIST) {
    return {
      ...state,
      modal: action.info.modal,
      contractId: action.info.contractId,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function payment(state = { state: false }, action) {
  if (action.type === SET_PAYMENT) {
    return action.state
  } else {
    return state
  }
}

export function clientType(state = { type: 'buyer' }, action) {
  if (action.type === SET_CLIENT_TYPE) {
    return action.info
  } else {
    return state
  }
}

export function laytimesheet(state = { state: false }, action) {
  if (action.type === SET_LAYTIMESHEET) {
    return action.info
  } else {
    return state
  }
}

export function registrationData(state = { type: 0, step: 0, payload: {} }, action) {
  if (action.type === SET_REGISTRATION_DATA) {
    return action.info
  } else {
    return state
  }
}

export function getAlert(state = { flag: false }, action) {
  if (action.type === SET_ALERT) {
    return action.info
  } else {
    return state
  }
}


export function addBankObj(state = { modal: false, info: {} }, action) {
  if (action.type === SET_ADDBANK_OBJ) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function disbursed(state = { modal: false, info: {} }, action) {
  if (action.type === SET_DISBURSED) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}


export function financeDetails(state = { status: false, type: 0, page: 0, info: {} }, action) {
  if (action.type === SET_FINANCE_DETAILS) {
    return {
      ...state,
      status: action.info.status,
      type: action.info.type,
      page: action.info.page,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function addDirectory(state = { modal: false, info: {} }, action) {
  if (action.type === SET_ADD_DIRECTORY) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function addQuotation(state = { modal: false, info: {} }, action) {
  if (action.type === SET_RAISE_QUOTATION) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function offContract(state = { modal: false, info: {} }, action) {
  if (action.type === SET_OFF_CONTRACT) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function navToggleState(state = { status: 0 }, action) {
  if (action.type === NAV_TOGGLE_STATE) {
    return {
      ...state,
      status: action.info.status
    }
  } else {
    return state
  }
}

export function creditlineDataBank(state = { modal: false, info: {} }, action) {
  if (action.type === SET_CREDITLINE_DATA_BANK) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function channelPartnerAccountList(state = { modal: false, info: {} }, action) {
  if (action.type === SET_CHANNEL_PARTNER_ACCOUNT_LIST) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function channelPartnerDisbursedInvoice(state = { modal: false, info: {} }, action) {
  if (action.type === SET_CHANNEL_PARTNER_DISBURSED_INVOICE) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}

export function channelPartnerRaisedInvoice(state = { modal: false, info: {} }, action) {
  if (action.type === SET_CHANNEL_PARTNER_RAISED_INVOICE) {
    return {
      ...state,
      modal: action.info.modal,
      info: action.info.info
    }
  } else {
    return state
  }
}