import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import call from '../../service';
import DataTable from 'react-data-table-component';
import columns from '../dataTablesColumsMap/CreditLinebyAdmin';
import customStyles from '../dataTablesColumsMap/customTableCss';
import toastDisplay from '../../utils/toastNotification';
import FilePreview from '../utilComponents/previewFile';
import validate from '../../utils/validation_files/creditlineByBank';

const CreditLineGrid = ({ userTokenDetails, manualUserId, manualUserName, manualUserEmail, gridType, navToggleState, cDetailsState, contractDetailLogi }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [creditUserDetail, setCreditUserDetail] = useState([]);
  const [filterData, setFilterData] = useState({})
  const [refresh, setrefresh] = useState(0)
  const [fileData, setfileData] = useState({});
  const [data, setData] = useState({})
  const [error, setError] = useState({})
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [adminAction, setadminAction] = useState({ modal: false, data: {} });
  const [adminActionData, setadminActionData] = useState({});
  const [applicantAction, setApplicantAction] = useState({ modal: false, data: {} });
  const [viewAction, setViewAction] = useState({ modal: false, data: {} });
  const [showLoader, setshowLoader] = useState(false);
  const [activeClass, setActiveClass] = useState(1);
  const [tempactiveClass, settempactiveClass] = useState(1);
  const [maxactiveClass, setmaxactiveClass] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tabing, settabing] = useState(false);
  const [countryData, setcountryData] = useState([]);
  const [portLoading, setPortLoading] = useState([]);
  const [buyersList, setBuyersList] = useState([]);
  const [financialList, setFinancialList] = useState([]);

  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails && userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null

  const astrix = <span className="required-field text-danger">*</span>

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  //useEffect
  useEffect(() => {
    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1, {})
    //---------------------------------------------------------------------------------------------------------------------
  }, [refresh, filterData]);

  useEffect(() => {

    data.userId = manualUserId
    call("POST", "getCreditlineDocDetail", data).then(async (result) => {
      setCreditUserDetail(result);
    }).catch((e) => {
      console.log("error in getRequestCredits", e);
    });

    call('get', 'getallCountry').then((result) => {
      setcountryData(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })

    let object = {
      "userId": manualUserId,
      "userTypeId": userTypeId
    }

    object = { ...object }

    call('POST', 'getBuyersDetail', object).then((result) => {
      console.log('running getBuyersDetail api-->', result.buyerData);
      setBuyersList(result.buyerData);
    }).catch((e) => {
      console.log('error in getBuyersDetail', e);
    })

    let finObject = {
      "manualUserId": manualUserId,
      "userTypeId": userTypeId
    }

    finObject = { ...finObject }

    call('POST', 'getuserfinancegrid', finObject).then((result) => {
      console.log('running getuserfinancegrid api-->', result);
      setFinancialList(result.finData);
    }).catch((e) => {
      console.log('error in getuserfinancegrid', e);
    })
    //------------------------------------------------------------------
  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false)
      toastDisplay("Form Validation Error", "warn")
    }
  }, [error]);

  //---------------------------------------------------------------------------------------------------------------------
  //Port List

  const loadPort = (event) => {
    event.persist();
    call('POST', 'getCountryPort', { 'countryId': event.target.value.split(':')[1] }).then((result) => {
      setPortLoading(result)
    }).catch((e) => {
      console.log('error in getCountryPort loadPort', e);
    })
  }

  //---------------------------------------------------------------------------------------------------------------------
  //Datatable:
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [searchKey, setsearchKey] = useState('');
  const [totalRows, setTotalRows] = useState(0);

  function callPreviewFileComponent(values, action) {
    setfileData({ tbl_doc_id: values, "action": action })
    setshowPreviewModal(true)
  }

  const TableExpandedComponent = ({ data }) => (<>
    <div className="col-md-12 pt-2 pb-0 mb-0 pl-3">
      <div className="row">
        {
          createMiniBody(data)
        }
      </div>
    </div>

    <hr />
  </>
  );

  function documentsVault(allData) {
    for (let i = 0; i < creditUserDetail.length; i++) {
      if (creditUserDetail[i].userid == allData.id) {
        let docJson = creditUserDetail[i].userDetail;
        let keysArray = Object.keys(docJson)
        let returnJSX = keysArray.length ? keysArray.map((keyValue) => {
          return (<>
            {docJson[keyValue].map((arrayElement) => {
              return (

                <div className="col-md-12">
                  <div className="row pt-2">
                    <div className="modal-padding" id={"CreditLine:49"}>
                      <ul className="other-documents pb-0 mb-0">
                        <li style={{ "min-width": "250px" }}>
                          <label className="mb-0" >
                            <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" />
                            </span>
                            {keyValue}
                          </label>
                        </li>
                        <li style={{ "min-width": "250px" }}>
                          <button title="View File" className="text-center btn btn-primary btn-sm m-1" onClick={
                            () => callPreviewFileComponent(arrayElement.docID, "view")}>
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </button>
                          <button title="Download File" className="text-center btn btn-primary btn-sm m-1" onClick={
                            () => callPreviewFileComponent(arrayElement.docID, "download")}>
                            <i className="fa fa-download" aria-hidden="true"></i>
                          </button>
                        </li>
                      </ul>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              )
            })}
          </>)
        }) : ""

        return (returnJSX)
      }
    }
  }

  function createMiniBody(data) {
    for (let i = 0; i < creditUserDetail.length; i++) {
      if (creditUserDetail[i].userid == data.id) {
        let docJson = creditUserDetail[i].userDetail;
        let keysArray = Object.keys(docJson)
        let returnJSX = keysArray.length ? keysArray.map((keyValue) => {
          return (<>
            {docJson[keyValue].map((arrayElement) => {
              return (
                <div className="col-md-4 bg-light border-left mb-1">
                  <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                  <button className="btn btn-link btn-sm" onClick={() => callPreviewFileComponent(arrayElement.docID, "view")}>
                    {keyValue}
                  </button>
                </div>
              )
            })}
          </>)
        }) : ""

        return (returnJSX)
      }
    }
  }

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "manualUserId": manualUserId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": gridType
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      console.log('running getCreditLineByType api-->', result);
      settableData(formatDataFortable(result.finData));
      setTotalRows(result.countdata.totalcount)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
      setLoading(false)
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "manualUserId": manualUserId,
      "userTypeId": userTypeId,
      "userId": userId,
      "gridType": gridType
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getCreditLineByType', objectAPI).then((result) => {
      settableData(formatDataFortable(result.finData))
      setTotalRows(result.countdata.totalcount);
      setLoading(false);
    }).catch((e) => {
      console.log('error in getCreditLineByType', e);
      setLoading(false)
    })
  };

  function formatDataFortable(dataArray) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let tempObj = {}
      tempObj.id = dataArray[i].id;
      tempObj.requestId = dataArray[i].credit_id;
      tempObj.credit_amount = dataArray[i].credit_amount;
      var d = new Date(dataArray[i].created_at);
      let date = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      tempObj.created_at = date + '/' + month + '/' + year;
      tempObj.loanType = dataArray[i].loanType;
      tempObj.admin_status = dataArray[i].admin_status / 1;
      tempObj.status = dataArray[i].status;
      tempObj.admin_comment = dataArray[i].admin_comment ? dataArray[i].admin_comment : "--";

      if (userTypeId === 1) {
        tempObj.action = <button className="btn btn-success btn-sm" onClick={() => setadminAction({ modal: true, data: { id: dataArray[i].id, year: dataArray[i].year, allData: dataArray[i] } })} >
          <i className={"fas fa-eye"} aria-hidden="true"></i>
        </button>
      }
      else if (dataArray[i].status === 'User Approval Pending' || dataArray[i].status === 'User Approved' || dataArray[i].status === 'User Rejected') {
        tempObj.action = 'N/A';
      }
      else {
        tempObj.action = <button className="btn btn-success btn-sm" onClick={() => setApplicantAction({ modal: true, data: { id: dataArray[i].id, allData: dataArray[i] } })} >
          <i class="fas fa-folder-open"></i>
        </button>
      }
      resultArray.push(tempObj)
    }
    return resultArray
  }
  //---------------------------------------------------------------------------------------------------------------------
  // functions

  //---------------------------------------------------------------------------------------------------------------------

  const handleChange = (event) => {
    event.persist();
    setData(logdata => ({ ...logdata, [event.target.name]: event.target.value }));
    setError(errorData => ({ ...errorData, [event.target.name]: "" }))
  }

  const handleFiles = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.files[0] });
    setError({ ...error, [event.target.name]: "" });
  };

  const handleChangeAdmin = (event) => {
    if (event.target.name === "adminStatus" && event.target.value / 1 === 2) {
      setadminActionData({ ...adminActionData, [event.target.name]: event.target.value, "adminComment": "Details OK" })
    } else {
      setadminActionData({ ...adminActionData, [event.target.name]: event.target.value })
    }
  };

  function submitAdminStatus() {
    if (!adminActionData.adminStatus || !adminActionData.adminComment) {
      toastDisplay("Please Fill all details", "warn")
      return
    }
    adminActionData.manualUserId = manualUserId;
    adminActionData.manualUserEmail = manualUserEmail;
    adminActionData.userId = userId;
    adminActionData.userEmail = userEmail;
    adminActionData.bank_id = adminAction.data.allData.bank_id;
    call('POST', 'adminUpdateCreditLine', { ...adminActionData, ...adminAction.data }).then((result) => {
      toastDisplay(result, "success");
      setrefresh(refresh + 1)
      setadminAction({ modal: false, data: {} })
      setadminActionData({})
    }).catch((e) => {
      console.log('error in adminUpdateCreditLine', e);
      toastDisplay(e, "error");
    })

  }

  const handlePrev = (id) => setActiveClass(id - 1);

  const handleNext = () => {
    if (activeClass < 3) {
      setActiveClass(activeClass + 1)
    }
    setIsSubmitting(true)
  }

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting(true);
  };

  const handleSubmit = function () {
    setshowLoader(true)

    data.userId = manualUserId;
    data.userName = manualUserName;
    data.userEmail = manualUserEmail;
    data.contract_id = applicantAction.data.allData.credit_id;
    data.buyer_id = applicantAction.data.allData.buyer_id;
    data.bankId = userId;
    data.bankEmail = userEmail;
    data.requestedAmount = applicantAction.data.allData.credit_amount;
    data.credit_id_by_user = applicantAction.data.allData.id;
    data.company_name = userName;

    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    call('POST', "insertcreditofferbybbank", formData).then((result) => {
      console.log('post request result:', result);
      toastDisplay(result, "success");
      setshowLoader(false)
      setrefresh(refresh + 2)
      setIsSubmitting(false)
    }).catch(err => {
      setIsSubmitting(false)
      setshowLoader(false)
      console.log("conn:", err)
      toastDisplay(err, "error");
    })
  }

  const handleActiveClass = (id) => {
    if (id < activeClass) {
      setActiveClass(id)
    } else {
      settempactiveClass(id);
      setIsSubmitting(true)
      settabing(true)
    }
  }

  //---------------------------------------------------------------------------------------------------------------------
  // Filters
  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };


  const reset = () => {
    setFilterData({})
    document.getElementById("searchInput").value = ''
    document.getElementById("searchFromdate").value = ''
    document.getElementById("searchTodate").value = ''
    setrefresh(refresh + 1)
  }


  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };

  //---------------------------------------------------------------------------------------------------------------------
  // Mini Preview

  function miniPreview() {
    return (
      <div className="modal-padding">
        <ul className="price-ul">
          <li>
            <div className="price-left">{manualUserName ? manualUserName : '--'}</div>
            <div className="price-right">Username </div>
          </li>
          <li>
            <div className="price-left">{applicantAction.data.allData.company_name}</div>
            <div className="price-right">Lender Name </div>
          </li>
          <li>
            <div className="price-left"> {applicantAction.data.allData.credit_amount} </div>
            <div className="price-right">Requested Amount</div>
          </li>
          <li>
            <div className="price-left">{applicantAction.data.allData.created_at}</div>
            <div className="price-right">Created At</div>
          </li>
          <li>
            <div className="price-left"> {applicantAction.data.allData.status}</div>
            <div className="price-right">Status</div>
          </li>
        </ul>
      </div>
    )
  }


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return

  return (
    <>
      <div className="table-responsive">
        <DataTable
          columns={columns}
          data={tableData}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
          persistTableHead
          // expandableRows
          // expandableRowsComponent={<TableExpandedComponent />}
          // expandOnRowClicked
          highlightOnHover
          onSort={handleSort}
          sortServer
          striped
          noHeader
          customStyles={customStyles}
        />
      </div>

      {showPreviewModal && <FilePreview
        userTokenDetails={userTokenDetails}
        fileData={fileData}
        showPreviewModal={showPreviewModal}
        setshowPreviewModal={setshowPreviewModal}
        viewTrail={false}
      />}

      {adminAction.modal &&
        <div className={"modal" + (adminAction.modal ? " show" : "")} id="Confirmation">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Action Form</h4>
                <button type="button" className="close" onClick={() => { setadminAction({ modal: false, data: {} }) }}>×</button>
              </div>
              <div className="modal-body">

                <div className="col-md-12 text-center">
                  <h3> {`${manualUserName} - Credit Details`} </h3>
                </div>
                <hr className="pt-1 pb-1" />
                <div className="col-md-12">
                  <div className="row">

                    <div className="col-md-6 ">
                      <label>Choose your Status for Credit Line</label>
                    </div>

                    <div className="row col-md-6 ">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="adminStatus" id="adminStatus1" value="1" onChange={handleChangeAdmin} checked={adminActionData.adminStatus / 1 === 1 ? true : false} />
                        <label className="form-check-label text-danger" for="adminStatus1">Revision</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="adminStatus" id="adminStatus2" value="2" onChange={handleChangeAdmin} checked={adminActionData.adminStatus / 1 === 2 ? true : false} />
                        <label className="form-check-label text-success" for="adminStatus2">Approved</label>
                      </div>
                    </div>
                    <hr className="col-md-11 pt-1 pb-1" />
                    <div className="row col-md-12">
                      <label className="col-md-2">Comment</label>
                      <div className="col-md-10">
                        <textarea name="adminComment" value={adminActionData.adminComment} placeholder="Enter Message" className="form-control" onChange={handleChangeAdmin} />
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <div className="modal-footer primary">
                <button type="button" className="btn btn-success btn-sm" onClick={() => submitAdminStatus()}>Submit</button>
                <button type="button" className="btn btn-info btn-sm" onClick={() => setadminAction({ modal: false, data: {} })}>Cancel</button>
              </div>
            </div>
          </div>
        </div>}

      {applicantAction.modal &&
        <div className={"modal" + (applicantAction.modal ? " show" : "")} id="Confirmation">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">Credit Limit Form</h4>
                <button type="button" className="close" onClick={() => { setApplicantAction({ modal: false, data: {} }) }}>×</button>
              </div>
              <div className="modal-body calc-modal">
                <div className="col-md-12 text-center">
                  <h3> {`${manualUserName} - Financial Details for Year `} </h3>
                </div>
                <hr className="pt-1 pb-1" />
                {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                <div id="tabs1" className="tab-contents no-js">
                  <div className="tab-nav">

                    <li id="1" className={"tab-link" + (activeClass === 1 ? " is-active" : "") + ((1 < maxactiveClass) ? " is-completed" : "")} onClick={(1 <= maxactiveClass) ? () => handleActiveClass(1) : ""}>Applicant Info</li>
                    <li id="2" className={"tab-link" + (activeClass === 2 ? " is-active" : "") + ((2 < maxactiveClass) ? " is-completed" : "")} onClick={(2 <= maxactiveClass) ? () => handleActiveClass(2) : ""}>Credit Limit Offer</li>
                  </div>


                  <div className={"tab-content" + (activeClass === 1 ? " is-active" : "")}>
                    < div className="calc-inner-modal">
                      {miniPreview()}
                      <hr className="pt-1 pb-1" />
                      <div className="col-md-12 row">
                        <div className="col-md-12">
                          <h3 className="green mt-3"> Applicant Financial Year Document </h3>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        {
                          documentsVault(applicantAction.data.allData)
                        }
                      </div>
                    </div>
                  </div>


                  <div className={"tab-content" + (activeClass === 2 ? " is-active" : "")}>
                    <div className="calc-inner-modal">
                      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

                      {/* Extra Section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Credit Details
                                    </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>User Name </label>
                                      <input type="text" className={" form-control" + (!error.userName ? '' : ' border-danger')} placeholder="" name="userName" value={manualUserName} />
                                      {error.userName && <p className="text-danger error-contract">{error.userName}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Requested Amount </label>
                                      <input type="number" className={" form-control" + (!error.requestedAmount ? '' : ' border-danger')} placeholder="" name="requestedAmount" value={applicantAction.data.allData.credit_amount} />
                                      {error.requestedAmount && <p className="text-danger error-contract">{error.requestedAmount}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Created At </label>
                                      <input type="datetime" className={" form-control" + (!error.created_at ? '' : ' border-danger')} placeholder="" name="created_at" value={applicantAction.data.allData.created_at} />
                                      {error.created_at && <p className="text-danger error-contract">{error.created_at}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Status</label>
                                      <input type="text" className={" form-control" + (!error.status ? '' : ' border-danger')} placeholder="" name="status" value={applicantAction.data.allData.status} />
                                      {error.status && <p className="text-danger error-contract">{error.status}</p>}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 1st Section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Funding Details
                                    </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Lender Name {astrix} </label>
                                      <input type="text" className={" form-control" + (!error.lendername ? '' : ' border-danger')} placeholder="" name="lendername" value={userName} />
                                      {error.lendername && <p className="text-danger error-contract">{error.lendername}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Approved Finance Amount {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.approvedFinanceAmount ? '' : ' border-danger')} placeholder="" name="approvedFinanceAmount" value={data.approvedFinanceAmount} onChange={handleChange} />
                                      {error.approvedFinanceAmount && <p className="text-danger error-contract">{error.approvedFinanceAmount}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Funding Percentage(%) {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.fundingPercentage ? '' : ' border-danger')} placeholder="" name="fundingPercentage" value={data.fundingPercentage} onChange={handleChange} />
                                      {error.fundingPercentage && <p className="text-danger error-contract">{error.fundingPercentage}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Funding Tenor {astrix}</label>
                                      <input type="number" className={" form-control" + (!error.fundingTenor ? '' : ' border-danger')} placeholder="" name="fundingTenor" value={data.fundingTenor} onChange={handleChange} />
                                      {error.fundingTenor && <p className="text-danger error-contract">{error.fundingTenor}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Due date calculation from</label>
                                      <input type="date" className={" form-control" + (!error.duedateCalcFrom ? '' : ' border-danger')} placeholder="" name="duedateCalcFrom" value={data.duedateCalcFrom} onChange={handleChange} />
                                      {error.duedateCalcFrom && <p className="text-danger error-contract">{error.duedateCalcFrom}</p>}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 2nd section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Interest Details
                                    </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Interest Rate {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.interestRate ? '' : ' border-danger')} placeholder="" name="interestRate" value={data.interestRate} onChange={handleChange} />
                                      {error.interestRate && <p className="text-danger error-contract">{error.interestRate}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label className="col-md-12">Interest Collection {astrix} </label>
                                      <div className="col-md-12">
                                        <select className="form-control" name="interestCollection" value={data.interestCollection} onChange={handleChange} >
                                          <option value="" selected>Select any one</option>
                                          <option value="deducted_upfront">Deducted upfront</option>
                                          <option value="later">Later</option>
                                        </select>
                                      </div>
                                      {error.interestCollection && <p className="text-danger error-contract">{error.interestCollection}</p>}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 3rd section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Finance Request Step Fees
                                    </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Percentage {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.finRequestPercentage ? '' : ' border-danger')} placeholder="" name="finRequestPercentage" value={data.finRequestPercentage} onChange={handleChange} />
                                      {error.finRequestPercentage && <p className="text-danger error-contract">{error.finRequestPercentage}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label className="col-md-12">Collection {astrix} </label>
                                      <div className="col-md-12">
                                        <select className="form-control" name="finRequestCollection" value={data.finRequestCollection} onChange={handleChange} >
                                          <option value="" selected>Select any one</option>
                                          <option value="upfront">Upfront</option>
                                          <option value="deductible">Deductible in first transaction</option>
                                        </select>
                                      </div>
                                      {error.finRequestCollection && <p className="text-danger error-contract">{error.finRequestCollection}</p>}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 4th section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Additional Fees Detail
                                    </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Additional Fee 1 {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.additionalFee ? '' : ' border-danger')} placeholder="" name="additionalFee" value={data.additionalFee} onChange={handleChange} />
                                      {error.additionalFee && <p className="text-danger error-contract">{error.additionalFee}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Description 1 {astrix} </label>
                                      <input type="text" className={" form-control" + (!error.additionalDescription ? '' : ' border-danger')} placeholder="" name="additionalDescription" value={data.additionalDescription} onChange={handleChange} />
                                      {error.additionalDescription && <p className="text-danger error-contract">{error.additionalDescription}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label className="col-md-12">Collection {astrix} </label>
                                      <div className="col-md-12">
                                        <select className="form-control" name="additionalCollection" value={data.additionalCollection} onChange={handleChange} >
                                          <option value="" selected>Select any one</option>
                                          <option value="deducted_upfront">Deducted upfront</option>
                                          <option value="later">Later</option>
                                        </select>
                                      </div>
                                      {error.additionalCollection && <p className="text-danger error-contract">{error.additionalCollection}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Last Fee Charges(% or USD per day) {astrix} </label>
                                      <input type="text" className={" form-control" + (!error.lastfeeCharges ? '' : ' border-danger')} placeholder="" name="lastfeeCharges" value={data.lastfeeCharges} onChange={handleChange} />
                                      {error.lastfeeCharges && <p className="text-danger error-contract">{error.lastfeeCharges}</p>}
                                    </div>
                                    <br />
                                    <br />

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 5th section */}

                      {/* <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Platform Fees
                                    </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Platform Fee {astrix} </label>
                                      <input type="number" className={" form-control" + (!error.platformFee ? '' : ' border-danger')} placeholder="" name="platformFee" value={data.platformFee} onChange={handleChange} />
                                      {error.platformFee && <p className="text-danger error-contract">{error.platformFee}</p>}
                                    </div>
                                    <br />
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* 6th section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Other T&C
                                    </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="form-group col-md-6">
                                      <label>Sanction Country {astrix} </label>
                                      <div className="col-md-12">
                                        <select className={" form-control" + (!error.sanctionCountry ? '' : ' border-danger')} name="sanctionCountry" value={data.sanctionCountry} onChange={(event) => { handleChange(event); loadPort(event); }}>
                                          <option value="" selected>--Select country--</option>
                                          {/* <CountryList /> */}
                                          {
                                            countryData.length ? countryData.map((item, index) => {
                                              return (<option value={item.sortname + ':' + item.id}>{item.name}</option>)
                                            }) : ''
                                          }
                                        </select>
                                        {error.sanctionCountry && <p className="text-danger error-contract">{error.sanctionCountry}</p>}
                                      </div>
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Sanction Port {astrix} </label>
                                      <div className="col-md-12">
                                        <select className={" form-control" + (!error.sanctionPort ? '' : ' border-danger')} name="sanctionPort" value={data.sanctionPort} onChange={handleChange}>
                                          <option value="" selected>--Select loading port--</option>
                                          {portLoading.map((portLoading) => {
                                            return (
                                              <option value={portLoading.port_ckey} >{portLoading.port_name}</option>
                                            )
                                          })}
                                        </select>
                                        {error.sanctionPort && <p className="text-danger error-contract">{error.sanctionPort}</p>}
                                      </div>
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Commodities {astrix} </label>
                                      <input type="text" className={" form-control" + (!error.commodities ? '' : ' border-danger')} placeholder="" name="commodities" value={data.commodities} onChange={handleChange} />
                                      {error.commodities && <p className="text-danger error-contract">{error.commodities}</p>}
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>Offer Valid Till {astrix} </label>
                                      <input type="date" className={" form-control" + (!error.offerValidity ? '' : ' border-danger')} placeholder="" name="offerValidity" value={data.offerValidity} onChange={handleChange} />
                                      {error.offerValidity && <p className="text-danger error-contract">{error.offerValidity}</p>}
                                    </div>

                                    <br />
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 7th section */}

                      <div className="accordionWrapper row pr-0 pt-0">
                        <div className="container-fluid accordionItem open">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="green mt-3">
                                    Upload Documents
                                    </h3>
                                  <hr />
                                </div>
                                <div className="col-md-12">
                                  <div className="row pt-2">

                                    <div className="modal-padding" id={"Creditline:49"}>
                                      <ul className="other-documents pb-0 mb-0">
                                        <li style={{ "min-width": "200px" }}>
                                          <label className="mb-0" >
                                            <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                          Credit Line Document {astrix}
                                          </label>
                                        </li>
                                        <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                                          <div className="file-browse">
                                            <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                                            <input type="file" accept=".png,.jpg,.pdf,.xml" id="file_1" name={"Creditline:49"} onChange={handleFiles} />
                                            {error["Creditline:49"] && <p lassName="text-danger error-contract">{error["Creditline:49"]}</p>}
                                          </div>
                                        </li>
                                        <li style={{ "min-width": "200px" }}>
                                          {(data["Creditline:49"]) ? <div className="filePath text-left"><div className="file-name">{`${data["Creditline:49"].name}`}</div></div> : ""}
                                        </li>
                                      </ul>
                                      <br />
                                      <br />
                                    </div>

                                    <div className="modal-padding" id={"Creditline ExtraDoc:49"}>
                                      <ul className="other-documents pb-0 mb-0">
                                        <li style={{ "min-width": "200px" }}>
                                          <label className="mb-0" >
                                            <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
                                          Extra Document(If Any)
                                          </label>
                                        </li>
                                        <li className="text-center pr-0" style={{ "min-width": "200px" }}>
                                          <div className="file-browse">
                                            <button type="button" className="btn btn-primary btn-sm">Select Document</button>
                                            <input type="file" accept=".png,.jpg,.pdf,.xml" id="file_1" name={"Creditline ExtraDoc:49"} onChange={handleFiles} />
                                            {error["Creditline ExtraDoc:49"] && <p lassName="text-danger error-contract">{error["Creditline ExtraDoc:49"]}</p>}
                                          </div>
                                        </li>
                                        <li style={{ "min-width": "200px" }}>
                                          {(data["Creditline ExtraDoc:49"]) ? <div className="filePath text-left"><div className="file-name">{`${data["Creditline ExtraDoc:49"].name}`}</div></div> : ""}
                                        </li>
                                      </ul>
                                      <br />
                                      <br />
                                    </div>

                                    <br />
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="modal-footer primary">
                <button type="button" className="btn btn-dark btn-sm" onClick={activeClass > 1 ? () => handlePrev(activeClass) : ''} id="previd">Back</button>
                {activeClass > 1 ?
                  <button type="button" className="btn btn-info btn-sm" onClick={() => preHandleSubmit()}>Submit</button>
                  :
                  <button type="button" className="btn btn-info btn-sm" onClick={() => handleNext()} id="nextid">Next</button>
                }
                <button type="button" className="btn btn-info btn-sm" onClick={() => setApplicantAction({ modal: false, data: {} })}>Cancel</button>
              </div>
            </div>
          </div>
        </div>}

      {viewAction.modal &&
        <div className={"modal" + (viewAction.modal ? " show" : "")} id="Confirmation">
          <div className="modal-dialog modal-lg border-inner" id="parent_class">
            <div className="modal-content">
              <div className="modal-header primary">
                <h4 className="modal-title text-white">View Form</h4>
                <button type="button" className="close" onClick={() => { setViewAction({ modal: false, data: {} }) }}>×</button>
              </div>
              <div className="modal-body">
                <div className="col-md-12 text-center">
                  <h3> {`${userName} - Credit Request Details`} </h3>
                </div>
                <hr className="pt-1 pb-1" />

                <div className="modal-padding">
                  <ul className="price-ul">
                    <li>
                      <div className="price-right">Lender Name </div>
                      <div className="price-left">{viewAction.data.allData.company_name}</div>
                    </li>
                    <li>
                      <div className="price-right">Requested Amount </div>
                      <div className="price-left">{viewAction.data.allData.credit_amount}</div>
                    </li>
                    <li>
                      <div className="price-right">Loan Type</div>
                      <div className="price-left"> {viewAction.data.allData.loanType} </div>
                    </li>
                    <li>
                      <div className="price-right">Created At</div>
                      <div className="price-left"> {viewAction.data.allData.created_at} </div>
                    </li>
                    <li>
                      <div className="price-right">Status</div>
                      <div className="price-left">{viewAction.data.allData.status}</div>
                    </li>
                  </ul>
                </div>
                <hr className="pt-1 pb-1" />

                {buyersList.map((value) => {

                  return (
                    <>{
                      value.id == viewAction.data.allData.buyer_id &&
                      <div>
                        <div className="col-md-12 text-center">
                          <h3> {'Buyer Details'} </h3>
                        </div>
                        <div className="modal-padding">
                          <ul className="price-ul">
                            <li>
                              <div className="price-right">Buyer's Name </div>
                              <div className="price-left">{value.buyerName}</div>
                            </li>
                            <li>
                              <div className="price-right">Buyer's Email </div>
                              <div className="price-left">{value.buyerEmail}</div>
                            </li>

                            <li>
                              <div className="price-right">Buyer's Phone </div>
                              <div className="price-left">{value.buyerPhone}</div>
                            </li>
                            <li>
                              <div className="price-right">Previous Year Annual Sale </div>
                              <div className="price-left">{value.previousAnnualSale}</div>
                            </li>
                            <li>
                              <div className="price-right">Expected Current Year Annual Sale </div>
                              <div className="price-left">{value.currentAnnualSale}</div>
                            </li>
                            <li>
                              <div className="price-right">Product Details </div>
                              <div className="price-left">{value.productDetails}</div>
                            </li>
                            <li>
                              <div className="price-right">Buyer's Address </div>
                              <div className="price-left">{value.buyerAddress}</div>
                            </li>
                            <li>
                              <div className="price-right">Buyer's Country </div>
                              <div className="price-left">{value.buyerCountry}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    }
                    </>
                  )
                })}


                <hr className="pt-1 pb-1" />

                {financialList.map((value) => {
                  (console.log('nnnnnnnnnnnnn ', viewAction.data.allData))
                  return (
                    <>{
                      value.id == viewAction.data.allData.financial_year_id ?
                        <div>
                          <div className="col-md-12 text-center">
                            <h3> Financial Details </h3>
                          </div>
                          <div className="modal-padding">
                            <ul className="price-ul">
                              <li>
                                <div className="price-right">Financial Year </div>
                                <div className="price-left">{value.year}</div>
                              </li>
                              <li>
                                <div className="price-right">Currency </div>
                                <div className="price-left">{value.currency.split(':')[1]}</div>
                              </li>

                              <li>
                                <div className="price-right">Turn Over </div>
                                <div className="price-left">{value.turnover + value.currency.split(':')[1]}</div>
                              </li>
                              <li>
                                <div className="price-right">Net Profit </div>
                                <div className="price-left">{value.netprofit + value.currency.split(':')[1]}</div>
                              </li>
                              <li>
                                <div className="price-right">Net Worth </div>
                                <div className="price-left">{value.networth + value.currency.split(':')[1]}</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        :
                        ''
                    }
                    </>
                  )
                })}
              </div>
            </div>

          </div>
        </div>
      }
    </>
  )
}

//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditLineGrid)