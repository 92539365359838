import React, { useEffect, useState } from 'react';
import Header from '../partial/header';
import Footer from '../partial/footer';
import Sidebar, { inspectionTypeIds } from '../partial/sidebar';
import ContractDetail from '../contractDetails/contractDetails';
import ContractLogs from '../contractDetails/contractLogs'
import call from '../../service';
import { connect } from 'react-redux';
import toastDisplay from '../../utils/toastNotification';
import { setContractDetails, setContractState, setContractDocList } from '../../store/actions/action';
import { ToastContainer } from 'react-toastify';
import CreateContractModal from '../tradeContract/createContractModal';
import DataTable from 'react-data-table-component';
import columns from '../dataTablesColumsMap/contractListColoums';
import customStyles from '../dataTablesColumsMap/customTableCss';
import { formatDate_Application, } from '../../utils/dateFormaters';
import { getSocket } from '../../socket'
import QuotRaiseModal from './quotationRaiseModal.js';
import InvestigationRemark from './investigationRemark.js';
import InsuranceRemark from './insuranceRemark.js';
import { InspectionContractModal } from './inspectionContractModal';
import { useLocation } from 'react-router-dom';
import { WorkOrderDetail } from './workOrderDetail';
const queryString = require('query-string');

const ContractList = ({ userTokenDetails, cDetailsState, setContractDetails, contractState, setContractState, clientType, setContractDocList, navToggleState, dispatch }) => {


  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const openContract = params.get('openContract');

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  var Socket = getSocket()
  const [contractCount, setcontractCount] = useState({})
  const [refresh, setrefresh] = useState(0)
  const [searchKey, setsearchKey] = useState("")
  const [filterData, setFilterData] = useState({})
  const [statusFilter, setstatusFilter] = useState(0)
  const [contractLog, setcontractLog] = useState({ modal: false, contract_no: "" })
  const [showQuotRaiseModal, setshowQuotRaiseModal] = useState({})
  const [showInvestigationRemark, setshowInvestigationRemark] = useState({})
  const [showInsuranceRemark, setshowInsuranceRemark] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [quotaAvail, setQuotaAvail] = useState(false)
  const [userPlanQuota, setUserPlanQuota] = useState({});
  const [deleteConfirm, setdeleteConfirm] = useState(false);
  const [contDeleteData, setcontDeleteData] = useState({});
  const [inspectionContractModal, setInspectionContractModal] = useState({ show: false, data: {} })
  const [inspectionWorkorderDetails, setInspectionWorkorderDetails] = useState({ show: false, data: {}, offer_data: {} })

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const parentId = userTokenDetails.parentId !== undefined ? userTokenDetails.parentId : null

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // use effects
  useEffect(() => {
    //------------------------------------------------------------------

    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    fetchTableData(1, { userId: userId, type_id: userTypeId })

    call('get', 'v1/plan/quota').then((result) => {
      setUserPlanQuota(result)
      if (result && result.trades && result.trades.enable && ((result.trades.limitLeft / 1) > 0 || (result.trades.limitLeft / 1) === -1))
        setQuotaAvail(true)
    }).catch((e) => {
      console.log("Error while  getUserCurrentPlanQuota header:-->", e);
    })

    //------------------------------------------------------------------
  }, [refresh, clientType.type, contractState.info.refresh, filterData]);

  useEffect(() => {
    Socket.on("notification", (data) => {
      if (data.notif_type / 1 === 1) {
        setrefresh(refresh + 1)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkFilterQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("refresh in in grid comp---===>", refresh)
  // console.log("userPlanQuota grid==>", userPlanQuota)
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // datatable
  const [tableData, settableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    if (openContract) {
      let objectAPI = {
        "currentPage": 1,
        "resultPerPage": 1,
        "userId": userId,
        "type_id": userTypeId,
        "activeType": clientType.type,
        "userParentId": parentId,
        "gridOnly": true,
        search: openContract
      }
      call('POST', 'getContracts', objectAPI).then((result) => {
        // console.log('running getcontract api-->', result);
        if (result?.contractdata?.length) {
          setContractDetails(result.contractdata[0]['contract_number'])
        }
        else {
          toastDisplay("Invalid link", "error")
        }
      }).catch((e) => {
        console.log('error in getContracts', e);
      })
    }
  }, [openContract])

  function fetchTableData(page, inptObject) {
    setLoading(true);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": perPage,
      "userId": inptObject.userId ? inptObject.userId : userId,
      "type_id": inptObject.type_id ? inptObject.type_id : userTypeId,
      "activeType": clientType.type,
      "userParentId": parentId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getContracts', objectAPI).then((result) => {
      console.log('running getcontract api-->', result);
      settableData(formatDataFortable(result.contractdata, objectAPI.userId))
      setTotalRows(result.countdata.totalcount)
      setcontractCount(result.countdata)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false);
    })
  }

  const handlePageChange = page => {
    fetchTableData(page, {});
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    let objectAPI = {
      "currentPage": page,
      "resultPerPage": newPerPage,
      "userId": userId,
      "type_id": userTypeId,
      "activeType": clientType.type,
      "userParentId": parentId,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getContracts', objectAPI).then((result) => {
      // console.log('running getcontract api-->', result);
      settableData(formatDataFortable(result.contractdata, objectAPI.userId))
      setTotalRows(result.countdata.totalcount)
      setcontractCount(result.countdata)
      setLoading(false);
    }).catch((e) => {
      console.log('error in getContracts', e);
      setLoading(false);
    })
  };

  const handleSort = (column, sortDirection) => {
    setFilterData({
      ...filterData,
      "sortColum": column.selector,
      "sortDirection": sortDirection
    })
  };

  const TableExpandedComponent = ({ data }) => {

    if (data.isMktPlaceContract) {
      let offerType = inspectionTypeIds.includes(userTypeId) ? "offers_sent" : "offers_received"
      return (
        <>
          <div className="col-md-12 row pt-2 text-center pb-0 mb-0">
            <div className="ml-5 mr-5 border-left" style={{ "width": "135px" }}>
              <p className="pl-2 pt-1" >{data.actions.contract_number}</p>
            </div>
            <div className="border-left">
              {/* <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => {
                setInspectionContractModal({ show: true, data: data.dbData });
              }}><span className="pr-2"><i class="fa fa-pencil" aria-hidden="true"></i></span>Update Contract</button> */}
              <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => {
                setInspectionWorkorderDetails({ show: true, offer_data: data.dbData[offerType][0], data: data.dbData });
              }}>
                <span className="pr-2"><i class="fa fa-folder-open" aria-hidden="true">
                </i></span>{'Workorder Details'}</button>
            </div>
          </div>
        </>
      )
    }
    return (
      <>
        {/* {console.log("data in extra comp==>", data)} */}
        <div className="col-md-12 row pt-2 text-center pb-0 mb-0">
          <div className="ml-5 mr-5 border-left" style={{ "width": "135px" }}>
            <p className="pl-2 pt-1" >{data.actions.contract_number}</p>
          </div>

          {(
            ((((userTypeId === 14 && clientType.type === "buyer") || userTypeId === 3) && data.actions.status_buyer === 0) ||
              (((userTypeId === 14 && clientType.type === "seller") || userTypeId === 4) && data.actions.status_seller === 0)) &&
            (aclMap.contract_access && aclMap.contract_access.cont_edit && aclMap.contract_access.cont_edit.isExist)
            && data.actions.contAddress === null
          ) && <div className="border-left">
              <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => {
                setContractState(true, { contractNo: data.actions.contract_number, "modalMode": 'edit' });
              }}><span className="pr-2"><i class="fa fa-pencil" aria-hidden="true"></i></span>Contract Form</button>
            </div>}

          {(
            ((((userTypeId === 14 && clientType.type === "buyer") || userTypeId === 3) && data.actions.status_buyer === 1) ||
              (((userTypeId === 14 && clientType.type === "seller") || userTypeId === 4) && data.actions.status_seller === 1)) &&
            (aclMap.contract_access && aclMap.contract_access.cont_view && aclMap.contract_access.cont_view.isExist)
            && data.actions.contAddress === null
          ) && <div className="border-left">
              <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => {
                setContractState(true, { contractNo: data.actions.contract_number, "modalMode": 'view' });
              }}><span className="pr-2"><i class="fa fa-eye" aria-hidden="true"></i></span>Contract Form</button>
            </div>}

          {/* <div className="border-left">
          <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => { viewContractPDF(data.actions.contract_number, userTypeId, clientType.type, "view") }}>
            <span className="pr-2"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></span>View Contract PDF</button>
        </div> */}

          {(aclMap.contract_access && aclMap.contract_access.cont_shipmentQuote && aclMap.contract_access.cont_shipmentQuote.isExist) &&
            <>
              {
                ((data.actions.contract_type === 'FOB' && userTypeId === 3) ||
                  (data.actions.contract_type === 'CIF' && userTypeId === 4) ||
                  (data.actions.contract_type === 'FOB' && userTypeId === 14 && clientType.type === 'buyer') ||
                  (data.actions.contract_type === 'CIF' && userTypeId === 14 && clientType.type === 'seller'))
                  ?
                  <>
                    {
                      (data.actions.quotation_status / 1 === 0) ? <div className="border-left">
                        <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => setshowQuotRaiseModal({ modal: true, contract_id: data.actions.contract_id, contract_no: data.actions.contract_number, contractData: data })}>
                          <span className="pr-2"><i class="fa fa-hand-paper-o" aria-hidden="true"></i></span>Raise Shipment Quotation</button>
                      </div> :
                        <div className="border-left">
                          <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " disabled>
                            <span className="pr-2"><i class="fa fa-check" aria-hidden="true"></i></span>Shipment Quotation live</button>
                        </div>
                    }
                  </> :
                  <>
                    {/* <div className="border-left">
                    <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " disabled>
                      <span className="pr-2"><i class="fa fa-times" aria-hidden="true"></i></span>Shipment Quotation NA</button>
                  </div> */}
                  </>}
            </>
          }

          {/* {(data.investigation_status / 1 === 0 && (userTypeId == 14 || userTypeId == 3 || userTypeId == 4)) ? <div className="border-left">
          <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => setshowInvestigationRemark({ modal: true, contract_id: data.actions.contract_id, contract_no: data.actions.contract_number, contractData: data })}>
            <span className="pr-2"><i class="fa fa-hand-paper-o" aria-hidden="true"></i></span>Raise Investigation</button>
        </div> : (userTypeId == 14 || userTypeId == 3 || userTypeId == 4) ? <div className="border-left">
          <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " disabled>
            <span className="pr-2"><i class="fa fa-check" aria-hidden="true"></i></span>Investigation Live</button>
        </div> : ''} */}


          {/* {(data.insurance_status / 1 === 0 && (userTypeId == 14 || userTypeId == 3 || userTypeId == 4)) ? <div className="border-left">
          <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => setshowInsuranceRemark({ modal: true, contract_id: data.actions.contract_id, contract_no: data.actions.contract_number, contractData: data })}>
            <span className="pr-2"><i class="fa fa-hand-paper-o" aria-hidden="true"></i></span>Raise Insurance</button>
        </div> : (userTypeId == 14 || userTypeId == 3 || userTypeId == 4) ?
          <div className="border-left">
            <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " disabled>
              <span className="pr-2"><i class="fa fa-check" aria-hidden="true"></i></span>Insurance Live</button>
          </div> : ''
        } */}

          {/* {(aclMap.contract_access && aclMap.contract_access.cont_details && aclMap.contract_access.cont_details.isExist && data.actions.contAddress) && */}
          {
            <div className="border-left">
              <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => setContractDetails(data.actions.contract_number)}>
                <span className="pr-2"><i class="fa fa-folder-open" aria-hidden="true"></i></span>{userTypeId === 8 ? 'Cases Details' : 'Contract Details'}</button>
            </div>}

          {(aclMap.contract_access && aclMap.contract_access.cont_viewDoc && aclMap.contract_access.cont_viewDoc.isExist && data.actions.contAddress) &&
            <div className="border-left">
              <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => {
                setContractDetails(data.actions.contract_number);
                setContractDocList(data.actions.contract_number, { direct: true })
              }}>
                <span className="pr-2"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></span>Contract Documents</button>
            </div>}

          {(aclMap.contract_access && aclMap.contract_access.cont_details && aclMap.contract_access.cont_details.isExist && data.actions.contAddress) &&
            <div className="border-left">
              <button className="text-center btn btn-primary btn-sm mt-0 ml-2 mr-2 " onClick={() => setcontractLog({ "modal": true, "contract_no": data.actions.contract_number })}>
                <span className="pr-2"><i class="fa fa-line-chart" aria-hidden="true"></i></span>{userTypeId === 8 ? 'Cases Logs' : 'Contract Logs'}</button>
            </div>}

          {(aclMap.contract_access && aclMap.contract_access.cont_duplicate && aclMap.contract_access.cont_duplicate.isExist) &&
            <div className="border-left">
              <button className="text-center btn btn-info btn-sm mt-0 ml-2 mr-2 " onClick={() => duplicateContract(data.actions.contract_id, data.actions.contract_number)}
                disabled={!quotaAvail} title={quotaAvail ? "Make a copy of this contract" : "Contract Creation Quota is Finished"}>
                <span className="pr-2"><i class="fa fa-files-o" aria-hidden="true"></i></span>Duplicate Contract</button>
            </div>}

          {(aclMap.contract_access && aclMap.contract_access.cont_purge && aclMap.contract_access.cont_purge.isExist && data.actions.status_buyer === 0 && data.actions.status_seller === 0) &&
            <div className="border-left">
              <button className="text-center btn btn-danger btn-sm mt-0 ml-2 mr-2 " onClick={() => { setcontDeleteData({ "id": data.actions.contract_id, "number": data.actions.contract_number }); setdeleteConfirm(true) }}
                title="Delete the Trade Contract along with any linked Shipment">
                <span className="pr-2"><i class="fa fa-files-o" aria-hidden="true"></i></span>Delete Contract</button>
            </div>}

        </div>
        <hr />
      </>)
  };

  function formatDataFortable(dataArray, userId) {
    let resultArray = []
    for (let i = 0; i < dataArray.length; i++) {
      let isMktPlaceContract = dataArray[i].booking_id ? true : false
      let offerType = inspectionTypeIds.includes(userTypeId) ? "offers_sent" : "offers_received"
      let tempObj = {}
      tempObj.isMktPlaceContract = isMktPlaceContract
      tempObj.contract_name = isMktPlaceContract ? dataArray[i].booking_id : dataArray[i].contract_name;
      tempObj.buyername = isMktPlaceContract ? dataArray[i].company_name : dataArray[i].buyername;
      tempObj.suppliername = isMktPlaceContract ? "NA" : dataArray[i].suppliername;
      tempObj.commodity_name = dataArray[i].commodity_name;

      tempObj.investigation_status = isMktPlaceContract ? 0 : dataArray[i].investigation_status;
      tempObj.insurance_status = isMktPlaceContract ? 0 : dataArray[i].insurance_status;

      if (isMktPlaceContract) {
        tempObj.quantity = dataArray[i][offerType][0]["offer_details_obj"]["quantity"] + ' mt';
        tempObj.price = dataArray[i][offerType][0]["offer_details_obj"]["currency"] + " " + dataArray[i][offerType][0]["offer_details_obj"]["inspection_quote"];
        tempObj.dbData = dataArray[i]
      }
      else {
        tempObj.quantity = ([3, 4, 8, 13, 14].includes(userTypeId / 1)) ? (dataArray[i].quantity + ' mt') : "CONFI";
        tempObj.price = ([3, 4, 8, 14].includes(userTypeId / 1)) ? (dataArray[i].currency.split(':')[1] + dataArray[i].price) : "CONFI";
      }
      tempObj.created_at = dataArray[i].created_at ? formatDate_Application(dataArray[i].created_at) : "NA";

      if (aclMap.contract_access && aclMap.contract_access.cont_bankerComp && aclMap.contract_access.cont_bankerComp.isExist) {
        tempObj.category = ((userId === dataArray[i].buyer_bank) ? "Buyer Bank" :
          (userId === dataArray[i].supplier_bank) ? "Seller Bank" :
            (userId !== dataArray[i].supplier_bank && userId !== dataArray[i].buyer_bank && parentId === 0) ? "Bank Master" : "NA")
        tempObj.lc = dataArray[i].lc ? dataArray[i].lc : 'N/A';
        tempObj.status = dataArray[i].lc_status;
      }
      else {
        if (isMktPlaceContract) {
          tempObj.laycan_start = formatDate_Application(dataArray[i][offerType][0]["offer_details_obj"]["laycan_start_date"]);
          tempObj.laycan_end = formatDate_Application(dataArray[i][offerType][0]["offer_details_obj"]["laycan_end_date"]);
        }
        else {
          tempObj.laycan_start = dataArray[i].laycan_start ? formatDate_Application(dataArray[i].laycan_start) : "NA";
          tempObj.laycan_end = dataArray[i].laycan_end ? formatDate_Application(dataArray[i].laycan_end) : "NA";
        }
        tempObj.status = {
          "status_buyer": dataArray[i].status_buyer,
          "status_seller": dataArray[i].status_seller,
          "edit_request": dataArray[i].edit_request,
          "payment_status": dataArray[i].payment_status,
          "userId": userId,
          "display_status": isMktPlaceContract ? "Offer Accepted" : dataArray[i].display_status
        }
      }

      tempObj.actions = {
        "status_buyer": dataArray[i].status_buyer,
        "status_seller": dataArray[i].status_seller,
        "contract_number": isMktPlaceContract ? dataArray[i].booking_id : dataArray[i].contract_number,
        "contract_id": dataArray[i].id,
        "exporter_id": dataArray[i].exporter_id,
        "importer_id": dataArray[i].importer_id,
        "supplier_bank": dataArray[i].supplier_bank,
        "lc_status": dataArray[i].lc_status,
        "contract_type": dataArray[i].contract_type,
        "quotation_status": dataArray[i].quotation_status,
        "contAddress": dataArray[i].address,
      }

      resultArray.push(tempObj)
    }
    return resultArray
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  //Filters
  function checkFilterQuery() {
    let query = queryString.parse(window.location.search);
    filterStateButtons(query.status / 1)
    setstatusFilter(query.status / 1)
  }

  const filterState = (event) => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value
    })
  };

  const handleKeyPress = (event) => {
    event.persist();
    if (event.keyCode === 13) {
      setFilterData({
        ...filterData,
        "search": searchKey
      })
    }
  };


  const filterStateButtons = (type) => {
    if (type === 3) {
      setFilterData({
        ...filterData,
        onlyPilot: false,
        onlyProgress: false,
        onlyComplete: true
      })
    } else if (type === 2) {
      setFilterData({
        ...filterData,
        onlyPilot: false,
        onlyProgress: true,
        onlyComplete: false
      })
    } else if (type === 1) {
      setFilterData({
        ...filterData,
        onlyPilot: true,
        onlyProgress: false,
        onlyComplete: false
      })
    }
  };

  const reset = () => {
    setstatusFilter(0)
    setFilterData({})
    document.getElementById("searchInput").value = ''
    document.getElementById("searchFromdate").value = ''
    document.getElementById("searchTodate").value = ''
    setsearchKey("")
    setrefresh(1)
  }

  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // functions
  function ExportTableCSV() {

    let objectAPI = {
      "currentPage": 1,
      "resultPerPage": totalRows,
      "userId": userId,
      "type_id": userTypeId,
      "activeType": clientType.type,
      "gridOnly": true
    }

    objectAPI = { ...objectAPI, ...filterData }

    call('POST', 'getContracts', objectAPI).then((result) => {
      //console.log('running getcontract api-->', result);

      const dbResult = result.contractdata
      let csvString = "Contract No.,Contract Name,Importer,Exporter,Commodity,Quantity,Price," +
        ((aclMap.contract_access && aclMap.contract_access.cont_bankerComp && aclMap.contract_access.cont_bankerComp.isExist) ? "Category,Status,LC/Check NO\r\n"
          : "Created on,Laycan Start,Laycan End,Importer Status,Exporter Status\r\n")

      for (let i = 0; i < dbResult.length; i++) {
        let offerDetails = dbResult[i].offers_received ? dbResult[i].offers_received[0]["offer_details_obj"] : {}
        let rowString = `${dbResult[i].booking_id || dbResult[i].contract_number},
      ${dbResult[i].booking_id || dbResult[i].contract_name},
      ${dbResult[i].company_name || (dbResult[i].buyername ? dbResult[i].buyername : "NA")},
      ${dbResult[i].suppliername ? dbResult[i].suppliername : "NA"},
      ${dbResult[i].commodity_name ? dbResult[i].commodity_name : "NA"},
      ${offerDetails["quantity"] || (dbResult[i].quantity ? dbResult[i].quantity : "NA")},
      ${offerDetails["inspection_quote"] || (dbResult[i].price ? dbResult[i].price : "NA")},`

        if (aclMap.contract_access && aclMap.contract_access.cont_bankerComp && aclMap.contract_access.cont_bankerComp.isExist) {
          rowString += `${((userId === dbResult[i].buyer_bank) ? "Buyer Bank" :
            (userId === dbResult[i].supplier_bank) ? "Seller Bank" :
              (userId !== dbResult[i].supplier_bank && userId !== dbResult[i].buyer_bank && parentId === 0) ? "Bank Master" : "NA")
            },
        ${dbResult[i].lc_status === 0 ? "New" :
              dbResult[i].lc_status === 1 ? "In Progress" :
                dbResult[i].lc_status === 2 ? "LC Verified" :
                  dbResult[i].lc_status === 3 ? "Completed" : "NA"
            },
        ${dbResult[i].lc ? dbResult[i].lc : "NA"} \r\n`
        } else {
          rowString += `${dbResult[i].created_at ? dbResult[i].created_at : "NA"},
      ${offerDetails["laycan_start_date"] || (dbResult[i].laycan_start ? dbResult[i].laycan_start : "NA")},
      ${offerDetails["laycan_end_date"] || (dbResult[i].laycan_end ? dbResult[i].laycan_end : "NA")},
      ${dbResult[i].status_buyer === 1 ? "Agree" : "Pending"},
      ${dbResult[i].status_seller === 1 ? "Agree" : "Pending"}\r\n`
        }


        rowString = rowString.replace(/(\n)/gm, "")
        csvString += rowString
      }
      let link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', `Trade_Contracts_${userEmail.split('@')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }).catch((e) => {
      console.log('error in getContracts creating csv', e);
    })
  }

  function duplicateContract(id, contractNo) {
    if (quotaAvail) {
      setshowLoader(true)
      call('POST', 'duplicatecontract', { "id": id, "contractNo": contractNo }).then((result) => {
        console.log("duplicatecontract result ->", result)
        setshowLoader(false)
        setContractState(false, { refresh: true })
      }).catch((error) => {
        setshowLoader(false)
        toastDisplay(error, "error");
        console.log("error occur in duplicatecontract ->", error)
      })
    } else {
      if (userPlanQuota.trades)
        toastDisplay("You have already used " + userPlanQuota.trades.limitUsed + " out of " + userPlanQuota.trades.totalLimit + userPlanQuota.trades.desc + " Quota", "warn")
      else
        toastDisplay("Plan Quota details are not available", "warn")
    }
  }

  function deleteSelectedContract() {

    setshowLoader(true)
    call('POST', 'deletecontract', contDeleteData).then((result) => {
      console.log("deletecontract result ->", result)
      setdeleteConfirm(false)
      setshowLoader(false)
      setContractState(false, { refresh: true })
    }).catch((error) => {
      setshowLoader(false)
      toastDisplay(error, "error");
      console.log("error occur in deletecontract ->", error)
    })

  }
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Body Consoles
  //  console.log("here is --==--=>>>", global)
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      <div className="container-fluid">
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="row">
          <Sidebar state={userTypeId === 13 ? 'insurercontract' : 'contract'} userTokenDetails={userTokenDetails} />

          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">

            {showLoader === true && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}

            {(!cDetailsState.modal && !inspectionWorkorderDetails.show) && <>
              <Header
                title={userTypeId === 13 ? 'Insured Contracts' : userTypeId === 8 ? 'Cases' : 'Contracts'}
                total={'Total ' + (contractCount.totalcount ? contractCount.totalcount : '') + (userTypeId === 8 ? ' Cases' : ' Contracts')}
                userTokenDetails={userTokenDetails} />
              <div className="d-md-flex mt-3 mb-3">
                <div className="filter-bg">
                  <ul>
                    <li className={"pilot-cont cursor-pointer " + (statusFilter === 1 ? "shadow" : '')} id="progessButton" onClick={() => { setstatusFilter(1); filterStateButtons(1) }}>
                      <span className="icon"><img src="assets/images/filter-icon.svg" /></span>{userTypeId === 8 ? 'New' : "In-Pilot"}
                    </li>
                    {/* {contractCount.pilotcount ? contractCount.pilotcount : ''} */}
                    <li className={"progress-cont cursor-pointer " + (statusFilter === 2 ? "shadow" : '')} id="statusFilter" onClick={() => { setstatusFilter(2); filterStateButtons(2) }}>
                      <span className="icon"><img src="assets/images/filter-icon.svg" /></span>In-progress
                    </li>
                    {/* {contractCount.progresscount ? contractCount.progresscount : ''} */}
                    <li className={"complete-cont cursor-pointer " + (statusFilter === 3 ? "shadow" : '')} id="statusFilter" onClick={() => { setstatusFilter(3); filterStateButtons(3) }}>
                      <span className="icon"><img src="assets/images/filter-icon.svg" /></span>Completed
                    </li>
                    {/* {contractCount.completecount ? contractCount.completecount : ''} */}
                  </ul>
                </div>

                <div className="ml-md-auto relative">
                  {/* {(Object.keys(filterData).length) ? <div className="reset" onClick={() => reset()}>reset</div> : ''} */}
                  <ul className="range-slider">
                    <li>
                      <input name="search" placeholder="Search" value={searchKey}
                        onChange={(event) => { setsearchKey(event.target.value) }} onKeyDown={handleKeyPress} id="searchInput" />
                      <button type="button" onClick={() => {
                        setFilterData({
                          ...filterData,
                          "search": searchKey
                        })
                      }}><i class="fas fa-search fa-lg"></i></button>
                    </li>
                    <li>
                      <label>From</label>
                      <input type="date" name="fromDate" value={filterData.fromDate} onChange={filterState} id="searchFromdate" />
                    </li>
                    <li>
                      <label>To</label>
                      <input type="date" name="toDate" value={filterData.toDate} onChange={filterState} id="searchTodate" />
                    </li>
                    <li><input type="button" value="Reset" className="btn btn-danger btn-sm" onClick={() => reset()} /></li>
                  </ul>
                </div>
              </div>
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={tableData}
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  progressComponent={<img width="100px" src="assets/images/loader.gif" alt="description" />}
                  persistTableHead
                  expandableRows
                  expandableRowsComponent={<TableExpandedComponent />}
                  expandOnRowClicked
                  highlightOnHover
                  onSort={handleSort}
                  sortServer
                  striped
                  noHeader
                  customStyles={customStyles}
                />
              </div>
              <hr />
              <div className="float-left col-md-6 ">
                <div className="col-md-12 row">
                  <div className="col-md-6">
                    <button className=" text-center btn btn-primary btn-sm m-0" onClick={() => ExportTableCSV()}>
                      <i class="fas fa-file-csv"></i> Export CSV
                    </button>
                  </div>
                </div>
              </div>
            </>}

            {/* Contract details page component start */}
            {(cDetailsState.modal && (aclMap.contract_access && aclMap.contract_access.cont_details && aclMap.contract_access.cont_details.isExist)) &&
              <ContractDetail userTokenDetails={userTokenDetails} />}
            {/* Contract details page component end  */}

            {/* Contract Create modal component start */}
            {contractState.modal && <CreateContractModal userTokenDetails={userTokenDetails} />}
            {/* Contract Create modal component end */}

            {/* {inspectionContractModal.show &&
              <InspectionContractModal data={inspectionContractModal.data} userTokenDetails={userTokenDetails}
                setrefresh={setrefresh} refresh={refresh}
                close={() => setInspectionContractModal({ show: false, data: {} })} />} */}

            {inspectionWorkorderDetails.show &&
              <WorkOrderDetail userTokenDetails={userTokenDetails} offer_data={inspectionWorkorderDetails.offer_data}
                data={inspectionWorkorderDetails.data} onClose={() => setInspectionWorkorderDetails({ show: false, data: {}, offer_data: {} })} />}

            {showQuotRaiseModal.modal &&
              <QuotRaiseModal
                userTokenDetails={userTokenDetails}
                showQuotRaiseModal={showQuotRaiseModal}
                setshowQuotRaiseModal={setshowQuotRaiseModal}
                setrefresh={setrefresh}
                refresh={refresh} />}

            {showInvestigationRemark.modal &&
              <InvestigationRemark
                userTokenDetails={userTokenDetails}
                showInvestigationRemark={showInvestigationRemark}
                setshowInvestigationRemark={setshowInvestigationRemark}
                setrefresh={setrefresh}
                refresh={refresh} />}

            {showInsuranceRemark.modal &&
              <InsuranceRemark
                userTokenDetails={userTokenDetails}
                showInsuranceRemark={showInsuranceRemark}
                setshowInsuranceRemark={setshowInsuranceRemark}
                setrefresh={setrefresh}
                refresh={refresh} />}

            {(contractLog.modal && (aclMap.contract_access && aclMap.contract_access.cont_details && aclMap.contract_access.cont_details.isExist)) &&
              <div className={"modal" + (contractLog.modal ? " show" : "")} id="changelogsModal">
                <div className="modal-dialog modal-xl border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Contract logs</h4>
                      <button type="button" className="close" onClick={() => setcontractLog({ "modal": false, "contract_no": "" })}>×</button>
                    </div>
                    <div className="modal-body calc-inner-modal">
                      {<ContractLogs
                        userTokenDetails={userTokenDetails}
                        contractNo={contractLog.contract_no} />}
                    </div>
                  </div>
                </div>
              </div>}

            {(deleteConfirm && (aclMap.contract_access && aclMap.contract_access.cont_purge && aclMap.contract_access.cont_purge.isExist)) &&
              <div className={"modal" + (deleteConfirm ? " show" : "")} id="Confirmation">
                <div className="modal-dialog modal-lg border-inner" id="parent_class">
                  <div className="modal-content">
                    <div className="modal-header primary">
                      <h4 className="modal-title text-white">Confirmation</h4>
                      <button type="button" className="close" onClick={() => { setdeleteConfirm(false) }}>×</button>
                    </div>
                    <div className="modal-body">
                      Please Confirm that you want to delete the Contract No: {contDeleteData.number},
                      this operation will delete the Trade Contract along with its Shipment contract if any.
                      This Operation is irreversible.
                    </div>
                    <div className="modal-footer primary">
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteSelectedContract()}>Delete</button>
                      <button type="button" className="btn btn-info btn-sm" onClick={() => setdeleteConfirm(false)}>Cancel</button>
                    </div>
                  </div>
                </div>
              </div>}

          </main>
        </div>
      </div>
      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  )
}

//---------------------------------------------------------------------------------------------------------------------
// Redux

const mapStateToProps = state => {
  return {
    cDetailsState: state.contractDetails,
    contractState: state.contract,
    clientType: state.clientType,
    navToggleState: state.navToggleState

  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractDetails: (id) => { dispatch(setContractDetails({ cId: id, modal: true })) },
    setContractState: (flag, data) => { dispatch(setContractState({ modal: flag, info: data })) },
    setContractDocList: (id, data) => { dispatch(setContractDocList({ modal: true, contractId: id, info: data })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractList)
