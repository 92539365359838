

import React, { useState } from 'react';
import call from '../../../service';
import toastDisplay from '../../../utils/toastNotification';


const BankReimburseForm = ({ userTokenDetails, contractData, setshowLoader, refresh, setrefresh }) => {
  //---------------------------------------------------------------------------------------------------------------------
  //States
  const [data, setData] = useState({});
  const [error, setError] = useState({});

  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Handlers

  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
    setError({ ...error, [event.target.name]: "" })
  };
  const handleFiles = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.files[0] }));
    setError({ ...error, [event.target.name]: "" })
  };

  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  function submitLCprocessData() {
    setshowLoader(true)

    let postObj = {}
    if (contractData.paymentType === "LC") {
      postObj = {
        "contract_id": contractData.id,
        "contract_no": contractData.contractNo,
        "party_a": contractData.buyer_bank,
        "party_b": contractData.exporter_id,
        "process_status": 9,
        "sendToUsers": contractData.importer_id + ',' + contractData.buyer_bank + ',' + contractData.exporter_id + ',' + contractData.supplier_bank,
        "reimburseData": JSON.stringify(data),
        "txnDoc": data.txnDoc
      }
    } else if (contractData.paymentType === "DADP") {
      postObj = {
        "contract_id": contractData.id,
        "contract_no": contractData.contractNo,
        "process_status": 7,
        "dadpType": contractData.dadpType,
        "sendToUsers": contractData.importer_id + ',' + contractData.buyer_bank + ',' + contractData.exporter_id + ',' + contractData.supplier_bank,
        "reimburseData": JSON.stringify(data),
        "txnDoc": data.txnDoc
      }
    } else if (contractData.paymentType === "TT") {
      postObj = {
        "contract_id": contractData.id,
        "contract_no": contractData.contractNo,
        "sendToUsers": contractData.importer_id + ',' + contractData.buyer_bank + ',' + contractData.exporter_id + ',' + contractData.supplier_bank,
        "reimburseData": JSON.stringify(data),
        "txnDoc": data.txnDoc
      }
    }

    console.log("postObj===>", postObj)

    let formData = new FormData();

    Object.keys(postObj).forEach((key) => {
      formData.append(key, postObj[key]);
    })

    let route = contractData.paymentType === "LC" ? 'updatelcprocess' : contractData.paymentType === "DADP" ? 'updatedpprocess' : contractData.paymentType === "TT" ? 'updatettprocess' : ""
    call('POST', route, formData).then(async (result) => {
      setshowLoader(false)
      setrefresh(refresh + 1)
      toastDisplay("details saved", "success")
    }).catch((error) => {
      setshowLoader(false)
      console.log("error occur in updatelcprocess ->", error)
      toastDisplay(error, "error")
    })

  }

  //---------------------------------------------------------------------------------------------------------------------

  return (<>
    <div className="col-md-12 mt-3 row">
      <div className="col-md-6 mt-2">
        <label className="col-md-12">Transaction ID</label>
        <input type="text" name="txnId" value={data.txnId} className="form-control" onChange={handleChange} required />
      </div>
      <div className="col-md-6 mt-2">
        <label className="col-md-12">Transaction Amount</label>
        <input type="text" name="txnAmount" value={data.txnAmount} className="form-control" onChange={handleChange} required />
      </div>
      <div className="col-md-6 mt-2">
        <label className="col-md-12">Transaction Date/Time</label>
        <input type="datetime-local" name="txnDate" value={data.txnDate} className="form-control" onChange={handleChange} required />
      </div>

      <div className="col-md-12 mt-5 pb-3">
        <ul className="other-documents">
          <li>
            <span className="file-icon"><img src="../assets/images/onbording/file-icon.svg" /> </span>
          </li>
          <li>
            <h3 className={"custum-control" + (error.txnDoc ? " border-danger" : "")} style={{ "min-width": "205px" }}>TXN Document {astrix} </h3>
          </li>
          <li>
            <div className="file-browse col-md-12">
              <button className="btn btn-primary btn-sm col-md-6">Upload Document</button>
              <input className="col-md-12" type="file" accept=".png,.jpg,.pdf" name="txnDoc" onChange={handleFiles} />
            </div>
          </li>
          {data.txnDoc &&
            <li>
              <div className="form-group mb-0"><div className="file-name">{`${data.txnDoc.name}`}</div></div>
            </li>}
          {error.txnDoc ? <div class="text-danger error-contract">{error.txnDoc}</div> : ''}
        </ul>
      </div>

      <div className="col-md-12 mt-5 text-center">
        <button type="button" className="btn btn-success btn-sm" onClick={() => submitLCprocessData()}>Submit Details</button>
      </div>
    </div>

  </>)
}


export default BankReimburseForm
