import jsPDF from "jspdf";
import cryptoJs from "crypto-js";
import toastDisplay from "./toastNotification";
import call from "../service";
import natural from 'natural'

let secretKey = "5N-LzDy;LcKYHYgp^d]2Q59,}rR=|V&=3/Me,ugIuw]92<7cX@uEmE):+%|/#mA"

export const is_valid = (data) => {
  if (data && data.length) return true
  return false
}

// export const validate_number_field = str => {
//   return str.replace(/[^0-9\.]/g, "").replace(/(?<=\..*)\./g, '')
// }

export const validate_text_field = str => {
  return str.replace(/^\s?/, '')
}

const A4_PAPER_DIMENSIONS = {
  width: 210,
  height: 297,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

const imageDimensionsOnA4 = (dimensions) => {
  const isLandscapeImage = dimensions.width >= dimensions.height;
  // If the image is in landscape, the full width of A4 is used.
  if (isLandscapeImage) {
    return {
      width: A4_PAPER_DIMENSIONS.width,
      height:
        A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
    };
  }
  // If the image is in portrait and the full height of A4 would skew
  // the image ratio, we scale the image dimensions.
  const imageRatio = dimensions.width / dimensions.height;
  if (imageRatio > A4_PAPER_RATIO) {
    const imageScaleFactor =
      (A4_PAPER_RATIO * dimensions.height) / dimensions.width;
    const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;
    return {
      height: scaledImageHeight,
      width: scaledImageHeight * imageRatio,
    };
  }
  // The full height of A4 can be used without skewing the image ratio.
  return {
    width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
    height: A4_PAPER_DIMENSIONS.height,
  };
};

export async function convertImageToPdf(imgDataUrl, imgFileName) {
  return new Promise((resolve, reject) => {
    try {
      let pdfFileName = imgFileName.split(".")[0] + ".pdf"
      let image = new Image();
      image.src = imgDataUrl;
      image.onload = function () {
        const doc = new jsPDF();
        doc.deletePage(1);
        const imageDimensions = imageDimensionsOnA4({
          width: image.width,
          height: image.height,
        });
        doc.addPage();
        doc.addImage(
          image.src,
          // Images are vertically and horizontally centered on the page.
          (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
          (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
          imageDimensions.width,
          imageDimensions.height,
          "",
          "FAST"
        );
        // To view pdf in new tab
        // const test = doc.output('bloburl')
        // window.open(test, "_blank");
        const pdfBlob = doc.output('blob');
        resolve(
          {
            "file": new File([pdfBlob], pdfFileName, { type: pdfBlob["type"], lastModified: new Date() }),
            "dataUrl": doc.output('datauristring', { filename: pdfFileName })
          }
        );
      };
    }
    catch (error) {
      reject(false)
    }
  })
}

export const GetCache = (key) => {
  return JSON.parse(localStorage.getItem(key)) || {}
}

export const SetCache = (key, data_obj) => {
  localStorage.setItem(key, JSON.stringify({ ...GetCache(key), ...data_obj }))
}

export const ClearCache = (key) => {
  localStorage.removeItem(key)
}

export const encryptData = (strData) => {
  return cryptoJs.AES.encrypt(strData, secretKey).toString()
}

export const decryptData = (encStrData) => {
  let bytes = cryptoJs.AES.decrypt(encStrData, secretKey)
  return bytes.toString(cryptoJs.enc.Utf8)
}

export const cargoTypeDD = [
  { "name": "Break Bulk" },
  { "name": "Bulk Cargo" },
  { "name": "Oversize Load" },
  { "name": "Liquid Cargo" },
  { "name": "Gas" },
  { "name": "Wheeled Carg" }
]

export const containerTypeDD = [
  { "name": "20 Standard" },
  { "name": "40 Standard" },
  { "name": "40 High Cube" },
  { "name": "45 High Cube" },
  { "name": "20 Open Top" },
  { "name": "40 Open Top" },
  { "name": "20 Flatrack" },
  { "name": "40 Flatrack" },
  { "name": "20 Refrigerate" }
]

export const vesselTypeDD = [
  { "name": "General Cargo" },
  { "name": "Bulk Carriers" },
  { "name": "Containership" },
  { "name": "Tanker Market" },
  { "name": "Specialized" }
]

export function calcHaversineDistance(lat1, lon1, lat2, lon2) {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var lat1 = toRad(lat1);
  var lat2 = toRad(lat2);

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

function toRad(Value) {
  return Value * Math.PI / 180;
}

export const most_used_currencies = [
  { id: 47, name: "Rupees", code: "INR", symbol: "₹" },
  { id: 2, name: "Dollars", code: "USD", symbol: "$" },
  { id: 11, name: "Euro", code: "EUR", symbol: "€" },
  { id: 19, name: "Pounds", code: "GBP", symbol: "£" },
  { id: 44, name: "Dollars", code: "HKD", symbol: "$" },
  { id: 22, name: "Dollars", code: "CAD", symbol: "$" },
  { id: 6, name: "Dollars", code: "AUD", symbol: "$" },
  { id: 63, name: "Switzerland Francs", code: "CHF", symbol: "CHF" },
  { id: 95, name: "Rand", code: "ZAR", symbol: "R" },
  { id: 74, name: "Dollars", code: "NZD", symbol: "$" }
]

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  // console.log("dataURI==============", dataURI);
  if (!dataURI.includes("base64")) {
    dataURI = "data:application/pdf;base64," + dataURI
  }
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}


export const handleFileCommonFunction = (event, data, errors, setData, setErrors, setshowLoader) => {
  event.persist()
  if (!event.target.files.length) {
    return null
  }
  else {
    let file_type = event.target.files[0]["type"].toLowerCase()
    if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")) || (file_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")) || (file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")))) {
      setErrors({ ...errors, [event.target.name]: "Files with pdf, png, jpeg, docx & xlsx extension are allowed" })
      return
    }
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = async (e) => {
      let fileObj = event.target.files[0]
      let fileDataUrl = e.target.result
      if (file_type.includes("png") || file_type.includes("jpeg")) {
        let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
        console.log("pdfconversionresp", response);
        fileObj = response["file"]
        fileDataUrl = response['dataUrl']
        toastDisplay("File converted into pdf format", "success")
      }
      if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || file_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
        try {
          setshowLoader(true)
          let formData = new FormData()
          formData.append("file", fileObj)
          if (file_type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
            formData.append("docType", "docx")
          }
          if (file_type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            formData.append("docType", "xlsx")
          }
          let apiResp = await call('POST', "docToPdf", formData)
          let blob = dataURItoBlob(apiResp);
          fileObj = new File([blob], fileObj.name + "converted.pdf", { type: "application/pdf" });
          fileDataUrl = apiResp
          toastDisplay("File converted into pdf format", "success")
        } catch (error) {
          return toastDisplay("failed to convert file", "error")
        }
        setshowLoader(false)
      }
      fileObj["filebase64"] = fileDataUrl
      setData({ ...data, [event.target.name]: fileObj })
      setErrors({ ...errors, [event.target.name]: "" });
    }
  }
}

export const compareStrings = (string1, string2) => {
  if (string1 && string2) {
    string1 = string1.toLowerCase().replace(/\s/g, '')
    string2 = string2.toLowerCase().replace(/\s/g, '')
    if (string1.includes(string2) || string2.includes(string1)) {
      return 100
    }
    const diceCoefficient = natural.DiceCoefficient(string1, string2);
    const matchingPercentage = diceCoefficient * 100;
    return matchingPercentage.toFixed(2);
  }
  return 0
}
