import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel'
import { getUserDataFromCookie } from '../../utils/cookieHelper';


const Land = () => {

	const [index, setIndex] = useState(0);
	const [direction, setDirection] = useState(null);
	var [login, setlogin] = useState(false);
	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
		setDirection(e);
	};

	//---------------------------------------------------------------------------------------------------------------------
	// UseEffects
	useEffect(() => {
		//------------------------------------------------------------------
		//User details from cookie
		let userDetails = getUserDataFromCookie()
		let userTypeId = userDetails.type_id ? userDetails.type_id : null
		let userToken = userDetails.token ? userDetails.token : null
		let status = userDetails.status ? userDetails.status : null
		//------------------------------------------------------------------

		//------------------------------------------------------------------
		//Redirect if user already logged in
		if ((userToken !== undefined && userToken !== null) && (userTypeId !== undefined && userTypeId !== null) && (status !== undefined && status !== null)) {
			setlogin(true)
		} else {
			setlogin(false)
		}

		//------------------------------------------------------------------

	}, []);
	//---------------------------------------------------------------------------------------------------------------------

	return (
		<div className="land-page-outer">
			<header>
				<nav className="navbar navbar-expand-md green-dark navbar-dark">
					<div className="container" >
						<a className="navbar-brand " href="#"><img height="50px" src="../assets/images/logos/logo_1.png" alt="logo" /> </a>
						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse" id="collapsibleNavbar">
							<ul className="navbar-nav m-0">
								<li className="nav-item">
									<a className="nav-link" href="#about">About</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#our-team">Our Team</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#Partner">Partner</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#technology">Technology</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#What-We-Offer">What We Offer</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="/showplans">See Plans</a>
								</li>
							</ul>
							<ul className="navbar-nav login-sec m-0">
								<li className="nav-item">
									<a className="nav-link" href={login ? "/dashboard" : "/login"}>{login ? "Dashboard" : "Login"}</a>
								</li>
								{!login &&
									<li className="nav-item">
										<a className="nav-link" href={"/registration"}>Sign Up</a>
									</li>}
							</ul>
						</div>
					</div>
				</nav>
			</header>
			<div className="banner-text  ">
				<div className="container">
					<div className="row">
						<div className="col-lg-5 col-md-6 col-sm-6 col-xs-12"><h2>Trading the trust</h2>
							<p>Trusted trading platform based on Blockchain giving solution to all your needs.</p></div>
						<div className="col-lg-7 col-md-6 col-sm-6 col-xs-12">
							<img src="assets/images/land/warehouse.png" />
						</div>
					</div>

				</div>
			</div>
			<section id="about">
				<div className="container">
					<div className="row">
						<div className="col-lg-5 col-md-6">
							<div className="about-img">
								<img src="../assets/images/land/about-img.png" alt="" />
							</div>
						</div>
						<div className="col-lg-7 col-md-6">
							<div className="about-content">
								<h2 className="mb-3">About </h2>
								<ul>
									<li>
										<p>M/s TradeReboot Technologies Limited is represented by Mr. Dheeraj Kumar Khandelwal and Mrs. Sonia D. Khandelwal have an experience of more than 10 years in the commodity business. They have conceptualised the Digital Platform in trading commodities and intent to have same by developing a Blockchain based digital platform in Abu Dhabi. The company, once incorporated will invest in Blockchain Trading Platform in commodities like: Metals, Minerals, Agriculture and Energy related products</p>
									</li>
									<li>
										<p>As of now, this will be the first company having an established trading platform. Initially, this will be marketed to all product based companies which will use this platform to trade commodities. At a later stage, we will market this platform and we will charge for the usage of this platform. Once it will be marketed globally, then we will make this trading platform available for all the trading companies world-wide where the buyer and seller will be available on this common platform and can trade more efficiently.</p>
									</li>
									<li>
										<p>This platform will benefit trade efficiently. When the trading is done in the usual way, LC payment usually takes up to 15 days, whereas on this platform, the payment can be done almost immediately. This platform will not only be time efficient but also money efficient. This platform will bring transparency between buyers, sellers, third parties and relevant authorities.</p>
									</li>
									<li>
										<p>Our business idea was conceived last year and we were working on it since then by availing all necessary data and our technical team has started working on the project since June 2019.</p>

									</li>
									<li>
										<p>This company will not conduct any financial activities.</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="About Promoters " id="our-team">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-heading">
								<h2> Our Team</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="owl-carousel owl-theme ">
							<Carousel activeIndex={index} direction={direction} onSelect={handleSelect} interval={4000} fade={false} >
								<Carousel.Item>
									<div className="item">
										<div className="challeng-box top40">
											<div className=" blockList text-center">
												<div className="col-md-12 col-sm-12 center m-auto  text-center"> <img src="../assets/images/land/dummy.png" alt="Decentralization" /></div>
												<div className="col-md-12 col-sm-12">
													<div className="left-text  col-sm-12">
														<div className="accordion-text">
															<h5>Mr. Dheeraj Kumar Khandelwal
															</h5>
															<h6><strong>Promoter</strong></h6>
															<p> Promoters of the company are Mr. Dheeraj Kumar Khandelwal and Mrs. Sonia D. Khandelwal, who have an experience of more than 20 years in the trading business of industrial commodities like: Metals and Minerals. They have an experience in commodities procurement via brokers and selling the commodities in the International market. They have clients based in the Middle East, Mediterranean, Europe and South East Asia. They have also managed logistics, treasury, FOREX and Accounts functions and have an expertise skill in generating returns by investing excess funds. </p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Carousel.Item>
								<Carousel.Item>
									<div className="item">
										<div className="challeng-box top40">
											<div className=" blockList text-center">
												<div className="col-md-12 col-sm-12 center"> <img src="../assets/images/land/dummy.png" alt="Decentralization" /></div>
												<div className="col-md-12 col-sm-12">
													<div className="left-text  col-sm-12">

														<div className="accordion-text">
															<h5>Mr. Chirag Agarwal
															</h5>
															<h6><strong>Director</strong></h6>
															<p>Chirag is an enterprising and number-crunching professional who pursued Chartered Accountancy after completing his commerce graduation. He embarked on his entrepreneurial journey of setting up and giving direction to Accounting and VAT advisory company – Earningo Accounting & Tax Consultancy - after 7+ years of extensive work experience in India & Dubai. Under his dynamic leadership, the two-year-old company has seen appreciable expansion in terms of clients and today it has 50+ highly satisfied clients.
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Carousel.Item>
								<Carousel.Item>
									<div className="item">
										<div className="challeng-box top40">
											<div className=" blockList text-center">
												<div className="col-md-12 col-sm-12 center"> <img src="../assets/images/land/dummy.png" alt="Decentralization" /></div>
												<div className="col-md-12 col-sm-12">
													<div className="left-text  col-sm-12">
														<div className="accordion-text">
															<h5>Mr. Vikram Pandya
															</h5>
															<h6><strong>Proposed Independent Director</strong></h6>
															<p>Vikram Pandya is Director FinTech at SP Jain School of Global Management where he has designed Asia’s first interactive classroom led FinTech program featuring Blockchain, API Banking, AI/ML and IoT labs. He has authored several white papers and articles on FinTech domain. He has an extensive experience in banking, financial services, Fintech, consultancy and training domain. He is also associated as a mentor with various FinTech startups and Fintech focused funds across the globe. He is Fintech ambassador for Maharashtra Govt.'s Uday Fintech platform.He is also heading research arm of Fintech VC Fund Varanium. He has been a successful banker for more than a decade where he has made key contributions in Financial & Investor Strategy, Business Intelligence, Balance-sheet Management and Business Development & Technology Solutions. He has been a visiting faculty at various prestigious global institutes.
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Carousel.Item>
								<Carousel.Item>
									<div className="item">
										<div className="challeng-box top40">
											<div className=" blockList text-center">
												<div className="col-md-12 col-sm-12 center"> <img src="../assets/images/land/ravi.jpeg" alt="Decentralization" /></div>
												<div className="col-md-12 col-sm-12">
													<div className="left-text  col-sm-12">
														<div className="accordion-text">
															<h5>Dr. Ravi Prakash Chamria
															</h5>
															<h6><strong>Blockchain Advisor</strong></h6>
															<p>Dr. Ravi Prakash Chamria is CEO of Sofocle Technologies, an Enterprise Blockchain company. He has an experience of 19+ years in IT consulting spanning across Fintech, InsureTech, Supply Chain and e-Commerce. He was CTO of Biz2Credit for 6 years, a NYC based Fintech Company. An executive MBA from IIM, Lucknow, he is a prolific speaker on emerging technologies like Blockchain, IoT and AI/ML. He is passionate about Blockchain, Supply Chain Management, Digital Lending, Digital Payments, AI/ML, IoT and specializes in Strategic Management, Technology Innovation & Product Management.
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Carousel.Item>
							</Carousel>
						</div>
					</div>
				</div>
			</section>
			<section className="Partner" id="Partner">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-heading">
								<h2> Partner</h2>
								<p>Sofocle is one of the leading Enterprise Blockchain and Digital Transformation companies in the world. With a team of 60 experts in Blockchain and emerging technologies, Sofocle has executed various platforms, applications and solutions for companies and governments in the areas of Supply Chain, Financial services, Insurance, Governance, etc.
								</p>
							</div>
						</div>
					</div>
					<h4>In addition to solutions like</h4>
					<div className="row">
						<div className="col-md-5">
							<ul>
								<li>ProlitusX (Cryptocurrency Exchange),</li>
								<li>ProlitusTSP (Token Sales Platform),</li>
								<li>Certum (Supply Chain Traceability solution),</li>
								<li>Certiza (Blockchain based Digital Records Management) </li>
								<li>Insurra (Automated Claim Settlement Framework) </li>
							</ul>
						</div>
						<div className="col-md-7 mt-4">
							<p>Sofocle has developed Zeeve as an Auto-Deployment platform for managing large Blockchain networks that is being used by more than 1000 developers and companies worldwide. Zeeve helps deploy Blockchain chain in less than 10 minutes with no programming (saving 99.7% in DevOps) with additional benefits of optimized network, graphical dashboard etc.</p>
							<p> Sofocle has forged strong partnerships with large ecosystem players like Intel Corporation, Microsoft, Oracle, E&Y, Deloitte, ThingWorx, Sutherland Global, Infomerica, Enterprise Ethereum Alliance, IAMAI, IoT Alliance, Hyper Ledger Foundation etc. Sofocle has offices in Dubai, Delhi and Mumbai offering hybrid project management approach combining the best of local project management and remote technology development.</p>
						</div>
					</div>
				</div>
			</section>
			<section className="technology" id="technology">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-heading">
								<h2> Technology</h2>

							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8  wow fadeInUp">
							<h2 className="bottom30 page-heading">What is blockchain?
							</h2>
							<p>A Blockchain is, in the simplest of terms, a time-stamped series of immutable record of data that is managed by cluster of computers not owned by any single entity. Each of these blocks of data (i.e. block) are secured and bound to each other using cryptographic principles (i.e. chain).</p>
							<div className="mt-4 text-align">
								<h4>The reason why the Blockchain has gained so much admiration is that:
								</h4>
								<ul className="li-desc">
									<li>It is not owned by a single entity, hence it is decentralized</li>
									<li>The data is cryptographically stored inside</li>
									<li>The blockchain is immutable, so no one can tamper with the data that is inside the blockchain</li>
									<li>The blockchain is transparent so one can track the data if they want to.</li>
								</ul>
							</div>
						</div>
						<div className="col-md-4 col-sm-4 zoomIn animated animated animated">
							<img className="img-responsive pull-right" src="../assets/images/land/blockchain.png" alt="Permissioned-blockchain" width="270px" />
						</div>

					</div>
					<div className="mt-4 text-align">
						<h4>The Three Pillars of Blockchain Technology
						</h4>
						<p>The three main properties of Blockchain Technology which has helped it gain widespread acclaim are as follows:
						</p>
					</div>
					<div className="row">
						<div className="col-md-3 col-sm-12 text-left">
							<img alt="img" src="../assets/images/land/kyc.png" className="mb-2" />
							<p className="h5">Decentralization</p>
							<p className="ln">It is a centralized entity which stores all the data and needs to interact solely with this entity to get whatever information is required.</p>
						</div>
						<div className="col-md-3 col-sm-12 text-left">
							<img alt="img" src="../assets/images/land/kyc.png" className="mb-2" />
							<p className="h5">Immutability </p>
							<p className="ln">In the context of the blockchain, it means that once something has been entered into the blockchain, it cannot be tampered with.
							</p>
						</div>
						<div className="col-md-6 col-sm-12 text-left">
							<img alt="img" src="../assets/images/land/kyc.png" className="mb-2" />
							<p className="h5">Transparency </p>
							<p className="ln">In this technology Privacy and Transparency can effectively coexist. In a blockchain, the identity of a user is concealed behind powerful cryptography, meaning that linking public addresses to individual users is particularly difficult to achieve. The transparency of a blockchain stems from the fact that the holdings and transactions of each public address are open to viewing.
							</p>
						</div>
					</div>
					<div className="mt-4">
						<h4>Benefits of Blockchain
						</h4>
					</div>
					<div className="row blockchain-data">
						<div className="col-md-4">
							<p className="green-text">For Supplier</p>
							<ul>
								<li>Supplier Onboarding</li>
								<li>Supplier eKYC</li>
								<li>Supplier Credit Assessment</li>
								<li>Invoice management by supplier ERP integration</li>
							</ul>

						</div>
						<div className="col-md-4">
							<p className="green-text">For Buyer</p>
							<ul>
								<li>Buyer Onboarding</li>
								<li>Buyer eKYC</li>
								<li>Buyer Credit Assessment</li>
								<li>Invoice management by buyer ERP integration</li>
							</ul>

						</div>
						<div className="col-md-4">
							<p className="green-text">For Lenders</p>
							<ul>
								<li>Filter Approved Invoices based on Credit Policy</li>
								<li>Discounting Offer Workflow</li>
								<li>Disbursal using Smart Contract</li>
								<li>Repayment using Smart Contract</li>
							</ul>

						</div>
					</div>

				</div>
			</section>
			<section id="What-We-Offer" className="What-We-Offer">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-heading">
								<h2> What We Offer</h2>
								<p className="mb-0">Tracking Entire Life-cycle of each Transaction on the portal.</p>
								<p className="mt-0">One Stop Trading of various commodities on the Portal.</p>
							</div>
						</div>
					</div>
					<div className="row ">
						<div className="text-center col-md-12 mb-3">
							<h4>We are creating unified platform for all parties of trade, viz.</h4>
						</div>
						<div className="offer-list">
							<div className="icon-box">
								<div className="icon"><i className="fa fa-photo"></i></div>
								<h4 className="title"><a >Manufacturer</a></h4>
							</div>
							<div className="icon-box">
								<div className="icon"><i className="fa fa-photo"></i></div>
								<h4 className="title"><a >Buyer</a></h4>
							</div>
							<div className="icon-box">
								<div className="icon"><i className="fa fa-user"></i></div>
								<h4 className="title"><a >Sellers</a></h4>
							</div>
							<div className="icon-box">
								<div className="icon"><i className="fa fa-photo"></i></div>
								<h4 className="title"><a >Intermediaries </a></h4>
							</div>
							<div className="icon-box">
								<div className="icon"><i className="fa fa-money"></i></div>
								<h4 className="title"><a >Payments</a></h4>
							</div>
						</div>
					</div>
				</div>
				<div className="feature white-cont bottom30">
					<div className="container">
						<div className="row">
							<div className="left-part col-md-6">
								<h2 className="bottom20">Key Features</h2>
								<p>Transaction authentication by cryptography</p>
								<p>Logs: replicated, integrated and synchronized</p>
								<p>Benefits the Trade Partners</p>
								<p>Benefits the Financial Institutions</p>
								<p>Seller will pay on Platform</p>
								<p>Buyer will confirm Shipment</p>
								<p>Shipping Company will display shipment status</p>
								<p>Duties payable and Deposition</p>
								<p>Raise Claims</p>
								<p>Mark receipt and honour payments</p>
							</div>
							<div className="right-part col-md-6">
								<div className="right-part   listing-serv">
									<div className="left-part col-md-12">
										<h2 className="bottom20">Product Features</h2>
										<p>One Time License Fee</p>
										<p>Upgrade Feature in-built</p>
										<p>Covers Annual Maintenance</p>
										<p>Cross border transaction</p>
										<p>Replacement of other packages currently in use like SAP etc.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</section>
			<footer id="footer">
				<div className="container text-left">

					<div className="row align-items-center ">
						<div className="col-md-4 text-left">

							<a className="navbar-brand " href="#"><img height="50" src="../assets/images/logos/logo_1.png" alt="logo" /> </a>
							<div className="copyright text-left">
								© Copyright <label>TradeReboot</label>. All Rights Reserved
							</div>
						</div>

						<div className="col-md-4">

							<ul className="navbar-nav ml-auto">
								<li className="nav-item">
									<a className="nav-link" href="#about">About</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#our-team">Our Team</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#Partner">Partner</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#technology">Technology</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#What-We-Offer">What We Offer</a>
								</li>

							</ul>
						</div>
						<div className="col-md-3 text-left ml-auto">

							<p className="mb-1"><strong><i className="fa fa-map-marker"></i> Address :</strong></p>
							<p className="mb-1">
								24th Floor, Al Sila Tower, <br /> Abu Dhabi Global Market, <br /> Al Maryah Island, Abu Dhabu, <br /> United Arab Emirates</p>
							<p className="mb-1"><strong><i className="fa fa-phone"></i> 02 694 8537</strong></p>
						</div>
					</div>
				</div>
			</footer>
		</div>
	)
}

export default Land;
