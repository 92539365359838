import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import Header from '../partial/header';
import { setContractDetailLogi, setLaytimesheet, setViewTrack, setContractDocList, setInvoiceDetail } from '../../store/actions/action';
import { ToastContainer } from 'react-toastify';
import Laytime from '../laytimeSheets/laytime';
import Tracking from '../contractGrid/tracking';
import ReactCountryFlag from "react-country-flag";
import { formatDate_Application } from '../../utils/dateFormaters';
import ContractDoc from '../contractDocument/contractDoc';
import ShipmentDetailAccord from '../contractDetails/shipmentDetailAccord'
import LaytimeSheetTrail from '../laytimeSheets/laytimeSheetTrail';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import Invoice from '../invoice/invoice';
import { QRCode } from 'react-qrcode-logo';
import BillOfLading from './billOfLading.js'
import avatarUrl from '../../utils/makeAvatarUrl';
import ContainerComp from './containersComp.js';
import toastDisplay from '../../utils/toastNotification';

const ContractDetailShipment = ({
  userTokenDetails, assignContainer, setassignContainer, assignVessel, setassignVessel, setrefresh, refresh, setContractDetailLogi, contractDetailLogi,
  laytimesheet, setViewTrack, ViewTrack, setLaytimesheet,
  contractDocList, setContractDocList, setInvoiceDetail,
  InvoiceDetail, dispatch }) => {

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [data, setData] = useState({
    vesselMaster: assignVessel.data.vessel_master_id ? assignVessel.data.vessel_master_id : "",
    carrierAgent: assignVessel.data.carrier_agent_id ? assignVessel.data.carrier_agent_id : "",
    vesselIMO: assignVessel.data.ship_imo_no ? assignVessel.data.ship_imo_no : "",
  });
  const contractNo = contractDetailLogi.info.contract_number
  const [dbData, setDbData] = useState([])
  const [showLoader, setshowLoader] = useState(false);
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("Summary");
  const [planData, setplanData] = useState({});
  const [containerDetails, setContainerDetails] = useState({});
  const [userShips, setuserShips] = useState([]);
  const [selectedShip, setselectedShip] = useState({});
  const [vesselMasters, setvesselMasters] = useState([]);
  const [carrierAgents, setcarrierAgents] = useState([]);

  // console.log("assignVesselllllllllllllllllllllllllllll", assignVessel, dbData);

  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    call('POST', 'getUsersListing', { "searchUserType": 7, "searchKey": '', "network": "innetwork", "userAvatars": false }).then((result) => {
      console.log("result of api in getUsersListing vesselmaster-->", result)
      setvesselMasters(result)
    }).catch((e) => {
      console.log('error in getUsersListing vesselmaster', e);
    })

    call('POST', 'getUsersListing', { "searchUserType": 18, "searchKey": '', "network": "innetwork", "userAvatars": false }).then((result) => {
      console.log("result of api in getUsersListing carrier agents-->", result)
      setcarrierAgents(result)
    }).catch((e) => {
      console.log('error in getUsersListing carrier agents', e);
    })

  }, [])
  useEffect(() => {

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getShipContracts', { contractNo: contractDetailLogi.info.contract_number, contractOnly: true }).then(async (result) => {
      console.log('getShipContracts  ContractDetailShipment-->', result);
      setDbData(result)
      setData({ ...data, vesselMaster: result?.vessel_master_id, carrierAgent: result?.carrier_agent_id })
      setshowLoader(false)

      call('get', `v1/contract/plan?contractNumber=${result.contract_number}`).then(async (result) => {
        // console.log("getcontractplan ->", result)
        setplanData(result.planDetails.features)
        setshowLoader(false)
      }).catch((error) => {
        console.log("error occur in getcontractplan ->", error)
      })

    }).catch((e) => {
      setshowLoader(false)
      console.log("error in fetch shipment cont details==>", e)
    })
    //------------------------------------------------------------------

  }, [InvoiceDetail.info.refresh, contractDetailLogi.info.contract_number]);

  useEffect(() => {

    setshowLoader(true)
    call('get', 'getuserships').then((result) => {
      console.log("user Ships=>", result)
      setuserShips(result)

      for (let i = 0; i < result.length; i++) {
        if (result[i].imo_no / 1 === dbData.ship_imo_no / 1) {
          console.log('iiinnnnn')
          setselectedShip(result[i])
        }
      }
      setshowLoader(false)
    }).catch((e) => {
      console.log('error in getuserships', e);
    })

  }, [dbData])

  //-----------------------------------------------------------------------------------------------------------------------------
  //Handlers
  const handleChange = (event) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
  };

  const handleShipSelect = (event) => {
    event.persist();
    console.log("event.target.value=>", event.target.value)
    let tempdata = JSON.parse(event.target.value)
    setData(data => ({ ...data, ["vesselIMO"]: tempdata.imo_no }));
    setselectedShip(tempdata)
  };

  console.log('selectedShipselectedShipselectedShip => ', selectedShip)

  //--------------------------------------------------------------------------------------------------------------------------------
  // Submit handler
  function saveContainerInfo() {

    setshowLoader(true)

    call('POST', 'updatecontainerincontract', { contractNo: dbData.contract_no, containerDetails: containerDetails }).then((result) => {
      if (result) {
        setshowLoader(false)
        setassignContainer({ modal: false, data: {} })
        toastDisplay(" Succesfully", "success")
        setrefresh(refresh + 1)
      }
    }).catch(e => {
      setshowLoader(false)
      toastDisplay(e, "error")
      //setAddShip(false, {})
    })

  }

  function saveVesselInfo() {

    setshowLoader(true)

    console.log('dataaaaa ===> ', data);
    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    formData.append('contract_no', dbData.contract_no);

    for (var pair of formData.entries()) {
      console.log('formdataaaaaaaa ==>', pair[0] + ', ' + pair[1]);
    }


    call('POST', 'updatevesselincontract', formData).then((result) => {
      if (result) {
        setshowLoader(false)
        setassignVessel({ modal: false, data: {} })
        toastDisplay("Vessel and Users added Succesfully", "success")
        setrefresh(refresh + 1)
      }
    }).catch(e => {
      setshowLoader(false)
      toastDisplay(e, "error")
      //setAddShip(false, {})
    })

  }

  //-------------------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {((aclMap.shipment_access && aclMap.shipment_access.shipment_laytime && aclMap.shipment_access.shipment_laytime.isExist) && laytimesheet.state) &&
        <Laytime userTokenDetails={userTokenDetails} />}

      {((aclMap.shipment_access && aclMap.shipment_access.shipment_trackMonitor && aclMap.shipment_access.shipment_trackMonitor.isExist) && ViewTrack.modal) &&
        <Tracking userTokenDetails={userTokenDetails} />}

      {((aclMap.shipment_access && aclMap.shipment_access.shipment_viewDoc && aclMap.shipment_access.shipment_viewDoc.isExist) && contractDocList.modal) &&
        <ContractDoc userTokenDetails={userTokenDetails} planData={planData} />}

      {(InvoiceDetail.modal) &&
        <Invoice userTokenDetails={userTokenDetails} />}

      {(!laytimesheet.state && !ViewTrack.modal && !contractDocList.modal && !InvoiceDetail.modal) && <>
        <Header
          title={'Contract Details'}
          userTokenDetails={userTokenDetails} />
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
        <div className="d-flex">
          {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
          <ul className="contract-left">
            <li>
              <h2>Contract #{dbData.contract_no ? dbData.contract_no + " : " + tabName : '--'}</h2>
              <p>{formatDate_Application(dbData.created_at)}</p>
            </li>
          </ul>
          <div className="ml-auto pr-3">
            <ul className="contract-top d-flex flex-md-row align-items-center">
              {(aclMap.shipment_access && aclMap.shipment_access.shipment_viewDoc && aclMap.shipment_access.shipment_viewDoc.isExist) &&
                <li>
                  <button type="button" className="btn btn-info btn-sm" onClick={() => { setContractDocList(dbData.contract_number); setViewTrack(false, {}) }}>
                    List of Documnets
                  </button>
                </li>}

              {(aclMap.shipment_access && aclMap.shipment_access.shipment_laytime && aclMap.shipment_access.shipment_laytime.isExist) &&
                <li>
                  <button className="btn btn-info btn-sm" onClick={() => setLaytimesheet(dbData.contract_no)}>
                    Laytime Sheet
                  </button>
                </li>}


              {((aclMap.shipment_access && aclMap.shipment_access.shipment_genInvoice && aclMap.shipment_access.shipment_genInvoice.isExist) ||
                (aclMap.shipment_access && aclMap.shipment_access.shipment_uploadInvoice && aclMap.shipment_access.shipment_uploadInvoice.isExist) ||
                (aclMap.shipment_access && aclMap.shipment_access.shipment_getInvoice && aclMap.shipment_access.shipment_getInvoice.isExist)) && <>
                  <DropdownButton id="dropdownInvoice" title="Invoice" variant="primary" size="sm">
                    {(dbData.ship_invoice_status === 0 && dbData.ship_owner_id === userId) ? <>
                      {(aclMap.shipment_access && aclMap.shipment_access.shipment_genInvoice && aclMap.shipment_access.shipment_genInvoice.isExist) &&
                        <Dropdown.Item href="javascript:void(0)" onClick={() => {
                          setInvoiceDetail(true, { "contractNo": contractNo, "userType": userTypeId, "userId": userId, "invoiceType": "shipment", "operation": "Generate" })
                        }}>
                          Generate Invoice
                        </Dropdown.Item>}
                      {(aclMap.shipment_access && aclMap.shipment_access.shipment_uploadInvoice && aclMap.shipment_access.shipment_uploadInvoice.isExist) &&
                        <Dropdown.Item href="javascript:void(0)" onClick={() => {
                          setInvoiceDetail(true, { "contractNo": contractNo, "userType": userTypeId, "userId": userId, "invoiceType": "shipment", "operation": "Upload" })
                        }}>
                          Upload Invoice
                        </Dropdown.Item>}
                    </> :
                      (dbData.ship_invoice_status === 1 && dbData.ship_owner_id === userId) ?
                        <>
                          {(aclMap.shipment_access && aclMap.shipment_access.shipment_genInvoice && aclMap.shipment_access.shipment_genInvoice.isExist) &&
                            <Dropdown.Item href="javascript:void(0)" onClick={() => {
                              setInvoiceDetail(true, { "contractNo": contractNo, "userType": userTypeId, "userId": userId, "invoiceType": "shipment", "operation": "ReGenerate" })
                            }}>
                              Re-Generate Invoice
                            </Dropdown.Item>}
                        </> : (dbData.ship_invoice_status === 0 && dbData.charterer_id === userId) ?
                          "NA" : ""}
                    {(dbData.ship_invoice_status === 1 && (aclMap.shipment_access && aclMap.shipment_access.shipment_getInvoice && aclMap.shipment_access.shipment_getInvoice.isExist)) &&
                      <Dropdown.Item href="javascript:void(0)" onClick={() => {
                        setInvoiceDetail(true, { "contractNo": contractNo, "userType": userTypeId, "userId": userId, "invoiceType": "shipment", "operation": "View" })
                      }}>
                        View Invoice
                      </Dropdown.Item>}
                  </DropdownButton>
                </>}


            </ul>
          </div>
        </div>


        <ul className="nav nav-tabs-custom btn" id="myTab" role="tablist">
          <li>
            <a className={"nav-link cursor-pointer " + (tab === 0 ? " active show" : "")} onClick={() => {
              setTab(0);
              setTabName("Summary");
            }}>Summary</a>
          </li>
          {(aclMap.shipment_access && (aclMap.shipment_access && aclMap.shipment_access.shipment_blprocess && aclMap.shipment_access.shipment_blprocess.isExist)) &&
            <li>
              <a className={"nav-link cursor-pointer " + (tab === 1 ? " active show" : "")} onClick={() => {
                setTab(1);
                setTabName("Bill Of Lading");
              }}>B/L Process</a>
            </li>}
          {(aclMap.shipment_access && (dbData.shipment_type === 'container') && aclMap.shipment_access.shipment_assignVessel && aclMap.shipment_access.shipment_assignVessel.isExist) &&
            <li>
              <a className={"nav-link cursor-pointer " + (tab === 2 ? " active show" : "")} onClick={() => {
                setTab(2);
                setTabName("Assign Containers");
              }}>Assign Containers</a>
            </li>}
          {/* {(aclMap.shipment_access && (dbData.shipment_type === 'bulk') && aclMap.shipment_access.shipment_assignVessel && aclMap.shipment_access.shipment_assignVessel.isExist) &&
            <li>
              <a className={"nav-link cursor-pointer " + (tab === 2 ? " active show" : "")} onClick={() => {
                setTab(2);
                setTabName("Assign Containers");
              }}>Assign Vessel</a>
            </li>} */}
          {(aclMap.shipment_access && aclMap.shipment_access.shipment_assignVessel && aclMap.shipment_access.shipment_assignVessel.isExist) &&
            <li>
              <a className={"nav-link cursor-pointer " + (tab === 3 ? " active show" : "")} onClick={() => {
                setTab(3);
                setTabName("Assign Users");
              }}>Assign Users</a>
            </li>}
        </ul>

        {tab === 0 &&
          <div className="tab-pane active show  col-md-12">

            <div className="col-md-12 row mt-2">
              <div className="col-md-10">
                <div className="d-flex align-content-around flex-wrap mb-3 contract-details">
                  <div className="col-md-12 row mt-2">
                    <div className="col-md-3 ">
                      <div className="mr-auto pr-3 form-group">
                        <label className="col-md-12">Charterer</label>
                        <div className="chat-message pl-2">
                          <img src={avatarUrl(dbData.charterer_avatar)} alt="Avatar" width="32" height="32" />
                          <div className="chat-message-content clearfix pt-2">
                            <span className="date-format text-primary">  {dbData.charterer ? dbData.charterer : '--'}</span><br />
                          </div>
                          <p className="date-format p-0 m-0">{dbData.chartererEmail ? dbData.chartererEmail : '--'}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div className="mr-auto pr-3 form-group">
                        <label className="col-md-12">Ship Owner</label>
                        <div className="chat-message pl-2">
                          <img src={avatarUrl(dbData.shipOwner_avatar)} alt="Avatar" width="32" height="32" />
                          <div className="chat-message-content clearfix pt-2">
                            <span className="date-format text-primary">  {dbData.shipOwner ? dbData.shipOwner : '--'}</span><br />
                          </div>
                          <p className="date-format p-0 m-0">{dbData.shipOwnerEmail ? dbData.shipOwnerEmail : '--'}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div className="mr-auto pr-3 form-group">
                        <label className="col-md-12">Vessel Master</label>
                        <div className="chat-message pl-2">
                          <img src={avatarUrl(dbData.vesselMasterAvatar)} alt="Avatar" width="32" height="32" />
                          <div className="chat-message-content clearfix pt-2">
                            <span className="date-format text-primary">  {dbData.vesselMaster ? dbData.vesselMaster : '--'}</span><br />
                          </div>
                          <p className="date-format p-0 m-0">{dbData.vesselMaster ? dbData.vesselMaster : '--'}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div className="mr-auto pr-3 form-group">
                        <label className="col-md-12">Port Agent</label>
                        <div className="chat-message pl-2">
                          <img src={avatarUrl(dbData.portAgentAvatar)} alt="Avatar" width="32" height="32" />
                          <div className="chat-message-content clearfix pt-2">
                            <span className="date-format text-primary">  {dbData && dbData.portAgent ? dbData.portAgent : '--'}</span><br />
                          </div>
                          <p className="date-format p-0 m-0">{dbData.portAgent ? dbData.portAgent : '--'}</p>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="card-panel">
                    <ul>
                      <li>
                        {(dbData.contract_type / 1 === 0) && <h3>{dbData.commodity_name ? dbData.commodity_name : "--"}</h3>}
                        {(dbData.contract_type / 1 === 1) && <h3>{dbData.off_data ? dbData.off_data.commodity : "--"}</h3>}
                        <p>Commodity</p>
                      </li>
                    </ul>
                  </div>
                  {(userId === dbData.charterer_id / 1 || userId === dbData.ship_owner_id / 1) &&
                    <>
                      <div className="card-panel">
                        <ul>
                          <li>
                            <h3>{dbData.ship_currency ? dbData.ship_currency.split(':')[1] : ''} {dbData.ship_price} </h3>
                            <p>Shipment Price</p>
                          </li>
                        </ul>
                      </div>
                      <div className="card-panel">
                        <ul>
                          <li>
                            <h3>{dbData.ship_currency ? dbData.ship_currency.split(':')[1] : ''} {dbData.ship_demmurage_price} </h3>
                            <p>Demmurage Rate</p>
                          </li>
                        </ul>
                      </div>
                    </>}
                  <div className="card-panel">
                    <ul>
                      <li>
                        <h3>{dbData.charterer} </h3>
                        <p>Charterer</p>
                      </li>
                    </ul>
                  </div>
                  <div className="card-panel">
                    <ul>
                      <li>
                        <h3>{dbData.shipOwner} </h3>
                        <p>Ship Owner</p>
                      </li>
                    </ul>
                  </div>
                  <div className="card-panel">
                    <ul>
                      <li>
                        <h3>{dbData.ship_load_country ?
                          <span className="shadow">
                            <ReactCountryFlag
                              countryCode={dbData.ship_load_country ? dbData.ship_load_country.split(':')[0] : ''}
                              style={{ width: '25px', height: '25px' }} svg />
                          </span> : ""}{dbData.ship_load_port}</h3>
                        <p>Loading Port</p>
                      </li>
                    </ul>
                  </div>
                  <div className="card-panel">
                    <ul>
                      <li>
                        <h3>{dbData.ship_unload_country ?
                          <span className="shadow">
                            <ReactCountryFlag
                              countryCode={dbData.ship_unload_country ? dbData.ship_unload_country.split(':')[0] : ''}
                              style={{ width: '25px', height: '25px' }} svg />
                          </span> : ""}{dbData.ship_unload_port}</h3>
                        <p>Unloading Port</p>
                      </li>
                    </ul>
                  </div>
                  <div className="card-panel">
                    <ul>
                      <li>
                        <h3> {dbData.ship_imo_no}</h3>
                        <p>Vessel IMO</p>
                      </li>
                    </ul>
                  </div>
                  <div className="card-panel">
                    <ul>
                      <li>
                        <h3>{formatDate_Application(dbData.ship_date)} </h3>
                        <p>Shipment Date</p>
                      </li>
                    </ul>
                  </div>
                  <div className="card-panel">
                    <ul>
                      <li>
                        <h3>{formatDate_Application(dbData.laycan_start)}</h3>
                        <p>Laycan Start</p>
                      </li>
                    </ul>
                  </div>
                  <div className="card-panel">
                    <ul>
                      <li>
                        <h3>{formatDate_Application(dbData.laycan_end)} </h3>
                        <p>Laycan End</p>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
              <div className="col-md-2">
                <div className="card-panel">
                  <ul>
                    <li>
                      <QRCode value={dbData.address ? dbData.address : contractNo} enableCORS={true} fgColor={'#17a2b8'} qrStyle={'dots'} />
                      {/* <p>{dbData.address ? dbData.address + "\n\rSUmmer" : 'Not Available'}</p> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <hr />
            {(aclMap.shipment_access && aclMap.shipment_access.shipment_trackMonitor && aclMap.shipment_access.shipment_trackMonitor.isExist) &&
              <>
                <ShipmentDetailAccord
                  userTokenDetails={userTokenDetails}
                  contractNo={null}
                  shipContractNo={contractNo} />
                <hr />
                <LaytimeSheetTrail
                  userTokenDetails={userTokenDetails}
                  laytimeType={1}
                  contractNo={contractNo}
                  contractType={'shipment'} />
                <hr />
                <LaytimeSheetTrail
                  userTokenDetails={userTokenDetails}
                  laytimeType={2}
                  contractNo={contractNo}
                  contractType={'shipment'} />
              </>}

          </div>
        }
        {tab === 1 &&
          <div className="tab-pane active show col-md-12">
            {(aclMap.shipment_access && aclMap.shipment_access.shipment_blprocess && aclMap.shipment_access.shipment_blprocess.isExist) &&
              <BillOfLading
                userTokenDetails={userTokenDetails}
                contractNo={null}
                shipContractNo={contractNo} />}
          </div>}
        {tab === 2 && dbData.shipment_type == 'container' &&
          <div className="col-md-12 pt-2 pb-5 mt-5">
            <div className="modal-padding">
              <ul className="price-ul">
                <li>
                  <div className="price-left">{dbData.contract_no}</div>
                  <div className="price-right">Contract</div>
                </li>
                <li>
                  <div className="price-left">{dbData.charterer}</div>
                  <div className="price-right">Charterer </div>
                </li>
                <li>
                  <div className="price-left">{dbData.shipOwner}</div>
                  <div className="price-right">Ship Owner</div>
                </li>
                <li>
                  <div className="price-left">{dbData.loading_rate}  -  {dbData.unloading_rate}</div>
                  <div className="price-right">Loading Port - Unloading Port</div>
                </li>
              </ul>
            </div>
            <div className="modal-padding mt-4">
              <ContainerComp
                userTokenDetails={userTokenDetails}
                contractNo={dbData.contract_no}
                JSONData={containerDetails}
                setContainerDetails={setContainerDetails} />
            </div>
            <hr />
            <button type="button" className="btn btn-info btn-sm" onClick={() => saveContainerInfo()}>Update</button>
          </div>}
        {tab === 3 && dbData.shipment_type == 'container' &&
          <div className="col-md-12 pt-2 pb-5 mt-5">

            <div className="col-md-12 row">

              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Select Vessel</label>
                  <div className="col-md-12">
                    <select className="form-control" name="vesselIMO" value={JSON.stringify(selectedShip)} onChange={handleShipSelect}>
                      {(userShips && userShips.length) ? <option value="" selected>--Select Vessel--</option> : ""}
                      {(userShips && userShips.length) ? userShips.map((ship) => {
                        return (
                          <option value={JSON.stringify(ship)} >{ship.ship_name}</option>
                        )
                      }) : <option value="" selected>No Vessel Found</option>}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Select Vessel Master</label>
                  <div className="col-md-12">
                    <select className="form-control" name="vesselMaster" value={data.vesselMaster} onChange={handleChange}>
                      {(vesselMasters && vesselMasters.length) ? <option value="" selected>--Select Vessel Master--</option> : ""}
                      {(vesselMasters && vesselMasters.length) ? vesselMasters.map((vesselMaster) => {
                        return (
                          <option value={vesselMaster.id} >{vesselMaster.company_name}</option>
                        )
                      }) : <option value="" selected>No Vessel Master Found</option>}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row form-group">
                  <label className="col-md-12">Select Carrier Agent</label>
                  <div className="col-md-12">
                    <select className="form-control" name="carrierAgent" value={data.carrierAgent} onChange={handleChange}>
                      {(carrierAgents && carrierAgents.length) ? <option value="" selected>--Select Carrier Agent--</option> : ""}
                      {(carrierAgents && carrierAgents.length) ? carrierAgents.map((carrierAgent) => {
                        return (
                          <option value={carrierAgent.id} >{carrierAgent.company_name}</option>
                        )
                      }) : <option value="" selected>No Carrier Agent Found</option>}
                    </select>
                  </div>
                </div>
              </div>

            </div>
            <hr />
            <button type="button" className="btn btn-info btn-sm" onClick={() => saveVesselInfo()}>Update</button>

          </div>}
      </>}
    </>
  )
};
//---------------------------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------------------------
// Redux
const mapStateToProps = state => {
  return {
    contractDetailLogi: state.contractDetailLogi,
    laytimesheet: state.laytimesheet,
    ViewTrack: state.ViewTrack,
    contractDocList: state.contractDocList,
    InvoiceDetail: state.InvoiceDetail
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractDetailLogi: (flag, data) => { dispatch(setContractDetailLogi({ page: flag, info: data })) },
    setLaytimesheet: (cNumber) => { dispatch(setLaytimesheet({ state: true, cNo: cNumber })) },
    setViewTrack: (flag, data) => { dispatch(setViewTrack({ modal: flag, info: data })) },
    setContractDocList: (id) => { dispatch(setContractDocList({ modal: true, contractId: id })) },
    setInvoiceDetail: (flag, data) => { dispatch(setInvoiceDetail({ modal: flag, info: data })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractDetailShipment)
